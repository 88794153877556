import produce from 'immer';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Checkbox, CircularProgress, Button } from 'rmwc';

import { docKeyState, metadataState, docSelectorState } from '../hooks/doc';
import { currentUserState } from '../state/auth';
import captureException from '../lib/captureException';
import { publishDraft } from '../lib/core';

import './PreviewToolbar.css';

export default React.memo(props => {
  // recoil state

  const currentUser = useRecoilValue(currentUserState);
  const docKey = useRecoilValue(docKeyState);
  const [metadata, setMetadata] = useRecoilState(metadataState);
  const docSelector = useRecoilValue(docSelectorState);

  // local state

  const [isPublishing, setIsPublishing] = useState(false);

  // derived state

  const { isUnlisted, slug } = metadata || {};

  // change slug

  function handleChangeSlug(event) {
    const newSlug = event.target.value;

    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata.slug = newSlug;
      })
    );
  }

  // toggle unlisted

  function handleToggleIsUnlisted() {
    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata.isUnlisted = !isUnlisted;
      })
    );
  }

  // publish

  async function handleClickPublish() {
    setIsPublishing(true);

    const draft = { ...docSelector, metadata: { ...metadata, docKey } };

    try {
      const updatedDraft = await publishDraft(draft);
      if (updatedDraft) {
        // update metadata with publish info
        // so that publishDate won't change in the future
        setMetadata(updatedDraft.metadata);
      }
    } catch (error) {
      captureException(error);
    } finally {
      setIsPublishing(false);
    }
  }

  // render

  return (
    <div className="preview-toolbar">
      <div className="preview-toolbar__slug-group">
        <div className="preview-toolbar__slug-label">URL slug</div>
        <input
          className="preview-toolbar__slug-input"
          onChange={handleChangeSlug}
          placeholder="Url slug"
          value={slug}
        />
      </div>

      <Checkbox
        checked={Boolean(isUnlisted)}
        className="preview-toolbar__unlisted-checkbox"
        label="Unlisted"
        onChange={handleToggleIsUnlisted}
      />

      <Button
        disabled={!currentUser || isPublishing}
        icon={isPublishing ? <CircularProgress /> : 'publish'}
        label="Publish"
        onClick={handleClickPublish}
      />
    </div>
  );
});
