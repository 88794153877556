import { getDbObject, setDbObject } from './utils';

// paths

export function analyticsClientIdDbPath(uid) {
  if (!uid) {
    throw new Error('Invalid args');
  }
  return `/analyticsClientIds/${uid}`;
}

// get

export function getAnalyticsClientId(uid) {
  return getDbObject(analyticsClientIdDbPath(uid));
}

// set

export function setAnalyticsClientId(uid, cid) {
  return setDbObject(analyticsClientIdDbPath(uid), cid);
}

// read client id from cookie and set

export function updateAnalyticsClientId(uid) {
  // https://stackoverflow.com/a/50070138
  const cid = document.cookie
    .match(/_ga=(.+?);/)[1]
    .split('.')
    .slice(-2)
    .join('.');

  if (cid) {
    return setAnalyticsClientId(uid, cid);
  }

  throw new Error('Analytics client ID not found');
}
