import firebase from 'firebase/app';
import LogRocket from 'logrocket';
import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import captureException from '../lib/captureException';
import { updateAnalyticsClientId } from '../lib/core';
import { currentUserState } from '../state/auth';

// subscribe to firebase auth state changes, runs once
export default function useChannelMetadataSubscription() {
  const setCurrentUser = useSetRecoilState(currentUserState);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      // update recoil
      setCurrentUser(
        user
          ? {
              email: user.email,
              uid: user.uid,
            }
          : null
      );

      if (user) {
        // identify for firebase analytics
        firebase.analytics().setUserId(user.uid);
        // identify for logrocket
        LogRocket.identify(user.email);
        // update analytics client id in db
        try {
          updateAnalyticsClientId(user.uid);
        } catch (e) {
          captureException(e);
        }
      }
    });
  }, [setCurrentUser]);

  return null;
}
