import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import smoothScrollIntoView from 'smooth-scroll-into-view-if-needed';

import playerModes from '../constants/playerModes';
import {
  isEditingState,
  editModeState,
  editBlockIndexState,
} from '../hooks/editor';
import {
  isStickyState,
  playingSegmentState,
  playerModeState,
  playerBlocksState,
  useMediaInput,
  mediaInputState,
} from '../hooks/player';
import { studyState } from '../hooks/study';
import {
  topBarHeightState,
  bottomBarHeightState,
  mediaHeightState,
} from '../state/ui';

const scrollSlopPx = 1;
const topPadding = 36;
const bottomPadding = 4;

function getScrollTop() {
  return document.body.scrollTop || document.documentElement.scrollTop;
}

function getBounds(element) {
  if (element) {
    const rect = element.getBoundingClientRect();

    return {
      top: rect.top + window.scrollY - topPadding,
      left: rect.left,
      width: rect.width,
      height: rect.height + topPadding + bottomPadding,
    };
  } else {
    return null;
  }
}

export default function AutoScroller() {
  const { studyIndex, isStudyIndexFocused } = useRecoilValue(studyState);
  const topBarHeight = useRecoilValue(topBarHeightState);
  const bottomBarHeight = useRecoilValue(bottomBarHeightState);
  const mediaHeight = useRecoilValue(mediaHeightState);
  const mediaInput = useRecoilValue(mediaInputState);
  const { pauseAndHide } = useMediaInput();
  const playerMode = useRecoilValue(playerModeState);
  const isEditing = useRecoilValue(isEditingState);
  const editMode = useRecoilValue(editModeState);
  const editBlockIndex = useRecoilValue(editBlockIndexState);
  const blocks = useRecoilValue(playerBlocksState);
  const isSticky = useRecoilValue(isStickyState);
  const { blockIndex: playingBlockIndex, segmentIndex } = useRecoilValue(
    playingSegmentState
  );

  // state

  const scrollIntoViewRef = useRef();
  const autoScrollRangeRef = useRef();

  const [containerBounds, setContainerBounds] = useState(null);

  let index;
  if (isEditing) {
    index = editBlockIndex;
  } else if (playerMode === playerModes.study && isStudyIndexFocused) {
    index = studyIndex;
  } else {
    index = playingBlockIndex;
  }

  // calculate layout

  const mediaOffset = isEditing && !isSticky ? 0 : mediaHeight;
  const scrollIntoViewHeight =
    containerBounds &&
    topBarHeight + mediaOffset + containerBounds.height + bottomBarHeight;
  const scrollIntoViewMarginTop =
    containerBounds && -topBarHeight - mediaOffset;

  // update containerBounds

  useLayoutEffect(() => {
    if (blocks && blocks[index] && (mediaInput.isPlaying || isEditing)) {
      setTimeout(() => {
        const element = isEditing
          ? document.getElementById(blocks[index].key)
          : document.querySelector('#playing-segment');
        const bounds = getBounds(element);
        setContainerBounds(bounds);
      }, 0);
    } else {
      setContainerBounds(null);
    }
  }, [blocks, isEditing, editMode, index, mediaInput, segmentIndex]);

  // scroll into view if needed

  useLayoutEffect(() => {
    const scrollTop = getScrollTop();

    // if containerBounds found and not editing timing
    if (containerBounds && scrollIntoViewRef.current) {
      const autoScrollTop = containerBounds.top + scrollIntoViewMarginTop;
      const autoScrollBottom =
        containerBounds.top + scrollIntoViewMarginTop + scrollIntoViewHeight;
      const overScroll = scrollTop - autoScrollTop;
      const underScroll = autoScrollBottom - (scrollTop + window.innerHeight);

      if (overScroll - scrollSlopPx > 0) {
        // auto scrolling backwards
        autoScrollRangeRef.current = {
          start: scrollTop - overScroll,
          end: scrollTop,
        };
      } else if (underScroll - scrollSlopPx > 0) {
        // auto scrolling forwards
        autoScrollRangeRef.current = {
          start: scrollTop,
          end:
            isEditing || playerMode === playerModes.study
              ? // scroll block just into view
                scrollTop + underScroll
              : // scroll block to highest visible position (below media)
                autoScrollTop,
        };
      }

      if (overScroll - scrollSlopPx > 0 || underScroll - scrollSlopPx > 0) {
        // console.log(
        //   'smoothScrollIntoView',
        //   index,
        //   getScrollTop(),
        //   overScroll,
        //   underScroll
        // );
        smoothScrollIntoView(scrollIntoViewRef.current, {
          behavior: 'smooth',
          block:
            isEditing || playerMode === playerModes.study ? 'nearest' : 'start',
          inline: 'nearest',
          scrollMode: 'if-needed',
        });
      }
    }
  }, [
    containerBounds,
    scrollIntoViewHeight,
    scrollIntoViewMarginTop,
    blocks,
    isEditing,
    playerMode,
  ]);

  // pauseAndHide media on scroll

  useEffect(() => {
    if (isSticky) {
      function handleScroll() {
        const scrollTop = getScrollTop();
        if (
          autoScrollRangeRef.current &&
          (scrollTop + scrollSlopPx < autoScrollRangeRef.current.start ||
            scrollTop - scrollSlopPx > autoScrollRangeRef.current.end)
        ) {
          // console.log('handleScroll', scrollTop, autoScrollRangeRef.current);
          pauseAndHide();
        }
      }

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isSticky, pauseAndHide]);

  // render

  return (
    <div className="auto-scroller" style={containerBounds}>
      {isEditing && false && (
        <React.Fragment>
          <div
            className={`auto-scroller__highlight-left${
              isEditing ? '--editing' : ''
            }`}
          />
          <div
            className={`auto-scroller__highlight-right${
              isEditing ? '--editing' : ''
            }`}
          />
        </React.Fragment>
      )}

      <div
        className="auto-scroller__scroll-into-view"
        ref={scrollIntoViewRef}
        style={{
          height: scrollIntoViewHeight,
          marginTop: scrollIntoViewMarginTop,
        }}
      />
    </div>
  );
}
