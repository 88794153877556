import produce from 'immer';
import { useEffect } from 'react';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import languageOptions from '../constants/languageOptions';
import { metadataState } from '../hooks/doc';

// storage interface

function getSettings() {
  const item = localStorage.getItem('settings');
  if (item) {
    return JSON.parse(item);
  }
  return null;
}

function persistSettings(settings) {
  localStorage.setItem('settings', JSON.stringify(settings));
}

// settings UI

export const isShowingSettingsDialogState = atom({
  key: 'isShowingSettingsDialogState',
  default: false,
});

// state

export const isShowingTranslationState = atom({
  key: 'isShowingTranslationState',
  default: true,
});

export const languageSettingsState = atom({
  key: 'languageSettingsState',
  default: {},
});

export const playbackRateState = atom({
  key: 'playbackRateState',
  default: 100,
});

export const showAllPronsState = atom({
  key: 'showAllPronsState',
  default: true,
});

export const showAllTranslationsState = atom({
  key: 'showAllTranslationsState',
  default: true,
});

// convenience selectors for source language settings

export const sourceLanguageSettingsState = selector({
  key: 'sourceLanguageSettingsState',
  get: ({ get }) => {
    const metadata = get(metadataState);
    const languageSettings = get(languageSettingsState);

    const { sourceLanguage } = metadata || {};
    return languageSettings && languageSettings[sourceLanguage];
  },
});

export const highlightSettingState = selector({
  key: 'highlightSettingState',
  get: ({ get }) => {
    const sourceLanguageSettings = get(sourceLanguageSettingsState);
    return sourceLanguageSettings && sourceLanguageSettings.highlight;
  },
});

export const pronSettingState = selector({
  key: 'pronSettingState',
  get: ({ get }) => {
    const sourceLanguageSettings = get(sourceLanguageSettingsState);
    return sourceLanguageSettings && sourceLanguageSettings.pron;
  },
});

export const scriptSettingState = selector({
  key: 'scriptSettingState',
  get: ({ get }) => {
    const sourceLanguageSettings = get(sourceLanguageSettingsState);
    return sourceLanguageSettings && sourceLanguageSettings.script;
  },
});

// sync (used in App component)

export function useRestoreSettings() {
  // recoil state

  const metadata = useRecoilValue(metadataState);
  const [isShowingTranslation, setIsShowingTranslation] = useRecoilState(
    isShowingTranslationState
  );
  const [languageSettings, setLanguageSettings] = useRecoilState(
    languageSettingsState
  );
  const [playbackRate, setPlaybackRate] = useRecoilState(playbackRateState);
  const [showAllProns, setShowAllProns] = useRecoilState(showAllPronsState);
  const [showAllTranslations, setShowAllTranslations] = useRecoilState(
    showAllTranslationsState
  );
  const sourceLanguageSettings = useRecoilValue(sourceLanguageSettingsState);

  // derived state

  const { sourceLanguage } = metadata || {};
  const sourceLanguageOptions = languageOptions[sourceLanguage];

  // init languageSettings with defaults
  // when switching to a sourceLanguage for the first time

  useEffect(() => {
    if (sourceLanguageOptions && !sourceLanguageSettings) {
      if (languageSettings) {
        setLanguageSettings(settings =>
          produce(settings, draftSettings => {
            draftSettings[sourceLanguage] = sourceLanguageOptions
              ? sourceLanguageOptions.defaults
              : {};
          })
        );
      } else {
        setLanguageSettings({});
      }
    }
  }, [
    languageSettings,
    setLanguageSettings,
    sourceLanguage,
    sourceLanguageOptions,
    sourceLanguageSettings,
  ]);

  // restore settings on mount

  useEffect(() => {
    const settings = getSettings();
    if (settings) {
      setIsShowingTranslation(settings.isShowingTranslation);
      setLanguageSettings(settings.languageSettings);
      setPlaybackRate(settings.playbackRate);
      setShowAllProns(settings.showAllProns);
      setShowAllTranslations(settings.showAllTranslations);
    }
  }, [
    setIsShowingTranslation,
    setLanguageSettings,
    setPlaybackRate,
    setShowAllProns,
    setShowAllTranslations,
  ]);

  // persist settings on change

  useEffect(() => {
    persistSettings({
      isShowingTranslation,
      languageSettings,
      playbackRate,
      showAllProns,
      showAllTranslations,
    });
  }, [
    isShowingTranslation,
    languageSettings,
    playbackRate,
    showAllProns,
    showAllTranslations,
  ]);
}
