import React, { useEffect, useState } from 'react';
import { LinearProgress } from 'rmwc';

import DocLinkTile from './DocLinkTile';

import './Home.css';
import useAllChannelMetadata from '../hooks/useAllChannelMetadata';
import useAllDocMetadata from '../hooks/useAllDocMetadata';

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

let shuffledDocCache = null;

export default function Home() {
  // hooks

  const allChannelMetadata = useAllChannelMetadata();
  const allDocMetadata = useAllDocMetadata();

  // local state

  const [shuffledDocs, setShuffledDocs] = useState(shuffledDocCache);

  // shuffle doc metadata

  useEffect(() => {
    (async () => {
      if (allDocMetadata && !shuffledDocCache) {
        const shuffled = [...allDocMetadata];
        shuffleArray(shuffled);
        setShuffledDocs(shuffled);
        shuffledDocCache = shuffled;
      }
    })();
  }, [allDocMetadata]);

  // render

  return (
    <div className="home">
      {!shuffledDocs && (
        <React.Fragment>
          <LinearProgress />
          <div className="home__loading">
            Loading recently published lessons...
          </div>
        </React.Fragment>
      )}

      {shuffledDocs && (
        <div className="home__lesson-list">
          {shuffledDocs.map(docMetadata => (
            <DocLinkTile
              channelMetadata={allChannelMetadata[docMetadata.channelKey]}
              docMetadata={docMetadata}
              key={docMetadata.docKey}
            />
          ))}
        </div>
      )}

      <div className="home__heading">Create Amazing Language Lessons</div>

      <div className="home__text">
        We're partnering with creative language teachers to make lessons
        students love. Anyone can use our editor to publish lessons on their own
        channel. It's free to create free lessons. In the future you'll be able
        to sell premium lessons. Send us an email and we'll help you get
        started:{' '}
        <a
          href="mailto:collab@lessonize.com"
          className="home__email"
          rel="noopener noreferrer"
          target="_blank"
        >
          collab@lessonize.com
        </a>
      </div>

      <div className="home__heading">Features</div>

      <div className="home__feature-list">
        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/popup.png"
          />
          <div className="home__feature-text">
            Add <strong>popup</strong> translations and word audio
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/sync.png"
          />
          <div className="home__feature-text">
            <strong>Sync</strong> words with audio using our AI-powered editor
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/pronunciation.png"
          />
          <div className="home__feature-text">
            Add <strong>pronunciations</strong> which can be shown or hidden
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/translation.png"
          />
          <div className="home__feature-text">
            Add sentence <strong>translations</strong> which can be shown or
            hidden
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/highlight.png"
          />
          <div className="home__feature-text">
            <strong>Highlight</strong> vocabulary from exams like the HSK
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/speed.png"
          />
          <div className="home__feature-text">
            <strong>Slow</strong> down playback
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/skip.png"
          />
          <div className="home__feature-text">
            <strong>Replay</strong> sentences and <strong>skip</strong> back or
            forward
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/vocab.png"
          />
          <div className="home__feature-text">
            Automatically add <strong>vocabulary</strong> list
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/exercise.png"
          />
          <div className="home__feature-text">
            Easily add <strong>exercises</strong> including word matching and
            fill in the blanks
          </div>
        </div>

        <div className="home__feature">
          <img
            alt="screenshot"
            className="home__feature-image"
            src="features/sell.png"
          />
          <div className="home__feature-text">
            Sell courses as <strong>subscriptions</strong> or{' '}
            <strong>one-time</strong> purchases
          </div>
        </div>
      </div>
    </div>
  );
}
