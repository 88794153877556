import produce from 'immer';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, CircularProgress } from 'rmwc';

import { blocksState, metadataState } from '../hooks/doc';
import captureException from '../lib/captureException';
import segmentsToString from '../lib/segmentsToString';
import translate from '../lib/translate';
import './BlockTypeMenu.css';

function TranslateToolbar() {
  // recoil state

  const metadata = useRecoilValue(metadataState);
  const [blocks, setBlocks] = useRecoilState(blocksState);

  // derived state

  const { sourceLanguage, targetLanguage } = metadata || {};

  // local state

  const [isTranslating, setIsTranslating] = useState(false);

  // handlers

  function handleSelectAll() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          block.isSelected = true;
        });
      })
    );
  }

  function handleSelectNone() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          delete block.isSelected;
        });
      })
    );
  }

  async function handleClickTranslate() {
    setIsTranslating(true);
    try {
      // created map of keys to text for segmenting
      const keyedText = {};
      blocks.forEach(block => {
        if (block.isSelected) {
          keyedText[block.key] = segmentsToString(block.segments);
        }
      });

      // call translate cloud function
      const result = await translate(sourceLanguage, targetLanguage, keyedText);

      // update blocks in recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks.forEach(block => {
            if (result[block.key]) {
              block.translation = result[block.key] || null;
            }
          });
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setIsTranslating(false);
    }
  }

  // render

  return (
    <div className="draft-formatting-menu">
      <Button
        disabled={isTranslating || !metadata || !blocks}
        icon="done_all"
        label="All"
        onClick={handleSelectAll}
      />

      <Button
        disabled={isTranslating || !metadata || !blocks}
        icon="check_box_outline_blank"
        label="None"
        onClick={handleSelectNone}
      />

      <Button
        disabled={isTranslating}
        icon={isTranslating ? <CircularProgress /> : 'g_translate'}
        label="Translate"
        onClick={handleClickTranslate}
      />
    </div>
  );
}

export default React.memo(TranslateToolbar);
