import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { LinearProgress } from 'rmwc';
import uPlot from 'uplot';
import extractPeaks from 'webaudio-peaks';

const { devicePixelRatio } = window;

export const waveformScaleFactor = 4;

function Waveform(props) {
  const { mediaElement, onRendered } = props;

  // local state

  const [duration, setDuration] = useState(
    mediaElement && mediaElement.duration
  );
  const [hasRendered, setHasRendered] = useState(false);
  const uplotElRef = useRef(null);

  // update duration when media loads

  useEffect(() => {
    if (mediaElement) {
      if (mediaElement.readyState === 4) {
        setDuration(mediaElement.duration);
      } else {
        mediaElement.addEventListener('canplaythrough', () => {
          setDuration(mediaElement.duration);
        });
      }
    } else {
      setDuration(null);
    }
  }, [mediaElement]);

  // init Peaks when duration is known

  useLayoutEffect(() => {
    if (duration && uplotElRef.current) {
      function initPeaks() {
        // new shit
        fetch(mediaElement.currentSrc)
          .then(response => response.arrayBuffer())
          .then(audioData => {
            var audioContext = new (window.AudioContext ||
              window.webkitAudioContext)();

            //decode an ArrayBuffer into an AudioBuffer
            audioContext.decodeAudioData(audioData, function (decodedData) {
              //calculate peaks from an AudioBuffer

              const samplesPerPixel =
                (waveformScaleFactor * (2048 * decodedData.sampleRate)) / 48000;
              const peaks = extractPeaks(decodedData, samplesPerPixel, true);

              // format data for uplot

              const xs = [];
              const ys = [];
              const nys = [];

              peaks.data[0].forEach((value, index) => {
                xs.push(index);
                ys.push(value);
                nys.push(-value);
              });

              const data = [xs, ys, nys];

              // create uplot options

              const opts = {
                axes: [{ show: false }, { show: false }],
                height: 64 * (2 / devicePixelRatio),
                legend: { show: false },
                padding: [
                  0,
                  125 * (2 / devicePixelRatio),
                  0,
                  125 * (2 / devicePixelRatio),
                ],
                series: [
                  {},
                  {
                    fill: '#424242',
                    stroke: '#9e9e9e',
                  },
                  {
                    fill: '#424242',
                    stroke: '#9e9e9e',
                  },
                ],
                scales: {
                  x: {
                    time: false,
                  },
                },
                width: xs.length * 2 + 250 * (2 / devicePixelRatio),
              };

              // clear children
              uplotElRef.current.innerHTML = '';

              // plot
              uPlot(opts, data, uplotElRef.current);

              setHasRendered(true);
            });
          });
      }

      if (mediaElement.readyState === 4) {
        initPeaks();
      } else {
        mediaElement.addEventListener('canplaythrough', () => {
          setTimeout(() => {
            initPeaks();
          }, 300);
        });
      }
    }
  }, [duration, mediaElement]);

  // triggered onRendered

  useLayoutEffect(() => {
    if (hasRendered && onRendered) {
      onRendered();
    }
  }, [hasRendered, onRendered]);

  // render

  return (
    <React.Fragment>
      {!hasRendered && <LinearProgress />}

      <div className="waveform__uplot" ref={uplotElRef} />
    </React.Fragment>
  );
}

export default React.memo(Waveform);
