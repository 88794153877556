import firebase from 'firebase/app';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button } from 'rmwc';

import Exercise from './Exercise';
import { docKeyState } from '../../hooks/doc';
import { trackExerciseProgress } from '../../lib/core';
import { currentUserState } from '../../state/auth';
import {
  exerciseIndexState,
  lastCompletedExerciseIndexState,
  exercisesState,
} from '../../state/exercises';
import './Exercises.css';

export default function ExercisesSection() {
  // recoil state

  const currentUser = useRecoilValue(currentUserState);
  const docKey = useRecoilValue(docKeyState);

  const [exerciseIndex, setExerciseIndex] = useRecoilState(exerciseIndexState);
  const [
    lastCompletedExerciseIndex,
    setLastCompletedExerciseIndex,
  ] = useRecoilState(lastCompletedExerciseIndexState);
  const exercises = useRecoilValue(exercisesState);

  // derived state

  const numExercises = exercises ? exercises.length : 0;
  const numCompleted =
    typeof lastCompletedExerciseIndex === 'number'
      ? lastCompletedExerciseIndex + 1
      : 0;
  const isFinished = lastCompletedExerciseIndex === numExercises - 1;
  let buttonLabel = 'Start';
  if (isFinished) {
    buttonLabel = 'Restart';
  } else if (numCompleted > 0) {
    buttonLabel = 'Continue';
  }

  // handlers

  function handleClickStart() {
    if (isFinished) {
      setLastCompletedExerciseIndex(null);
      setExerciseIndex(0);

      if (currentUser) {
        trackExerciseProgress(currentUser.uid, docKey, null);
      }
    } else {
      setExerciseIndex(numCompleted);
    }

    // track in firebase analytics
    firebase.analytics().logEvent('start_exercises', { docKey });
  }

  // render

  if (!exercises) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="heading-outer">
        <div className="heading-inner">Exercises</div>
      </div>

      <div className="paragraph-outer">
        <div className="paragraph-inner">
          You've done {numCompleted} / {numExercises} exercises
        </div>
      </div>

      <div className="paragraph-outer">
        <div className="paragraph-inner">
          <Button
            className="exercises-section__start-button"
            label={buttonLabel}
            onClick={handleClickStart}
            theme="secondaryBg"
            unelevated
          />
        </div>
      </div>

      {typeof exerciseIndex === 'number' && <Exercise />}
    </React.Fragment>
  );
}
