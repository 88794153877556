import React from 'react';
import { Icon } from 'rmwc';

import smallProns from '../../constants/smallProns';

import './ReferenceDefs.css';

export default function ReferenceDefs(props) {
  const { defs, onCopy, pronOptions, wordText } = props;

  // render

  return (
    <React.Fragment>
      <div className="segment-editor__row">
        <div className="segment-editor__word">{wordText}</div>
      </div>

      <div className="segment-editor__def-list">
        {defs.map((def, defIndex) => {
          return (
            <div className="segment-editor__def reference-def" key={defIndex}>
              {def.prons && (
                <div className="segment-editor__pron-list">
                  {def.prons.map((pron, pronIndex) => (
                    <div
                      className="segment-editor__pron-group reference-def__pron-group"
                      key={pronIndex}
                    >
                      {pronOptions.map(pronOption => {
                        let pronOptionClasses = 'segment-editor__pron';
                        if (smallProns[pronOption]) {
                          pronOptionClasses += ' segment-editor__pron--small';
                        }

                        return (
                          <div className={pronOptionClasses} key={pronOption}>
                            {pron[pronOption]}
                          </div>
                        );
                      })}
                    </div>
                  ))}
                </div>
              )}

              <div className="segment-editor__meaning reference-def__meaning">
                {def.meaning}
              </div>

              <Icon
                className="segment-editor__icon-button segment-editor__duplicate-button"
                icon="copy"
                onClick={() => onCopy(def)}
              />
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}
