import React from 'react';
import { useTranslation } from 'react-i18next';

import Link, { getTitle } from './Link';

export default function DocLink(props) {
  const { metadata, onClick, selected } = props;
  const { t } = useTranslation();

  // render when metadata not available

  if (!metadata) {
    return <Link primaryText="..." />;
  }

  // render normally

  let secondaryText = t(metadata.sourceLanguage);
  if (metadata.level) {
    secondaryText += ` · ${metadata.level}`;
  }

  return (
    <Link
      id={metadata.docKey}
      thumbnailUrl={metadata.thumbnailUrl}
      onClick={onClick}
      primaryText={getTitle(metadata)}
      secondaryText={secondaryText}
      selected={selected}
    />
  );
}
