import firebase from 'firebase/app';
import produce from 'immer';
import React, { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { Checkbox, Dialog, DialogContent, DialogTitle, Radio } from 'rmwc';

import highlightOptionLabels from '../constants/highlightOptionLabels';
import languageOptions from '../constants/languageOptions';
import { metadataState } from '../hooks/doc';
import { hasPronsState, hasTranslationsState } from '../hooks/player';
import {
  isShowingSettingsDialogState,
  isShowingTranslationState,
  languageSettingsState,
  playbackRateState,
  showAllPronsState,
  showAllTranslationsState,
} from '../state/settings';
import './DocSettings.css';

const playbackRateOptions = [100, 80, 60];

export default function DocSettings() {
  // recoil state

  const [isShowingSettingsDialog, setIsShowingSettingsDialog] = useRecoilState(
    isShowingSettingsDialogState
  );

  const metadata = useRecoilValue(metadataState);

  const hasProns = useRecoilValue(hasPronsState);
  const hasTranslations = useRecoilValue(hasTranslationsState);

  const resetIsShowingTranslation = useResetRecoilState(
    isShowingTranslationState
  );
  const [languageSettings, setLanguageSettings] = useRecoilState(
    languageSettingsState
  );
  const [playbackRate, setPlaybackRate] = useRecoilState(playbackRateState);
  const [showAllProns, setShowAllProns] = useRecoilState(showAllPronsState);
  const [showAllTranslations, setShowAllTranslations] = useRecoilState(
    showAllTranslationsState
  );

  // derived state

  const sourceLanguage = metadata && metadata.sourceLanguage;

  // language options
  const sourceLanguageOptions =
    languageOptions && languageOptions[sourceLanguage];
  const {
    highlights: highlightOptions,
    prons: pronOptions,
    scripts: scriptOptions,
  } = sourceLanguageOptions || {};

  // language settings
  const sourceLanguageSettings =
    languageSettings && languageSettings[sourceLanguage];
  const {
    highlight: selectedHighlight,
    pron: selectedPron,
    script: selectedScript,
  } = sourceLanguageSettings || {};

  // handlers

  function handleSelectHighlight(option) {
    setLanguageSettings(settings =>
      produce(settings, draftSettings => {
        draftSettings[sourceLanguage].highlight = option;
      })
    );
  }

  function handleClickPron(option) {
    if (languageSettings[sourceLanguage].pron === option && showAllProns) {
      // if pron was already selected, turn off show prons
      setShowAllProns(false);
    } else {
      // otherwise, select pron and turn on show prons
      setLanguageSettings(settings =>
        produce(settings, draftSettings => {
          draftSettings[sourceLanguage].pron = option;
        })
      );
      setShowAllProns(true);
    }
  }

  function handleSelectScript(option) {
    setLanguageSettings(settings =>
      produce(settings, draftSettings => {
        draftSettings[sourceLanguage].script = option;
      })
    );
  }

  function handleSetShowAllTranslations(option) {
    setShowAllTranslations(option);
    resetIsShowingTranslation();
  }

  function handleSelectPlaybackRate(option) {
    setPlaybackRate(option);
  }

  // track opening settings

  useEffect(() => {
    if (isShowingSettingsDialog) {
      firebase.analytics().logEvent('open_settings');
    }
  }, [isShowingSettingsDialog]);

  // render

  return (
    <Dialog
      open={isShowingSettingsDialog}
      onClose={() => setIsShowingSettingsDialog(false)}
      renderToPortal
    >
      <DialogTitle>Settings</DialogTitle>
      <DialogContent>
        {scriptOptions && (
          <div className="doc-settings__section">
            <div className="doc-settings__section-title">Characters</div>
            {scriptOptions.map(option => (
              <Radio
                className="doc-settings__checkbox"
                checked={option === selectedScript}
                key={option}
                label={option}
                onChange={() => handleSelectScript(option)}
              />
            ))}
          </div>
        )}

        {hasProns && pronOptions && (
          <div className="doc-settings__section">
            <div className="doc-settings__section-title">Pronunciation</div>
            {pronOptions.map(option => (
              <Checkbox
                className="doc-settings__checkbox"
                checked={option === selectedPron && showAllProns}
                key={option}
                label={option}
                onChange={() => handleClickPron(option)}
              />
            ))}
          </div>
        )}

        {highlightOptions && (
          <div className="doc-settings__section">
            <div className="doc-settings__section-title">Highlighting</div>
            {highlightOptions.map(option => (
              <Checkbox
                className="doc-settings__checkbox"
                checked={option === selectedHighlight}
                key={option}
                label={highlightOptionLabels[option]}
                onChange={() =>
                  handleSelectHighlight(
                    option === selectedHighlight ? null : option
                  )
                }
              />
            ))}
          </div>
        )}

        {hasTranslations && (
          <div className="doc-settings__section">
            <div className="doc-settings__section-title">Translations</div>
            <Radio
              className="doc-settings__checkbox"
              checked={showAllTranslations}
              label="Below each sentence"
              onChange={() => handleSetShowAllTranslations(true)}
            />

            <Radio
              className="doc-settings__checkbox"
              checked={!showAllTranslations}
              label="Bottom of screen"
              onChange={() => handleSetShowAllTranslations(false)}
            />
          </div>
        )}

        <div className="doc-settings__section">
          <div className="doc-settings__section-title">Speed</div>
          {playbackRateOptions.map(option => (
            <Radio
              className="doc-settings__checkbox"
              checked={option === playbackRate}
              key={option}
              label={option + '%'}
              onChange={() => handleSelectPlaybackRate(option)}
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}
