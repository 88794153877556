import { Howl } from 'howler';

// https://freesound.org/people/Eponn/sounds/421002/
export const correctSound = new Howl({
  format: 'mp3',
  preload: true,
  src: ['/correct.mp3'],
});

// https://freesound.org/people/timgormly/sounds/181858/
export const incorrectSound = new Howl({
  format: 'mp3',
  preload: true,
  src: ['/incorrect.mp3'],
});
