import produce from 'immer';
import React, { useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Button, CircularProgress } from 'rmwc';

import { metadataState, blocksState, wordsState } from '../hooks/doc';
import annotate from '../lib/annotate';
import { encodeWordKeys } from '../lib/core';
import captureException from '../lib/captureException';
import segmentsToString from '../lib/segmentsToString';

export default function SegmentsToolbar(props) {
  // recoil state

  const metadata = useRecoilValue(metadataState);
  const [blocks, setBlocks] = useRecoilState(blocksState);
  const setWords = useSetRecoilState(wordsState);

  // local state

  const [isAnnotating, setIsAnnotating] = useState(false);

  // selection handlers

  function handleSelectAll() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          block.isSelected = true;
        });
      })
    );
  }

  function handleSelectNone() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          delete block.isSelected;
        });
      })
    );
  }

  // handle annotate

  async function handleClickAnnotate() {
    setIsAnnotating(true);
    try {
      // created map of keys to text for segmenting
      const keyedText = {};
      blocks.forEach(block => {
        if (block.isSelected) {
          keyedText[block.key] = segmentsToString(block.segments);
        }
      });

      // call annotate cloud function
      const result = await annotate(
        metadata.channelKey,
        metadata.sourceLanguage,
        metadata.targetLanguage,
        keyedText
      );

      // update blocks in recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks.forEach(block => {
            if (result.blocks[block.key]) {
              block.segments = result.blocks[block.key].segments;
            }
          });
        })
      );

      // base64 encode keys
      const base64KeyedWords = encodeWordKeys(result.words);

      // update defs recoil state
      setWords(words =>
        produce(words, draftWords => {
          Object.assign(draftWords, base64KeyedWords);
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setIsAnnotating(false);
    }
  }

  // render

  return (
    <React.Fragment>
      <div className="draft-formatting-menu">
        <Button
          disabled={isAnnotating || !metadata || !blocks}
          icon="done_all"
          label="All"
          onClick={handleSelectAll}
        />

        <Button
          disabled={isAnnotating || !metadata || !blocks}
          icon="check_box_outline_blank"
          label="None"
          onClick={handleSelectNone}
        />

        <Button
          disabled={isAnnotating || !metadata || !blocks}
          icon={isAnnotating ? <CircularProgress /> : 'assistant'}
          label="Annotate"
          onClick={handleClickAnnotate}
        />
      </div>
    </React.Fragment>
  );
}
