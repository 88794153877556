import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import {
  subscribeToChannelMetadata,
  unsubscribeFromChannelMetadata,
} from '../lib/core';
import { channelKeyState, channelMetadataState } from '../state/channel';

// updates channelMetadata whenever channelKey changes
export default function useChannelMetadataSubscription() {
  const channelKey = useRecoilValue(channelKeyState);
  const setMetadata = useSetRecoilState(channelMetadataState);

  useEffect(() => {
    if (channelKey) {
      subscribeToChannelMetadata(channelKey, setMetadata);
    }

    // cleanup
    return function cleanup() {
      if (channelKey) {
        unsubscribeFromChannelMetadata(channelKey);
      }
    };
  }, [channelKey, setMetadata]);

  return null;
}
