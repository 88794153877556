import React from 'react';
import { /*useRecoilState,*/ useRecoilValue } from 'recoil';

import { blocksState } from '../hooks/doc';
import { playingSegmentState } from '../hooks/player';

export default function BottomBarTranslation() {
  // recoil state

  const blocks = useRecoilValue(blocksState);
  const { blockIndex } = useRecoilValue(playingSegmentState);

  const { translation } = (blocks && blocks[blockIndex]) || {};

  // render

  if (translation) {
    return <div className="bottom-bar-translation">{translation}</div>;
  }

  return null;
}
