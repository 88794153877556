import { atom } from 'recoil';

import shouldUseModalSideBar from '../lib/shouldUseModalSideBar';

export const topBarHeightState = atom({
  key: 'topBarHeightState',
  default: 0,
});

export const bottomBarHeightState = atom({
  key: 'bottomBarHeightState',
  default: 0,
});

export const isUsingModalSideBarState = atom({
  key: 'isUsingModalSideBarState',
  default: shouldUseModalSideBar(),
});

export const isShowingSideBarState = atom({
  key: 'isShowingSideBarState',
  default: !shouldUseModalSideBar(),
});

export const mediaHeightState = atom({
  key: 'mediaHeightState',
  default: 0,
});
