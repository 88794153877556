export default {
  apiKey: 'AIzaSyA94YwHZKacWRIlofQMN-bCt7UReAgINQo',
  authDomain: 'lessonize-phil.firebaseapp.com',
  databaseURL: 'https://lessonize-phil.firebaseio.com',
  projectId: 'lessonize-phil',
  storageBucket: 'lessonize-phil.appspot.com',
  messagingSenderId: '434121511721',
  appId: '1:434121511721:web:2f4c042ece2570afa69d38',
  measurementId: 'G-HPCMP113BT',
};
