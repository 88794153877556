import { Base64 } from 'js-base64';

import {
  callCloudFunction,
  downloadStorageObjectAsJson,
  getDbObject,
  subscribeToDbObject,
  unsubscribeFromDbOject,
} from './utils';

// paths

export function docDbPath(docKey) {
  return `/docs/${docKey}`;
}

export function docMetadataDbPath(docKey) {
  return `/docs/${docKey}/metadata`;
}

export function keywordDbPath(sourceLanguage, targetLanguage, word) {
  const base64Word = Base64.encodeURI(word.toLowerCase());
  return `keywords/${sourceLanguage}/${targetLanguage}/${base64Word}`;
}

export function keywordLinkDbPath(
  sourceLanguage,
  targetLanguage,
  word,
  docKey
) {
  const base64Word = Base64.encodeURI(word);
  return `keywords/${sourceLanguage}/${targetLanguage}/${base64Word}/${docKey}`;
}

export function docStoragePath(docKey) {
  return `docs/${docKey}`;
}

export function docVersionStoragePath(docKey, versionKey) {
  return `docVersions/${docKey}/${versionKey}`;
}

// current doc operations

export async function fetchDoc(docKey) {
  return getDbObject(docDbPath(docKey));
}

export async function fetchDocAsGuest(docKey) {
  return downloadStorageObjectAsJson(docStoragePath(docKey));
}

export function fetchDocMetadata(docKey) {
  return getDbObject(docMetadataDbPath(docKey));
}

export function deleteDoc(docKey) {
  return callCloudFunction('deleteDoc', { docKey });
}

// doc version operations

export function listDocVersions(docKey) {
  return callCloudFunction('listDocVersions', { docKey });
}

export function fetchDocVersion(docKey, versionKey) {
  return downloadStorageObjectAsJson(docVersionStoragePath(docKey, versionKey));
}

// keywords

export function fetchKeywordLinks(sourceLanguage, targetLanguage, word) {
  return getDbObject(keywordDbPath(sourceLanguage, targetLanguage, word));
}

export function subscribeToKeywordLinks(
  sourceLanguage,
  targetLanguage,
  word,
  handleUpdate
) {
  return subscribeToDbObject(
    keywordDbPath(sourceLanguage, targetLanguage, word),
    handleUpdate
  );
}

export function unsubscribeFromKeywordLinks(
  sourceLanguage,
  targetLanguage,
  word
) {
  return unsubscribeFromDbOject(
    keywordDbPath(sourceLanguage, targetLanguage, word)
  );
}
