import { Base64 } from 'js-base64';

import { getDbObject, setDbObject, shortKey } from './utils';

// encoding

export function decodeWord(encodedWord) {
  return Base64.decode(encodedWord);
}

export function encodeWord(text) {
  return Base64.encodeURI(text.toLowerCase());
}

export function encodeWordKeys(obj) {
  const encodedKeyObj = {};

  Object.entries(obj).forEach(([key, value]) => {
    const encodedKey = encodeWord(key);
    encodedKeyObj[encodedKey] = value;
  });

  return encodedKeyObj;
}

export function decodeWordKeys(obj) {
  const decodedKeyObj = {};

  Object.entries(obj).forEach(([encodedKey, value]) => {
    const decodedKey = decodeWord(encodedKey);
    decodedKeyObj[decodedKey] = value;
  });

  return decodedKeyObj;
}

// channel paths - user edited defs

export function channelDefsDbPath(
  channelKey,
  sourceLanguage,
  targetLanguage,
  word
) {
  if (!channelKey || !sourceLanguage || !targetLanguage || !word) {
    throw new Error('Invalid args');
  }
  return `/channelDefs/${channelKey}/${sourceLanguage}_${targetLanguage}/${encodeWord(
    word
  )}`;
}

export function allChannelDefsDbPath(
  channelKey,
  sourceLanguage,
  targetLanguage
) {
  if (!channelKey || !sourceLanguage || !targetLanguage) {
    throw new Error('Invalid args');
  }
  return `/channelDefs/${channelKey}/${sourceLanguage}_${targetLanguage}`;
}

// global paths - language admin edited defs

export function globalDefsDbPath(sourceLanguage, targetLanguage, word) {
  if (!sourceLanguage || !targetLanguage || !word) {
    throw new Error('Invalid args');
  }
  return `/globalDefs/${sourceLanguage}_${targetLanguage}/${encodeWord(word)}`;
}

export function allGlobalDefsDbPath(sourceLanguage, targetLanguage) {
  if (!sourceLanguage || !targetLanguage) {
    throw new Error('Invalid args');
  }
  return `/globalDefs/${sourceLanguage}_${targetLanguage}`;
}

// dict paths - unmodified dict entries

export function wordDataDbPath(sourceLanguage, word) {
  if (!sourceLanguage || !word) {
    throw new Error('Invalid args');
  }
  return `/wordData/${sourceLanguage}/${encodeWord(word)}`;
}

export function dictDefsDbPath(sourceLanguage, targetLanguage, word) {
  if (!sourceLanguage || !targetLanguage || !word) {
    throw new Error('Invalid args');
  }
  return `/dictDefs/${sourceLanguage}_${targetLanguage}/${encodeWord(word)}`;
}

export function wordListStoragePath(sourceLanguage) {
  if (!sourceLanguage) {
    throw new Error('Invalid args');
  }

  return `wordLists/${sourceLanguage}.json`;
}

// channel getters

export function getChannelDefs(
  channelKey,
  sourceLanguage,
  targetLanguage,
  word
) {
  return getDbObject(
    channelDefsDbPath(channelKey, sourceLanguage, targetLanguage, word)
  );
}

export function getAllChannelDefs(channelKey, sourceLanguage, targetLanguage) {
  return getDbObject(
    allChannelDefsDbPath(channelKey, sourceLanguage, targetLanguage)
  );
}

// global getters

export function getGlobalDefs(sourceLanguage, targetLanguage, word) {
  return getDbObject(globalDefsDbPath(sourceLanguage, targetLanguage, word));
}

export function getAllGlobalDefs(sourceLanguage, targetLanguage) {
  return getDbObject(allGlobalDefsDbPath(sourceLanguage, targetLanguage));
}

// dict getters

export function getDictDefs(sourceLanguage, targetLanguage, word) {
  return getDbObject(dictDefsDbPath(sourceLanguage, targetLanguage, word));
}

export function getWordData(sourceLanguage, word) {
  return getDbObject(wordDataDbPath(sourceLanguage, word));
}

// setters

export function setChannelDefs(
  channelKey,
  sourceLanguage,
  targetLanguage,
  word,
  defs
) {
  return setDbObject(
    channelDefsDbPath(channelKey, sourceLanguage, targetLanguage, word),
    defs
  );
}

// utility

export function replaceDefKeys(def) {
  const defWithKeys = { key: shortKey(), meaning: def.meaning };

  if (def.prons) {
    defWithKeys.prons = def.prons.map(pron => ({
      ...pron,
      key: shortKey(),
    }));
  }

  return defWithKeys;
}

export function getPronForKey(def, pronKey) {
  if (def && def.prons && def.prons.length > 0) {
    if (pronKey) {
      return def.prons.find(p => p.key === pronKey);
    } else {
      return def.prons[0];
    }
  }
  return null;
}

export function getDefForKey(word, defKey) {
  if (word && word.defs) {
    if (defKey) {
      return word.defs.find(d => d.key === defKey);
    } else {
      return word.defs[0];
    }
  }
  return null;
}
