import React from 'react';
import { Menu, MenuItem, MenuSurfaceAnchor } from 'rmwc';

import levels from '../constants/levels';

function LevelSelect(props) {
  const { children, languageLevel, setLevel, sourceLanguage } = props;
  const [open, setOpen] = React.useState(false);

  function onOpen() {
    setOpen(true);
  }

  function onClose() {
    setOpen(false);
  }

  function onSelect(event) {
    const { index } = event.detail;
    setLevel(levels[sourceLanguage][index]);
  }

  return (
    <span onClick={onOpen}>
      <div className="language-select">
        <MenuSurfaceAnchor className="language-select__anchor">
          <Menu
            open={open}
            onSelect={onSelect}
            onClose={onClose}
            renderToPortal
          >
            {levels[sourceLanguage].map(level => (
              <MenuItem
                id={`level-select__${level}`}
                key={level}
                selected={level === languageLevel}
              >
                {level}
              </MenuItem>
            ))}
          </Menu>
        </MenuSurfaceAnchor>
      </div>

      {children}
    </span>
  );
}

export default React.memo(LevelSelect);
