import firebase from 'firebase/app';

import languageCodes from '../constants/sourceLanguageCodes';
import { getAudioUrl } from '../lib/core';

let voices;

export function listVoices() {
  if (voices) {
    return Promise.resolve(voices);
  }

  return firebase
    .functions()
    .httpsCallable('listVoices')()
    .then(result => {
      const voiceArray = result.data;
      const byTtsLanguageCode = {};
      const byLanguageCode = {};

      // group voices by language code
      for (const voice of voiceArray) {
        for (const languageCode of voice.languageCodes) {
          if (!byTtsLanguageCode[languageCode]) {
            byTtsLanguageCode[languageCode] = [];
          }
          byTtsLanguageCode[languageCode].push(voice);
        }
      }

      for (const ttsLanguageCode of Object.keys(byTtsLanguageCode)) {
        for (const languageCode of languageCodes) {
          let remappedLanguageCode = languageCode;
          if (languageCode === 'zh') {
            remappedLanguageCode = 'cmn';
          }

          if (ttsLanguageCode.startsWith(remappedLanguageCode)) {
            if (!byLanguageCode[remappedLanguageCode]) {
              byLanguageCode[remappedLanguageCode] = [];
            }

            byLanguageCode[remappedLanguageCode] = byLanguageCode[
              remappedLanguageCode
            ].concat(byTtsLanguageCode[ttsLanguageCode]);
          }
        }
      }

      // sort voices by name
      for (const voices of Object.values(byLanguageCode)) {
        voices.sort((a, b) => (a.name < b.name ? -1 : 1));
      }

      // remap Chinese variants
      byLanguageCode['zh'] = byLanguageCode['cmn'];
      delete byLanguageCode['cmn'];

      // filter out Standard voices unless there are no Wavenet voices
      Object.keys(byLanguageCode).forEach(code => {
        const filtered = byLanguageCode[code].filter(voice =>
          voice.name.includes('Wavenet')
        );
        if (filtered.length > 0) {
          byLanguageCode[code] = filtered;
        }
      });

      // save reference for future calls
      voices = byLanguageCode;

      return voices;
    });
}

export async function synthesizeSpeech(voice, keyedText) {
  const { data: keyedAudioKeys } = await firebase
    .functions()
    .httpsCallable('synthesizeSpeech')({
    voice,
    keyedText,
  });

  // get url for each audioKey
  const keyedAudioUrls = {};
  await Promise.all(
    Object.entries(keyedAudioKeys).map(async ([key, audioKey]) => {
      keyedAudioUrls[key] = await getAudioUrl(audioKey);
    })
  );

  return keyedAudioUrls;
}
