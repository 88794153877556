import { atom, selector } from 'recoil';

import { keyVocabularyState } from './vocab';
import exerciseTypes from '../constants/exerciseTypes';
import { blocksState } from '../hooks/doc';

export const exerciseIndexState = atom({
  key: 'exerciseIndexState',
  default: null,
});

export const lastCompletedExerciseIndexState = atom({
  key: 'lastCompletedExerciseIndexState',
  default: null,
});

export const exercisesState = selector({
  key: 'exercisesState',
  get: ({ get }) => {
    const blocks = get(blocksState);
    const keyVocabulary = get(keyVocabularyState);
    const exercises = [];

    // add a fill-in-the-blanks for each block
    blocks.forEach((block, index) => {
      if (block.segments && block.segments.some(segment => segment.isBlank)) {
        exercises.push({
          type: block.fillBlanksWithAudio
            ? exerciseTypes.fillBlanksWithAudio
            : exerciseTypes.fillBlanksWithTranslation,
          block,
          blockIndex: index,
        });
      }
    });

    // add vocab matching exercises if at least 3 words
    if (keyVocabulary.length >= 3) {
      // insert match audio->meanings at 2/3 of the way through
      const twoThirdsIndex = Math.round((exercises.length * 2) / 3);
      exercises.splice(twoThirdsIndex, 0, {
        type: exerciseTypes.matchAudioToMeanings,
        keyVocabularyList: keyVocabulary,
      });

      // insert match words->meanings at 1/3 of the way through
      const oneThirdIndex = Math.round(exercises.length / 3);
      exercises.splice(oneThirdIndex, 0, {
        type: exerciseTypes.matchWordsToMeanings,
        keyVocabularyList: keyVocabulary,
      });

      // insert match audio->words at beginning
      exercises.splice(0, 0, {
        type: exerciseTypes.matchAudioToWords,
        keyVocabularyList: keyVocabulary,
      });
    }

    return exercises.length > 0 ? exercises : null;
  },
});
