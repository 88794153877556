import produce from 'immer';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, CircularProgress, IconButton, Radio } from 'rmwc';

import TimeShifter from './TimeShifter';
import {
  blocksState,
  mediaStartsOnFirstCaptionState,
  metadataState,
} from '../hooks/doc';
import { highlightedSegmentIndexState } from '../hooks/editor';
import { useMediaInput } from '../hooks/player';
import useSelectSegmentOnClick from '../hooks/useSelectSegmentOnClick';
import useSelectSegmentOnKeyPress from '../hooks/useSelectSegmentOnKeyPress';
import captureException from '../lib/captureException';
import combineAudio from '../lib/combineAudio';
import syncTextToAudio from '../lib/syncTextToAudio';
import synthesizeWordAudio from '../lib/synthesizeWordAudio';

const previewDuration = 0.5;

function updateSegmentStartTime(draftSegments, index, newTime) {
  if (draftSegments[index]) {
    // update time of selected segment
    const oldTime = draftSegments[index].time;
    draftSegments[index].startTime = newTime;

    // shift later segments forward as needed
    if (newTime > oldTime) {
      for (let i = index + 1; i < draftSegments.length; i += 1) {
        if (typeof draftSegments[i].time === 'number') {
          if (draftSegments[i].startTime < newTime) {
            draftSegments[i].startTime = newTime;
          }
        }
      }
    }

    // shift previous segments backward as needed
    else if (newTime < oldTime) {
      for (let i = index - 1; i >= 0; i -= 1) {
        if (typeof draftSegments[i].startTime === 'number') {
          if (draftSegments[i].startTime > newTime) {
            draftSegments[i].startTime = newTime;
          }
        }
      }
    }
  }
}

function SyncToolbar(props) {
  const { block, index } = props;
  const { segments } = block;

  // recoil state

  const [blocks, setBlocks] = useRecoilState(blocksState);
  const highlightedSegmentIndex = useRecoilValue(highlightedSegmentIndexState);
  const [
    mediaStartsOnFirstCaption,
    setMediaStartsOnFirstCaption,
  ] = useRecoilState(mediaStartsOnFirstCaptionState);
  const [metadata, setMetadata] = useRecoilState(metadataState);

  // local state

  const initTimeRef = useRef(0);

  const [isCombining, setIsCombining] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSynthesizing, setIsSynthesizing] = useState(false);

  const [mainMediaElement, setMainMediaElement] = useState(null);

  // connect to media

  const { playClip, resetPlayerStartAndEndTime } = useMediaInput();

  // derived state

  const { audioKey, sourceLanguage, targetLanguage, videoKey, wordAudioKey } =
    metadata || {};
  const segment = segments && segments[highlightedSegmentIndex];
  const { startTime } = segment || {};
  const hasStartTime = typeof startTime === 'number';

  const isProcessing = isCombining || isSyncing || isSynthesizing;

  // hooks

  useSelectSegmentOnClick();

  // handle selection

  function handleSelectAll() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          block.isSelected = true;
        });
      })
    );
  }

  function handleSelectNone() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          delete block.isSelected;
        });
      })
    );
  }

  // handle combine

  async function combineAudioUrls(
    blocks,
    blockAudioUrlFieldName,
    metadataAudioKeyFieldName
  ) {
    // created array of audioUrls to combine
    const audioUrls = [];
    blocks.forEach(block => {
      if (block.hasAudio) {
        audioUrls.push(block[blockAudioUrlFieldName]);
      }
    });

    // call combine cloud function
    const audioKey = await combineAudio(audioUrls);

    // update metadata in recoil state
    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata[metadataAudioKeyFieldName] = audioKey;
      })
    );
  }

  async function handleCombineSelected() {
    setIsCombining(true);
    try {
      await combineAudioUrls(blocks, 'sentenceAudioUrl', 'audioKey');

      // remove word tts flag from metadata
      setMetadata(metadata =>
        produce(metadata, draftMetadata => {
          draftMetadata.wordTts = null;
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setIsCombining(false);
    }
  }

  // handle sync

  async function handleSyncSelected() {
    setIsSyncing(true);
    try {
      // created map of keys to segments for segmenting
      let segments = [];
      blocks.forEach(block => {
        if (block.hasAudio) {
          segments = segments.concat(block.segments);
        }
      });

      // call annotate cloud function
      const syncedSegments = await syncTextToAudio(
        sourceLanguage,
        targetLanguage,
        Boolean(videoKey),
        videoKey || audioKey,
        segments
      );

      // update blocks in recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          let segmentPos = 0;
          draftBlocks.forEach(block => {
            if (block.hasAudio) {
              const numSegmentsInBlock = block.segments.length;

              if (block.isSelected) {
                // update block.segments if selected
                block.segments = syncedSegments.slice(
                  segmentPos,
                  segmentPos + numSegmentsInBlock
                );
              }

              // advance pos
              segmentPos += numSegmentsInBlock;
            }
          });
        })
      );

      // now sync words as well

      // call annotate cloud function
      const syncedWordSegments = await syncTextToAudio(
        sourceLanguage,
        targetLanguage,
        false,
        wordAudioKey,
        segments
      );

      // update blocks in recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          let segmentPos = 0;
          draftBlocks.forEach(block => {
            if (block.hasAudio) {
              const numSegmentsInBlock = block.segments.length;
              const blockSyncedSegments = syncedWordSegments.slice(
                segmentPos,
                segmentPos + numSegmentsInBlock
              );

              // update block.segments if selected
              if (block.isSelected) {
                block.segments = block.segments.map((segment, index) => {
                  const { startTime, endTime } = blockSyncedSegments[index];
                  if (typeof startTime === 'number') {
                    return {
                      ...segment,
                      wordStart: startTime,
                      wordEnd: endTime,
                    };
                  } else {
                    return segment;
                  }
                });
              }

              segmentPos += numSegmentsInBlock;
            }
          });
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setIsSyncing(false);
    }
  }

  // handle init time

  const handleInitTime = useCallback(() => {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        const draftSegments = draftBlocks[index].segments;
        if (draftSegments[highlightedSegmentIndex]) {
          draftSegments[highlightedSegmentIndex].startTime =
            initTimeRef.current;
        }
      })
    );
  }, [highlightedSegmentIndex, index, setBlocks]);

  // handler change inside TimeShifter

  const handleChangeTime = useCallback(
    newTime => {
      if (hasStartTime) {
        // preview
        playClip(newTime, newTime + previewDuration);

        // update recoil state
        setBlocks(blocks =>
          produce(blocks, draftBlocks => {
            updateSegmentStartTime(
              draftBlocks[index].segments,
              highlightedSegmentIndex,
              newTime
            );
          })
        );
      } else {
        initTimeRef.current = newTime;
      }
    },
    [hasStartTime, highlightedSegmentIndex, index, playClip, setBlocks]
  );

  // handle delete time

  const handleDeleteTime = useCallback(() => {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        const draftSegments = draftBlocks[index].segments;
        const draftSegment =
          draftSegments && draftSegments[highlightedSegmentIndex];
        if (draftSegment) {
          delete draftSegment.startTime;
          delete draftSegment.endTime;
        }
      })
    );
  }, [highlightedSegmentIndex, index, setBlocks]);

  // synthesize word audio

  async function handleSynth() {
    setIsSynthesizing(true);
    try {
      // call cloud function
      const { audioDataMap, audioKey } = await synthesizeWordAudio(
        metadata.sourceLanguage,
        blocks
      );

      // update recoil state

      setMetadata(metadata =>
        produce(metadata, draftMetadata => {
          draftMetadata.wordAudioKey = audioKey;
          draftMetadata.wordTts = true;
        })
      );

      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks.forEach(block => {
            if (block.segments) {
              block.segments.forEach(segment => {
                if (segment.hasDefs && audioDataMap[segment.text]) {
                  const { start, end } = audioDataMap[segment.text];
                  segment.wordStart = start;
                  segment.wordEnd = end;
                }
              });
            }
          });
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setIsSynthesizing(false);
    }
  }

  // init and update main and word media elements

  useLayoutEffect(() => {
    (async () => {
      if (videoKey) {
        setMainMediaElement(document.querySelector('video'));
      } else if (audioKey) {
        setMainMediaElement(document.querySelector('audio#sync-main'));
      } else {
        setMainMediaElement(null);
      }
    })();
  }, [audioKey, videoKey]);

  // handle key presses

  const handleSpaceKey = useCallback(() => {
    if (typeof startTime === 'number') {
      handleDeleteTime();
    } else {
      // set segment start time to current timeline position
      handleInitTime();

      // preview
      playClip(initTimeRef.current, initTimeRef.current + previewDuration);
    }
  }, [handleDeleteTime, handleInitTime, playClip, startTime]);

  useSelectSegmentOnKeyPress(handleSpaceKey);

  // reset player time bounds on unmount
  useEffect(() => {
    return resetPlayerStartAndEndTime;
  }, [resetPlayerStartAndEndTime]);

  // play clip on highlightedSegmentIndex change
  useEffect(() => {
    if (typeof startTime === 'number') {
      playClip(startTime, startTime + previewDuration);
    }
  }, [highlightedSegmentIndex, playClip, startTime]);

  // render

  return (
    <div>
      <div>
        {false /* temporarily remove block audio recording and sync */ && (
          <Button
            disabled={isProcessing || !metadata || !blocks}
            icon="done_all"
            label="All"
            onClick={handleSelectAll}
          />
        )}

        {false /* temporarily remove block audio recording and sync */ && (
          <Button
            disabled={isProcessing || !metadata || !blocks}
            icon="check_box_outline_blank"
            label="None"
            onClick={handleSelectNone}
          />
        )}

        <Button
          disabled={isProcessing || !metadata || !blocks}
          icon={isCombining ? <CircularProgress /> : 'merge_type'}
          label="Combine"
          onClick={handleCombineSelected}
        />

        {false /* temporarily remove block audio recording and sync */ && (
          <Button
            disabled={isProcessing || !metadata || !blocks}
            icon={isSyncing ? <CircularProgress /> : 'timer'}
            label="Sync"
            onClick={handleSyncSelected}
          />
        )}

        <Button
          disabled={isProcessing || !metadata || !blocks}
          icon={isSynthesizing ? <CircularProgress /> : 'graphic_eq'}
          label="Synth"
          onClick={handleSynth}
        />
      </div>

      <div className="sync-toolbar__media-start-time-group">
        <div className="sync-toolbar__media-start-time-label">
          Start playing media
        </div>

        <Radio
          checked={!mediaStartsOnFirstCaption}
          className="sync-toolbar__media-start-time-radio"
          onChange={event =>
            setMediaStartsOnFirstCaption(event.currentTarget.value === 'true')
          }
          value="false"
        >
          At 0:00
        </Radio>

        <Radio
          checked={Boolean(mediaStartsOnFirstCaption)}
          className="sync-toolbar__media-start-time-radio"
          onChange={event =>
            setMediaStartsOnFirstCaption(event.currentTarget.value === 'true')
          }
          value="true"
        >
          On first caption
        </Radio>
      </div>

      <div>
        {!hasStartTime && <IconButton icon="add" onClick={handleInitTime} />}

        {hasStartTime && (
          <IconButton icon="delete" onClick={handleDeleteTime} />
        )}

        <TimeShifter
          duration={metadata.duration}
          id={block ? block.key + highlightedSegmentIndex : ''}
          mediaElement={mainMediaElement}
          onChange={handleChangeTime}
          startTime={startTime}
        />
      </div>
    </div>
  );
}

export default React.memo(SyncToolbar);
