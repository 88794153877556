import produce from 'immer';
import React from 'react';
import { useRecoilState } from 'recoil';
import { Button, IconButton } from 'rmwc';
import shortid from 'shortid';

import Editable from './Editable';
import { correctSound, incorrectSound } from '../lib/sounds';
import { answersState } from '../state/answers';

function Choices(props) {
  const { block, canAnswer, index, isEditing, setBlock } = props;

  const { choices } = block;

  const [answers, setAnswers] = useRecoilState(answersState);

  const isAnswered = Boolean(answers[block.key]);

  // add choice during editing

  const handleClickAddChoice = React.useCallback(() => {
    // update shared state
    const newBlock = produce(block, draftBlock => {
      if (!draftBlock.choices) {
        draftBlock.choices = [];
      }
      draftBlock.choices.push({ key: shortid.generate(), text: '...' });
    });
    setBlock(index, newBlock);
  }, [block, index, setBlock]);

  // edit choice text

  const handleChoiceChange = React.useCallback(
    (choiceIndex, newText) => {
      // update shared state
      const newBlock = produce(block, draftBlock => {
        if (newText) {
          draftBlock.choices[choiceIndex].text = newText;
        } else {
          draftBlock.choices.splice(choiceIndex, 1);
        }
      });
      setBlock(index, newBlock);
    },
    [block, index, setBlock]
  );

  // set correct choice index during editing

  const setCorrectChoice = React.useCallback(
    correctKey => {
      // update shared state
      const newBlock = produce(block, draftBlock => {
        for (let i = 0; i < draftBlock.choices.length; i++) {
          const choice = draftBlock.choices[i];
          if (choice.key === correctKey) {
            choice.isCorrect = true;
          } else {
            delete choice.isCorrect;
          }
        }
      });
      setBlock(index, newBlock);
    },
    [block, index, setBlock]
  );

  // click choice while studying

  const handleClickChoice = React.useCallback(
    choice => {
      if (choice.isCorrect) {
        correctSound.play();

        // update answers state
        setAnswers(answers =>
          produce(answers, draftAnswers => {
            draftAnswers[block.key] = choice.key;
          })
        );
      } else {
        incorrectSound.play();
      }
    },
    [block, setAnswers]
  );

  // render

  return (
    <div className="choices">
      {!isEditing &&
        choices &&
        choices.map((choice, choiceIndex) => {
          // determine class name
          let buttonClass;
          if (!canAnswer) {
            buttonClass = 'choices__choice--disabled';
          } else {
            if (isAnswered) {
              buttonClass = choice.isCorrect
                ? 'choices__choice--correct-answered'
                : 'choices__choice--incorrect-answered';
            } else {
              buttonClass = choice.isCorrect
                ? 'choices__choice--correct'
                : 'choices__choice--incorrect';
            }
          }

          // render choice button
          return (
            <div className="choices__choice-row" key={choiceIndex}>
              <Button
                className={buttonClass}
                disabled={!canAnswer || isAnswered}
                label={choice.text}
                onClick={() => handleClickChoice(choice)}
                theme="secondaryBg"
                unelevated
              />
            </div>
          );
        })}

      {isEditing &&
        choices &&
        choices.map((choice, choiceIndex) => (
          <div
            className="choices__choice-row"
            // include length in key to re-render on delete
            key={choice.key}
          >
            <IconButton
              icon={
                choice.isCorrect
                  ? 'check_circle_outline'
                  : 'radio_button_unchecked'
              }
              onClick={() => setCorrectChoice(choice.key)}
            />
            <div className="choices__choice--correct">
              <Editable
                id={`choice-${choiceIndex}-editable`}
                initialText={choice.text}
                onChange={text => handleChoiceChange(choiceIndex, text)}
              />
            </div>
          </div>
        ))}

      {isEditing && (
        <div className="choices__choice-row">
          <IconButton
            className="choices__add-button-icon"
            icon="radio_button_unchecked"
          />
          <Button
            className="choices__add-button"
            icon="add"
            onClick={handleClickAddChoice}
          />
        </div>
      )}
    </div>
  );
}

export default React.memo(Choices);
