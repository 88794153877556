import { useEffect, useState } from 'react';

import { listAllDocs } from '../lib/core';

let cache = null;

export default function useAllDocMetadata() {
  const [allDocMetadata, setAllDocMetadata] = useState(cache);

  useEffect(() => {
    (async () => {
      const docs = await listAllDocs();
      const listedDocs = docs.filter(doc => !doc.isUnlisted);
      setAllDocMetadata(listedDocs);
      cache = listedDocs;
    })();
  }, [setAllDocMetadata]);

  return allDocMetadata;
}
