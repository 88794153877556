import React, { useEffect, useState } from 'react';

import AudioChoice from './AudioChoice';
import MeaningChoice from './MeaningChoice';
import WordChoice from './WordChoice';
import exerciseTypes from '../../constants/exerciseTypes';
import useWordAudioSprites from '../../hooks/useWordAudioSprites';
import audioSpriteKey from '../../lib/audioSpriteKey';
import shuffle from '../../lib/shuffle';
import { correctSound, incorrectSound } from '../../lib/sounds';

import './Exercises.css';

export default function MatchVocab(props) {
  // props

  const { exercise, onComplete } = props;

  // hooks

  const wordAudioSprites = useWordAudioSprites();

  // local state

  const [topChoices, setTopChoices] = useState([]);
  const [bottomChoices, setBottomChoices] = useState([]);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(null);
  const [completedIndices, setCompletedIndices] = useState({});

  // derived state

  let prompt;
  let TopChoiceTag;
  let BottomChoiceTag;
  switch (exercise.type) {
    case exerciseTypes.matchAudioToWords:
      prompt = 'Match each sound with the correct word';
      TopChoiceTag = AudioChoice;
      BottomChoiceTag = WordChoice;
      break;
    case exerciseTypes.matchWordsToMeanings:
      prompt = 'Match each word with the correct meaning';
      TopChoiceTag = WordChoice;
      BottomChoiceTag = MeaningChoice;
      break;
    case exerciseTypes.matchAudioToMeanings:
      prompt = 'Match each sound with the correct meaning';
      TopChoiceTag = AudioChoice;
      BottomChoiceTag = MeaningChoice;
      break;
    default:
      break;
  }

  // update choices on exercise change

  useEffect(() => {
    const newTopChoices = [];
    const newBottomChoices = [];

    exercise.keyVocabularyList.forEach((keyVocabulary, index) => {
      const { segment, word } = keyVocabulary;
      newTopChoices.push({ index, segment, word });
      newBottomChoices.push({
        index,
        segment,
        word,
      });
    });

    shuffle(newTopChoices);
    shuffle(newBottomChoices);

    setTopChoices(newTopChoices);
    setBottomChoices(newBottomChoices);
    setSelectedChoiceIndex(null);
    setCompletedIndices({});
  }, [exercise]);

  // handlers

  function handleSelectChoice(newIndex) {
    if (typeof selectedChoiceIndex === 'number') {
      // if a choice is currently selected
      const selectedIsTopChoice = selectedChoiceIndex < topChoices.length;
      const newIsTopChoice = newIndex < topChoices.length;
      if (
        (selectedIsTopChoice && newIsTopChoice) ||
        (!selectedIsTopChoice && !newIsTopChoice)
      ) {
        // if both current and new selections are from same set, select new one
        setSelectedChoiceIndex(newIndex);
      } else {
        // otherwise, determine whether choices match
        const selectedChoice = selectedIsTopChoice
          ? topChoices[selectedChoiceIndex]
          : bottomChoices[selectedChoiceIndex - topChoices.length];
        const newChoice = newIsTopChoice
          ? topChoices[newIndex]
          : bottomChoices[newIndex - topChoices.length];
        if (selectedChoice.index === newChoice.index) {
          correctSound.play();
          const newCompletedIndices = {
            ...completedIndices,
            [newChoice.index]: true,
          };
          setSelectedChoiceIndex(null);
          setCompletedIndices(newCompletedIndices);
          if (Object.keys(newCompletedIndices).length === topChoices.length) {
            onComplete();
          }
        } else {
          incorrectSound.play();
          setSelectedChoiceIndex(null);
        }
      }
    } else {
      // otherwise, select choice
      setSelectedChoiceIndex(newIndex);
    }

    // play sound
    const choice = topChoices[newIndex];
    if (choice && wordAudioSprites) {
      wordAudioSprites.play(audioSpriteKey(choice.segment));
    }
  }

  // render

  return (
    <React.Fragment>
      <div className="exercise__prompt">{prompt}</div>

      <div className="exercise__top-choice-container">
        {topChoices.map((choice, index) => {
          const isSelected = index === selectedChoiceIndex;
          return (
            <TopChoiceTag
              choice={choice}
              index={index}
              isComplete={completedIndices[choice.index]}
              isSelected={isSelected}
              key={index}
              onClick={() => handleSelectChoice(index)}
            />
          );
        })}
      </div>

      <div className="exercise__bottom-choice-container">
        {bottomChoices.map((choice, index) => {
          const isSelected = topChoices.length + index === selectedChoiceIndex;
          return (
            <BottomChoiceTag
              choice={choice}
              index={index}
              isComplete={
                completedIndices[choice.index] || selectedChoiceIndex === null
              }
              isSelected={isSelected}
              key={index}
              onClick={
                isSelected
                  ? null
                  : () => handleSelectChoice(topChoices.length + index)
              }
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}
