import ContentEditable from 'react-contenteditable';
import React, { useEffect } from 'react';

import { cleanSpacing, segmentsToHtml, updateSegments } from '../lib/editable';

export default React.memo(props => {
  const {
    autoFocus,
    disabled,
    id,
    initialSegments,
    initialText,
    onChange,
    onFocus,
    placeholder,
  } = props;

  // contentEditable html
  const textInnerRef = React.useRef();
  const textRefValue = initialSegments
    ? segmentsToHtml(initialSegments)
    : initialText;
  const textRef = React.useRef(textRefValue);

  // handle text editing

  const handleTextChange = React.useCallback(
    event => {
      // update local contentEditable
      const html = event.target.value;
      textRef.current = html.replace(/(<([^a>]+)>)/gi, '').trim();

      // update parent
      if (initialSegments) {
        const updatedSegments = updateSegments(
          initialSegments,
          textInnerRef.current.textContent
        );
        onChange(updatedSegments);
      } else {
        onChange(cleanSpacing(textRef.current));
      }
    },
    [initialSegments, textRef, onChange]
  );

  // focus on start editing block

  useEffect(() => {
    if (autoFocus && textInnerRef.current) {
      textInnerRef.current.focus();
    }
  }, [autoFocus]);

  // update html when initialSegments changes
  React.useEffect(() => {
    // update textRef
    const textRefValue = initialSegments
      ? segmentsToHtml(initialSegments)
      : initialText;
    textRef.current = textRefValue;

    // update element innerHTML
    if (document.activeElement !== textInnerRef.current) {
      textInnerRef.current.innerHTML = textRefValue;
    }
  }, [initialSegments, initialText]);

  // render

  return (
    <ContentEditable
      className="editable"
      disabled={disabled}
      html={textRef.current || ''}
      id={id}
      innerRef={textInnerRef}
      onChange={handleTextChange}
      onFocus={onFocus}
      placeholder={placeholder}
    />
  );
});
