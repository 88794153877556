import dayjs from 'dayjs';

import { getDbObject, setDbObject, setDbObjectTransaction } from './utils';

// paths

/*
/docProgress/{uid}/{docKey}
  viewCount
  exerciseIndex
  firstDate
  lastDate
*/

export function allUserDocProgressDbPath(uid) {
  if (!uid) {
    throw new Error('Invalid args');
  }
  return `/docProgress/${uid}`;
}

export function userDocProgressDbPath(uid, docKey) {
  if (!uid || !docKey) {
    throw new Error('Invalid args');
  }
  return `/docProgress/${uid}/${docKey}`;
}

export function userDocLastCompletedExerciseIndexDbPath(uid, docKey) {
  if (!uid || !docKey) {
    throw new Error('Invalid args');
  }
  return `/docProgress/${uid}/${docKey}/lastCompletedExerciseIndex`;
}

// getters

export function getAllUserDocProgress(uid) {
  return getDbObject(allUserDocProgressDbPath(uid));
}

export function getUserDocProgress(uid, docKey) {
  return getDbObject(userDocProgressDbPath(uid, docKey));
}

// setters

export function trackDocView(uid, docKey) {
  return setDbObjectTransaction(
    userDocProgressDbPath(uid, docKey),
    docProgress => {
      const now = new Date().getTime();

      // if first view, init
      if (!docProgress) {
        return {
          viewCount: 1,
          firstDate: now,
          lastDate: now,
        };
      }

      // otherwise, increment viewCount if lastDate was more than 1 hour ago
      const newViewCount = dayjs(docProgress.lastDate).isBefore(
        dayjs().subtract(1, 'hour')
      )
        ? docProgress.viewCount + 1
        : docProgress.viewCount;
      return {
        ...docProgress,
        viewCount: newViewCount,
        // and upate lastDate
        lastDate: now,
      };
    }
  );
}

export function trackExerciseProgress(uid, docKey, lastCompletedExerciseIndex) {
  return setDbObject(
    userDocLastCompletedExerciseIndexDbPath(uid, docKey),
    lastCompletedExerciseIndex
  );
}
