import firebase from 'firebase/app';
import LogRocket from 'logrocket';

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { RMWCProvider } from '@rmwc/provider';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';
import 'firebase/storage';
import '@rmwc/circular-progress/circular-progress.css';
import '@rmwc/icon/styles';
import '@rmwc/tooltip/tooltip.css';
import 'material-components-web/dist/material-components-web.min.css';

import App from './components/App';
import { setFirebaseShim } from './lib/core';
import getFirebaseConfig from './lib/getFirebaseConfig';
import getPageTitle from './lib/getPageTitle';
import isLocal from './lib/isLocal';
import isProduction from './lib/isProduction';
import './lib/captureException';
import './lib/i18n';
import './components/Doc.css';
import './colors.css';
import './index.css';
import './theme.css';

// init logrocket if production

if (isProduction()) {
  LogRocket.init('vbtujf/lessonize', {
    release: process.env.npm_package_version,
  });
}

// configure firebase

firebase.initializeApp(getFirebaseConfig());

if (isLocal()) {
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

setFirebaseShim(firebase);

// set page title

document.title = getPageTitle();

// render

ReactDOM.render(
  <RecoilRoot>
    <BrowserRouter>
      <RMWCProvider
        icon={{
          basename: 'material-icons-round',
        }}
        ripple={false}
      >
        <App />
      </RMWCProvider>
    </BrowserRouter>
  </RecoilRoot>,
  document.getElementById('root')
);
