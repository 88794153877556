import produce from 'immer';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilValue } from 'recoil';
import { Button, CircularProgress, Icon } from 'rmwc';

import VocabToggle from './VocabToggle';
import smallProns from '../../constants/smallProns';
import { metadataState, pronOptionsState } from '../../hooks/doc';
import captureException from '../../lib/captureException';
import {
  getDictDefs,
  getWordData,
  replaceDefKeys,
  shortKey,
} from '../../lib/core';
import { createDef, createPron } from '../../lib/createWord';
import { cleanSpacing } from '../../lib/editable';

import './SegmentEditor.css';
import ReferenceDefs from './ReferenceDefs';

function SegmentEditor(props) {
  const { onChangeSegment, onSaveWord, segment, word } = props;

  // recoil state

  const metadata = useRecoilValue(metadataState);
  const pronOptions = useRecoilValue(pronOptionsState);

  // local state

  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [newWord, setNewWord] = useState(word);
  const [dictDefs, setDictDefs] = useState(null);
  const [charDefsArray, setCharDefsArray] = useState(null);

  // derived state

  const { sourceLanguage, targetLanguage } = metadata;

  // update newDefs and stop editing when segment or word changes

  useEffect(() => {
    (async () => {
      setNewWord(word);
      setIsEditing(false);
      setDictDefs(null);
      setCharDefsArray(null);
    })();
  }, [segment, word]);

  // selection handlers

  function handleClickPron(def, pron) {
    if (!isEditing) {
      onChangeSegment(
        produce(segment, draftSegment => {
          draftSegment.defKey = def.key;
          draftSegment.pronKey = pron.key;
        })
      );
    }
  }

  function handleClickDef(def) {
    if (!isEditing) {
      onChangeSegment(
        produce(segment, draftSegment => {
          draftSegment.defKey = def.key;
        })
      );
    }
  }

  // editing handlers

  async function handleClickEdit() {
    setIsEditing(true);

    // update word data

    const chars = segment.text.split('');
    let wordData = await getWordData(sourceLanguage, segment.text);

    if (sourceLanguage === 'zh') {
      // if we have char data
      if (!wordData) {
        // if we don't have word data, at least get char data
        const charWordData = await Promise.all(
          chars.map(char => getWordData(sourceLanguage, char))
        );
        // get first char data item for each char's wordData
        wordData = { chars: charWordData.map(data => data.chars[0]) };
      }
    }

    // update newWord if data found
    if (wordData) {
      setNewWord(newWord =>
        produce(newWord, draftWord => {
          draftWord.data = wordData;
        })
      );
    }

    // get dict defs

    setDictDefs(
      await getDictDefs(sourceLanguage, targetLanguage, segment.text)
    );

    // get char defs

    if (sourceLanguage === 'zh') {
      // if we have char data
      if (chars.length > 1) {
        setCharDefsArray(
          await Promise.all(
            chars.map(char => getDictDefs(sourceLanguage, targetLanguage, char))
          )
        );
      }
    }
  }

  async function handleClickSave() {
    // first clear segment def and pron keys in case they point
    // to something that doesn't exist after editing
    onChangeSegment(
      produce(segment, draftSegment => {
        draftSegment.defKey = null;
        draftSegment.pronKey = null;
      })
    );

    // then save
    setIsSaving(true);
    try {
      await onSaveWord(newWord);
      setIsEditing(false);
    } catch (error) {
      captureException(error);
    } finally {
      setIsSaving(false);
    }
  }

  function handleClickCancel() {
    setNewWord(word);
    setIsEditing(false);
  }

  function handleKeyDown(event) {
    event.stopPropagation();
  }

  function handleChangePron(defIndex, pronIndex, pronOption, newPron) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        draftWord.defs[defIndex].prons[pronIndex][pronOption] = cleanSpacing(
          newPron
        );
      })
    );
  }

  function handleClickDeletePron(defIndex, pronIndex) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        draftWord.defs[defIndex].prons.splice(pronIndex, 1);
      })
    );
  }

  function handleMovePronToTop(defIndex, pronIndex) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        // remove pron
        const [pron] = draftWord.defs[defIndex].prons.splice(pronIndex, 1);
        // insert at beginning
        draftWord.defs[defIndex].prons.splice(0, 0, pron);
      })
    );
  }

  function handleClickAddPron(defIndex) {
    // create pron
    const newKey = shortKey();
    const newPron = createPron(pronOptions, newKey);

    // append to selected def's prons
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        draftWord.defs[defIndex].prons.push(newPron);
      })
    );
  }

  function handleChangeMeaning(defIndex, newMeaning) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        draftWord.defs[defIndex].meaning = cleanSpacing(newMeaning);
      })
    );
  }

  function handleMoveDefToTop(defIndex) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        // remove def
        const [def] = draftWord.defs.splice(defIndex, 1);
        // insert at beginning
        draftWord.defs.splice(0, 0, def);
      })
    );
  }

  function handleCopyDef(def) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        // copy def
        const newDef = replaceDefKeys(def);
        // insert at beginning
        draftWord.defs.splice(0, 0, newDef);
      })
    );
  }

  function handleDeleteDef(defIndex) {
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        draftWord.defs.splice(defIndex, 1);
      })
    );
  }

  function handleClickAddDef() {
    // create def
    const newDef = createDef(pronOptions);

    // append to defs
    setNewWord(newWord =>
      produce(newWord, draftWord => {
        draftWord.defs.push(newDef);
      })
    );
  }

  // render

  if (!segment || !word) {
    return null;
  }

  return (
    <div className="segment-editor">
      <div className="segment-editor__row">
        <div className="segment-editor__word">{segment.text}</div>

        <div className="segment-editor__edit-save-buttons">
          {!isEditing && <VocabToggle word={segment.text} />}
          {!isEditing && (
            <Button
              icon="edit"
              label="Edit"
              onClick={handleClickEdit}
              outlined
            />
          )}
          {isEditing && (
            <Button
              className="segment-editor__save-button"
              disabled={isSaving}
              icon={isSaving ? <CircularProgress /> : 'save'}
              label="Save"
              onClick={handleClickSave}
              raised
            />
          )}
          {isEditing && (
            <Button
              disabled={isSaving}
              icon="close"
              label="Cancel"
              onClick={handleClickCancel}
              outlined
            />
          )}
        </div>
      </div>

      <div className="segment-editor__def-list">
        {newWord.defs.map((def, defIndex) => {
          const meaningClasses =
            'segment-editor__meaning' +
            (!isEditing &&
            (def.key === segment.defKey || newWord.defs.length === 1)
              ? ' segment-editor__meaning--selected'
              : '') +
            (isEditing ? ' segment-editor__input' : '');

          return (
            <div className="segment-editor__def" key={def.key}>
              {def.prons && (
                <div className="segment-editor__pron-list">
                  {def.prons.map((pron, pronIndex) => {
                    const pronGroupClasses =
                      'segment-editor__pron-group' +
                      (!isEditing &&
                      pron.key &&
                      (def.key === segment.defKey ||
                        newWord.defs.length === 1) &&
                      (pron.key === segment.pronKey || def.prons.length === 1)
                        ? ' segment-editor__pron-group--selected'
                        : '');
                    return (
                      <div
                        className={pronGroupClasses}
                        key={pron.key}
                        onClick={() => handleClickPron(def, pron)}
                      >
                        {isEditing && def.prons.length > 1 && (
                          <Icon
                            className="segment-editor__icon-button segment-editor__delete-pron-button"
                            icon="delete"
                            onClick={() =>
                              handleClickDeletePron(defIndex, pronIndex)
                            }
                          />
                        )}

                        {pronOptions.map(pronOption => {
                          let pronOptionClasses =
                            'segment-editor__pron' +
                            (isEditing ? ' segment-editor__input' : '');
                          if (smallProns[pronOption]) {
                            pronOptionClasses += ' segment-editor__pron--small';
                          }

                          return (
                            <ContentEditable
                              className={pronOptionClasses}
                              disabled={!isEditing}
                              html={pron[pronOption] || ''}
                              key={pronOption}
                              onChange={event =>
                                handleChangePron(
                                  defIndex,
                                  pronIndex,
                                  pronOption,
                                  event.target.value
                                )
                              }
                              onKeyDown={handleKeyDown}
                              placeholder={pronOption}
                            />
                          );
                        })}

                        {isEditing && pronIndex > 0 && (
                          <Icon
                            className="segment-editor__icon-button segment-editor__move-pron-up-button"
                            icon="vertical_align_top"
                            onClick={() =>
                              handleMovePronToTop(defIndex, pronIndex)
                            }
                          />
                        )}

                        {isEditing && pronIndex === def.prons.length - 1 && (
                          <Icon
                            className="segment-editor__icon-button segment-editor__add-pron-button"
                            icon="add"
                            onClick={() => handleClickAddPron(defIndex)}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              <ContentEditable
                className={meaningClasses}
                disabled={!isEditing}
                html={def.meaning || ''}
                onChange={event =>
                  handleChangeMeaning(defIndex, event.target.value)
                }
                onClick={() => handleClickDef(def)}
                onKeyDown={handleKeyDown}
                placeholder="meaning"
              />

              {isEditing && defIndex > 0 && (
                <Icon
                  className="segment-editor__icon-button segment-editor__move-def-up-button"
                  icon="vertical_align_top"
                  onClick={() => handleMoveDefToTop(defIndex)}
                />
              )}

              {isEditing && (
                <Icon
                  className="segment-editor__icon-button segment-editor__duplicate-button"
                  icon="copy"
                  onClick={() => handleCopyDef(def)}
                />
              )}

              {isEditing && newWord.defs.length > 1 && (
                <Icon
                  className="segment-editor__icon-button segment-editor__delete-def-button"
                  icon="delete"
                  onClick={() => handleDeleteDef(defIndex)}
                />
              )}
            </div>
          );
        })}

        {isEditing && (
          <Button
            className="segment-editor__add-def-button"
            icon="add"
            label="Add Definition"
            onClick={() => handleClickAddDef()}
            outlined
          />
        )}
      </div>

      {/* dict defs */}

      {isEditing && dictDefs && (
        <ReferenceDefs
          defs={dictDefs}
          onCopy={handleCopyDef}
          pronOptions={pronOptions}
          wordText={segment.text}
        />
      )}

      {/* char defs */}

      {isEditing &&
        charDefsArray &&
        charDefsArray.map((charDefs, charIndex) => (
          <ReferenceDefs
            defs={charDefs}
            key={'charDefs-' + charIndex}
            onCopy={handleCopyDef}
            pronOptions={pronOptions}
            wordText={segment.text[charIndex]}
          />
        ))}
    </div>
  );
}

export default React.memo(SegmentEditor);
