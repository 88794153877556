import { getDbObject, setDbObject } from './utils';

// paths

export function allUserSubscriptionsDbPath(uid) {
  if (!uid) {
    throw new Error('Invalid args');
  }
  return `/subscriptions/${uid}`;
}

export function subscriptionDbPath(uid, channelKey) {
  if (!uid || !channelKey) {
    throw new Error('Invalid args');
  }
  return `/subscriptions/${uid}/${channelKey}`;
}

// get

export function getAllUserSubscriptions(uid) {
  return getDbObject(allUserSubscriptionsDbPath(uid));
}

export function getSubscription(uid, channelKey) {
  return getDbObject(subscriptionDbPath(uid, channelKey));
}

// set

export function subscribe(uid, channelKey) {
  return setDbObject(subscriptionDbPath(uid, channelKey), true);
}

export function unsubscribe(uid, channelKey) {
  return setDbObject(subscriptionDbPath(uid, channelKey), null);
}
