export default {
  apiKey: 'AIzaSyDBkYUzpJ_j8P0GzNyMMfrmdxm4aRPg20c',
  authDomain: 'lessonize.firebaseapp.com',
  databaseURL: 'https://lessonize.firebaseio.com',
  projectId: 'lessonize',
  storageBucket: 'lessonize.appspot.com',
  messagingSenderId: '706862731271',
  appId: '1:706862731271:web:59e4b4264225ec3e835aa2',
  measurementId: 'G-QZDKJ7NJTG',
};
