import React from 'react';

export function getRoleClassName(role) {
  let className = 'bubble-role';

  if (role) {
    const { align, color } = role;
    if (align === 'right') {
      className += ' bubble-role-right';
    }
    className += ` color-${color}`;
  }

  return className;
}

export function getBubbleClassName(role) {
  if (role) {
    const { align, color } = role;

    return `bubble-bubble bg-${color} ${align}`;
  }

  return 'bubble-bubble';
}

export function getNotesClassName(role) {
  if (role) {
    const { align } = role;

    return `bubble-notes bubble-notes--${align}`;
  }

  return 'bubble-notes';
}

function Role(props) {
  const { role } = props;
  const className = getRoleClassName(role);

  return (
    <React.Fragment>
      {(!role || !role.name) && <div className="role__spacer" />}

      <div className={className} contentEditable={false}>
        {role && role.name}
      </div>
    </React.Fragment>
  );
}

export default React.memo(Role);
