import produce from 'immer';
import React from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { IconButton, Tooltip } from 'rmwc';

import editModes from '../../constants/editModes';
import { wordsState, pronOptionsState, useBlocks } from '../../hooks/doc';
import {
  editBlockIndexState,
  editBlockState,
  editModeState,
} from '../../hooks/editor';
import createWord from '../../lib/createWord';
import { encodeWord } from '../../lib/core';
import { mergeAdjacentTextSegments } from '../../lib/editable';

export default function DefEditorTooltip(props) {
  const { children, open, range } = props;

  const editBlockIndex = useRecoilValue(editBlockIndexState);
  const editBlock = useRecoilValue(editBlockState);
  const [defs, setDefs] = useRecoilState(wordsState);
  const { setBlock } = useBlocks();

  // create selection state in terms of segments

  const segmentsBefore = [];
  const segmentsAfter = [];
  let segmentIndex = null;
  let text = props.text;

  if (editBlock && range) {
    const isCollapsed = range.start === range.end;
    let pos = 0;
    editBlock.segments.forEach((segment, index) => {
      const segmentStart = pos;
      const segmentEnd = pos + segment.text.length;

      if (segmentEnd <= range.start) {
        segmentsBefore.push(segment);
      } else if (segmentStart >= range.end) {
        segmentsAfter.push(segment);
      } else {
        if (segmentStart < range.start) {
          segmentsBefore.push({
            text: segment.text.substring(0, range.start - segmentStart),
          });
        }
        if (segmentEnd > range.end) {
          segmentsAfter.push({
            text: segment.text.substring(range.end - segmentStart),
          });
        }
      }

      if (isCollapsed) {
        if (
          segmentStart <= range.start &&
          range.start < segmentEnd &&
          editBlock.segments[index]
        ) {
          text = editBlock.segments[index].text;
          segmentIndex = index;
        }
      }

      pos = segmentEnd;
    });
  }

  // find def for text

  const def = defs && text && defs[encodeWord(text.toLowerCase())];

  // recoil state

  const editMode = useRecoilValue(editModeState);
  const pronOptions = useRecoilValue(pronOptionsState);

  // handlers

  function handleClickAdd(event) {
    // update doc defs
    if (!def) {
      setDefs(
        produce(defs, draftDefs => {
          draftDefs[encodeWord(text)] = createWord(pronOptions);
        })
      );
    }

    // update segments
    const newSegments = [{ text, hasDefs: true }];
    const combinedSegments = segmentsBefore.concat(newSegments, segmentsAfter);
    const mergedSegments = mergeAdjacentTextSegments(combinedSegments);
    setBlock(
      editBlockIndex,
      produce(editBlock, draftBlock => {
        draftBlock.segments = mergedSegments;
      })
    );

    // clear selection
    window.getSelection().removeAllRanges();

    event.preventDefault();
  }

  function handleClickDelete(event) {
    setBlock(
      editBlockIndex,
      produce(editBlock, draftBlock => {
        delete draftBlock.segments[segmentIndex].hasDefs;
      })
    );
    event.preventDefault();
  }

  // render

  // only show tooltip in segments mode
  if (editMode !== editModes.segments) {
    return children;
  }

  const popupContent = (
    <React.Fragment>
      {segmentIndex === null && (
        // edit new def and apply to range on save
        <IconButton icon="add" onMouseDown={handleClickAdd} />
      )}

      {segmentIndex !== null && (
        <IconButton icon="delete" onMouseDown={handleClickDelete} />
      )}
    </React.Fragment>
  );

  return (
    <Tooltip
      className="draft-link-tooltip--selection"
      content={popupContent}
      open={open}
      showArrow
    >
      {children}
    </Tooltip>
  );
}
