import capitalize from 'capitalize';
import getYoutubeId from 'get-youtube-id';
import unescape from 'unescape';
import { upperCase } from 'upper-case';
import X2JS from 'x2js';

const captionTracksUrlTemplate =
  'https://www.youtube.com/api/timedtext?type=list&v=VIDEO_ID';

const captionUrlTemplate =
  'https://www.youtube.com/api/timedtext?&v=VIDEO_ID&lang=LANGUAGE_CODE&name=TRACK_NAME';

const x2js = new X2JS();

function parseTrackListXml(trackListXml) {
  const unescapedResponseXml = unescape(trackListXml);
  const responseJson = x2js.xml2js(unescapedResponseXml);
  let tracks = responseJson.transcript_list.track;

  if (!tracks) {
    tracks = [];
  }

  if (!tracks.length) {
    tracks = [tracks]; // must be array
  }

  return tracks.map(track => ({
    id: track._id,
    name: track._name,
    languageCode: track._lang_code,
    languageOriginal: track._lang_original,
    languageTranslated: track._lang_translated,
  }));
}

export function getYoutubePosterUrl(url) {
  const videoId = getYoutubeId(url);
  return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
}

export function getYoutubeThumbnailUrl(url) {
  const videoId = getYoutubeId(url);
  return `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`;
}

export function fetchTrackList(url) {
  const videoId = getYoutubeId(url);

  if (!videoId) {
    return Promise.resolve(null);
  }

  const apiUrl = captionTracksUrlTemplate.replace('VIDEO_ID', videoId);

  return (
    fetch(apiUrl)
      .then(response => response.text())
      .then(parseTrackListXml)
      // filter out tracks missing id
      .then(tracks => tracks.filter(track => track.id))
  );
}

function parseTrackXml(trackXml) {
  const unescapedResponseXml = unescape(trackXml);
  const responseJson = x2js.xml2js(unescapedResponseXml);

  if (
    !responseJson ||
    !responseJson.transcript ||
    !responseJson.transcript.text ||
    !responseJson.transcript.text.map
  ) {
    return null;
  }

  let captions = [];
  responseJson.transcript.text.forEach(caption => {
    if (caption.__text && caption._start) {
      let text = caption.__text;

      // replace newlines with spaces
      text = text.replace('\n', ' ');

      // remove hyphens
      text = text.replace('- ', '');

      // trim leading and trailing space
      text = text.trim();

      // if all caps, instead capitalize the first letter
      if (text === upperCase(text)) {
        text = capitalize(text);
      }

      const start = parseFloat(caption._start);
      const duration = parseFloat(caption._dur);

      captions.push({
        text,
        startTime: Math.round(10 * start) / 10,
        endTime: Math.round(10 * (start + duration)) / 10,
      });
    }
  });

  // filter out captions missing time or text
  captions = captions.filter(
    c =>
      typeof c.startTime === 'number' &&
      typeof c.endTime === 'number' &&
      c.text.length > 0
  );

  // sort by time
  captions.sort((a, b) => (a.start < b.start ? -1 : 1));

  return captions;
}

export function fetchTrack(url, track) {
  const { languageCode, name } = track;
  const videoId = getYoutubeId(url);
  const apiUrl = captionUrlTemplate
    .replace('VIDEO_ID', videoId)
    .replace('LANGUAGE_CODE', languageCode)
    .replace('TRACK_NAME', encodeURIComponent(name));

  return fetch(apiUrl)
    .then(response => response.text())
    .then(parseTrackXml);
}
