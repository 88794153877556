import produce from 'immer';
import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import LanguageSelect from './LanguageSelect';
import LevelSelect from './LevelSelect';
import sourceLanguageCodes from '../constants/sourceLanguageCodes';
import targetLanguageCodes from '../constants/targetLanguageCodes';
import { linksState, metadataState } from '../hooks/doc';
import { isEditingState } from '../hooks/editor';

function Link(props) {
  const { link } = props;

  const history = useHistory();

  // href is used for external links
  const href = link.url.startsWith('http') ? link.url : null;

  // onClick is used for docKeys
  const onClick = href
    ? null
    : () => {
        history.push('/l/' + link.url);
      };

  // render

  return (
    <React.Fragment>
      {' · '}
      <a
        className="doc-info__link"
        href={href}
        onClick={onClick}
        rel="noopener noreferrer"
        target="_blank"
      >
        {link.text}
      </a>
    </React.Fragment>
  );
}

function DocInfo(props) {
  // recoil state

  const isEditing = useRecoilValue(isEditingState);
  const [metadata, setMetadata] = useRecoilState(metadataState);
  const sourceLanguage = metadata && metadata.sourceLanguage;
  const targetLanguage = metadata && metadata.targetLanguage;
  const level = metadata && metadata.level;

  const links = useRecoilValue(linksState);

  const { t } = useTranslation();

  // handlers

  function handleSelectSourceLanguage(newSourceLanguage) {
    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata.sourceLanguage = newSourceLanguage;
      })
    );
  }

  function handleSelectLevel(newLevel) {
    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata.level = newLevel;
      })
    );
  }

  function handleSelectTargetLanguage(newTargetLanguage) {
    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata.targetLanguage = newTargetLanguage;
      })
    );
  }

  // render

  return (
    <React.Fragment>
      {metadata && !isEditing && (
        <div className="doc-info">
          <span className="doc-info__text">
            {t(sourceLanguage)}

            {level && ` · ${level}`}

            {links &&
              links.map((link, index) => <Link key={index} link={link} />)}
          </span>
        </div>
      )}

      {metadata && isEditing && (
        <div className="doc-info">
          <LanguageSelect
            idPrefix="source"
            language={sourceLanguage}
            languageCodes={sourceLanguageCodes}
            setLanguage={handleSelectSourceLanguage}
          >
            <span className="doc-info__language" id="doc-info__source-language">
              {sourceLanguage ? t(sourceLanguage) : t('selectSourceLanguage')}
            </span>
          </LanguageSelect>
          {' · '}
          <LevelSelect
            level={level}
            setLevel={handleSelectLevel}
            sourceLanguage={sourceLanguage}
          >
            <span className="doc-info__language" id="doc-info__level">
              {level || t('selectLevel')}
            </span>
          </LevelSelect>
          {' · '}
          <LanguageSelect
            idPrefix="translation"
            language={targetLanguage}
            languageCodes={targetLanguageCodes}
            setLanguage={handleSelectTargetLanguage}
          >
            <span className="doc-info__language" id="doc-info__target-language">
              {!targetLanguage && t('selectTranslationLanguage')}
              {targetLanguage && t(targetLanguage)}
            </span>
          </LanguageSelect>
          {/* {' · '}
          <LinkEditor /> */}
        </div>
      )}
    </React.Fragment>
  );
}

export default React.memo(DocInfo);
