import React, { useEffect, useRef } from 'react';
import { useRecoilValue } from 'recoil';

import Segment from './Segment';
import {
  wordsState,
  metadataState,
  scriptsState,
  vocabState,
} from '../hooks/doc';
import { blockIsInSourceLanguageState } from '../hooks/player';
import { encodeWord, isSpaceDelimited } from '../lib/core';
import {
  highlightSettingState,
  pronSettingState,
  scriptSettingState,
  showAllPronsState,
} from '../state/settings';
import editModes from '../constants/editModes';

// allow segment click handling in editor

let handleClickBlank;

export function onClickBlank(callback) {
  handleClickBlank = callback;
}

// determine whether segment should show playing highlight

function calcHasPlayingHighlight(segments, index, playingSegmentIndex) {
  // playingSegmentIndex is set when the media current time is between
  // a segment's startTime and the next segment's startTime within a block

  // if playingSegmentIndex isn't set
  if (typeof playingSegmentIndex !== 'number') {
    // this segment should not be highlighted
    return false;
  }

  // if index is playingSegmentIndex
  if (index === playingSegmentIndex) {
    // this segment should be highlighted
    return true;
  }

  // if index is after playingSegmentIndex
  if (index > playingSegmentIndex) {
    // interate over segments after playing segment up to current segment
    for (let i = playingSegmentIndex + 1; i <= index; i += 1) {
      // if there is a segment has a startTime
      if (typeof segments[i].startTime === 'number') {
        // this segment should not be highlighted
        return false;
      }
    }

    // if no segment in between has a startTime,
    // this segment should be highlighted
    return true;
  }

  // if segment is before
  return false;
}

// the component

function BlockText(props) {
  const {
    blockIndex,
    editMode,
    highlightedSegmentIndex,
    isEditingExercises,
    // isPlaying,
    isSyncing,
    playingSegmentIndex,
    role,
    segments,
  } = props;

  // recoil state

  const blockIsInSourceLanguage = useRecoilValue(blockIsInSourceLanguageState);
  const words = useRecoilValue(wordsState);
  const metadata = useRecoilValue(metadataState);
  const scripts = useRecoilValue(scriptsState);
  const selectedHighlight = useRecoilValue(highlightSettingState);
  const selectedPron = useRecoilValue(pronSettingState);
  const selectedScript = useRecoilValue(scriptSettingState);
  const showAllProns = useRecoilValue(showAllPronsState);
  const vocab = useRecoilValue(vocabState);

  // local state

  const editableRef = useRef();

  // derived state

  const isAnnotating = editMode === editModes.annotations;
  const scriptTextMap = scripts[selectedScript];

  // autofocus while editing annotations

  useEffect(() => {
    if (isAnnotating && editableRef.current) {
      editableRef.current.focus();
    }
  }, [isAnnotating, editableRef]);

  // render

  let textOffset = 0; // for calculating selection offsets

  return (
    <React.Fragment>
      {segments &&
        segments.map((segment, index) => {
          const word = segment.hasDefs && words[encodeWord(segment.text)];
          // editing highlight
          const isHighlighted = index === highlightedSegmentIndex;
          // playing highlight
          const hasPlayingHighlight = calcHasPlayingHighlight(
            segments,
            index,
            playingSegmentIndex
          );
          const renderedSegment = (
            <Segment
              blockIndex={blockIndex}
              editMode={editMode}
              word={word}
              displayText={
                (scriptTextMap && scriptTextMap[encodeWord(segment.text)]) ||
                segment.text
              }
              hasPlayingHighlight={hasPlayingHighlight}
              isHighlighted={isHighlighted}
              isPlaying={index === playingSegmentIndex}
              isSpaceDelimited={isSpaceDelimited(
                metadata && metadata.sourceLanguage
              )}
              isSyncing={isSyncing}
              isVocab={Boolean(vocab[encodeWord(segment.text)])}
              key={index}
              role={role}
              segment={segment}
              segmentIndex={index}
              selectedHighlight={selectedHighlight}
              selectedPron={
                showAllProns &&
                blockIsInSourceLanguage[blockIndex] &&
                selectedPron
              }
              textOffset={textOffset}
            />
          );

          textOffset += segment.text.length;

          if (isEditingExercises && segment.isBlank) {
            // if editing blanks, wrap with blank as needed
            return (
              <div
                className="exercise__choice exercise__choice--word exercise__choice--blank"
                key={index}
                onClick={() => {
                  if (handleClickBlank) {
                    handleClickBlank(blockIndex, index, segment);
                  }
                }}
              >
                {renderedSegment}
              </div>
            );
          }

          return renderedSegment;
        })}
    </React.Fragment>
  );
}

export default React.memo(BlockText);
