export default {
  zh: {
    defaults: {
      highlight: null,
      pron: 'pinyin',
      script: 'simplified',
    },
    highlights: ['hsk', 'hsk-chars', 'tocfl', 'tocfl-chars', 'rank-chars'],
    prons: ['pinyin', 'zhuyin'],
    scripts: ['simplified', 'traditional'],
  },
};
