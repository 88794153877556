import { Howl } from 'howler';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { blocksState, metadataState } from './doc';
import { wordAudioIsLoadedState } from './player';
import audioSpriteKey from '../lib/audioSpriteKey';
import { getAudioUrl } from '../lib/core';

export default function useWordAudioSprites() {
  const metadata = useRecoilValue(metadataState);
  const blocks = useRecoilValue(blocksState);
  const setWordAudioIsLoaded = useSetRecoilState(wordAudioIsLoadedState);

  const [sprite, setSprite] = useState(null);

  const { wordAudioKey } = metadata || {};

  useEffect(() => {
    (async () => {
      setSprite(null);
      if (wordAudioKey && blocks) {
        setWordAudioIsLoaded(false);
        const wordAudioUrl = await getAudioUrl(wordAudioKey);

        // build audio sprite
        const sprite = {};
        blocks.forEach(block => {
          if (block.segments) {
            block.segments.forEach((segment, segmentIndex) => {
              if (typeof segment.wordStart === 'number') {
                sprite[audioSpriteKey(segment)] = [
                  segment.wordStart * 1000,
                  (segment.wordEnd - segment.wordStart) * 1000,
                ];
              }
            });
          }
        });

        // create howl
        setSprite(
          new Howl({
            format: 'mp3',
            onload: () => setWordAudioIsLoaded(true),
            preload: true,
            src: [wordAudioUrl],
            sprite,
          })
        );
      }
    })();
  }, [blocks, setWordAudioIsLoaded, wordAudioKey]);

  return sprite;
}
