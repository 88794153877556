import React from 'react';
import { /*useRecoilState,*/ useRecoilState, useRecoilValue } from 'recoil';
import { Icon, IconButton } from 'rmwc';

import {
  canGoState,
  hasTranslationsState,
  playingSegmentState,
  useMediaInput,
  // isLineByLineState,
  mediaOutputState,
  mustTapVideoState,
} from '../hooks/player';
import { getGlobalMediaPlayer, globalPlay } from '../lib/globalVideoPlayer';
import {
  isShowingSettingsDialogState,
  isShowingTranslationState,
  showAllTranslationsState,
} from '../state/settings';

export default function BottomBarWatch() {
  // recoil state

  const { play, pause, playBlock } = useMediaInput();
  const { isPlaying } = useRecoilValue(mediaOutputState);
  // const [isLineByLine, setIsLineByLine] = useRecoilState(isLineByLineState);
  const {
    blockIndex: playingBlockIndex,
    segmentIndex: playingSegmentIndex,
  } = useRecoilValue(playingSegmentState);
  const mustTapVideo = useRecoilValue(mustTapVideoState);
  const { canGoForward } = useRecoilValue(canGoState);
  const hasTranslations = useRecoilValue(hasTranslationsState);
  const showAllTranslations = useRecoilValue(showAllTranslationsState);
  const [isShowingTranslation, setIsShowingTranslation] = useRecoilState(
    isShowingTranslationState
  );

  const [isShowingSettingsDialog, setIsShowingSettingsDialog] = useRecoilState(
    isShowingSettingsDialogState
  );

  // handlers

  // function handleClickLineByLine() {
  //   pause();
  //   setIsLineByLine(!isLineByLine);
  // }

  function handleClickTogglePlay() {
    if (isPlaying) {
      pause();
    } else {
      // initial play must happen in direct response to a
      // user input on Safari
      if (getGlobalMediaPlayer()) {
        globalPlay();
      }
      // set recoil state to playing
      play();
    }
  }

  function handleClickBack() {
    if (playingSegmentIndex <= 1) {
      // if currently playing one of the first two segments of the block
      // then play the previous block
      playBlock(Math.max(0, playingBlockIndex - 1));
    } else {
      // if past the first two segments, replay the current block
      playBlock(playingBlockIndex);
    }
  }

  function handleClickForward() {
    playBlock(playingBlockIndex + 1);
  }

  function handleClickStopReplay() {
    if (isPlaying) {
      pause();
    } else {
      playBlock(playingBlockIndex);
    }
  }

  function handleClickSettings() {
    setIsShowingSettingsDialog(!isShowingSettingsDialog);
  }

  function handleClickTranslate() {
    setIsShowingTranslation(!isShowingTranslation);
  }

  // render

  return (
    <React.Fragment>
      <div className="bottom-bar-watch">
        <IconButton
          className={
            isShowingSettingsDialog
              ? 'bottom-bar-watch__icon-button--on'
              : 'bottom-bar-watch__icon-button'
          }
          icon="settings"
          onClick={handleClickSettings}
        />

        {mustTapVideo && (
          <div className="bottom-bar-watch__playback-controls">
            Tap video to start
          </div>
        )}

        {!mustTapVideo && (
          <div className="bottom-bar-watch__playback-controls">
            <Icon
              className="bottom-bar-watch__icon"
              icon={{ icon: 'skip_previous', size: 'large' }}
              onClick={handleClickBack}
            />

            {
              /*!isLineByLine*/ true && (
                <Icon
                  className="bottom-bar-watch__play-button"
                  icon={{
                    icon: isPlaying ? 'pause' : 'play_arrow',
                    size: 'xlarge',
                  }}
                  onClick={handleClickTogglePlay}
                />
              )
            }

            {
              /*isLineByLine*/ false && (
                <Icon
                  className="bottom-bar-watch__play-button"
                  icon={{ icon: isPlaying ? 'stop' : 'replay', size: 'xlarge' }}
                  onClick={handleClickStopReplay}
                />
              )
            }

            <Icon
              className={
                'bottom-bar-watch__icon ' +
                (!canGoForward && 'bottom-bar-watch__icon--disabled')
              }
              icon={{ icon: 'skip_next', size: 'large' }}
              onClick={canGoForward ? handleClickForward : null}
            />
          </div>
        )}

        {(!hasTranslations || showAllTranslations) && (
          <IconButton className="spacer" icon="translate" />
        )}

        {hasTranslations && !showAllTranslations && (
          <IconButton
            className={
              isShowingTranslation
                ? 'bottom-bar-watch__icon-button--on'
                : 'bottom-bar-watch__icon-button'
            }
            icon="translate"
            onClick={handleClickTranslate}
          />
        )}
      </div>
    </React.Fragment>
  );
}
