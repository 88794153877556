import React from 'react';

export default function Link(props) {
  const {
    id,
    thumbnailUrl,
    primaryText,
    secondaryText,
    selected,
    onClick,
  } = props;

  return (
    <div
      className={selected ? 'link--selected' : 'link'}
      id={`link-${id}`}
      onClick={onClick}
    >
      <div
        className="link__thumbnail"
        style={{ backgroundImage: `url("${thumbnailUrl}")` }}
      />

      <div className="link__text">
        <div className="link__primary_text">{primaryText}</div>

        {secondaryText && (
          <div className="link__secondary_text">{secondaryText}</div>
        )}
      </div>
    </div>
  );
}

export function getTitle(metadata) {
  const title = metadata.title;
  const keywords = metadata.keywords;

  if (title) {
    return title;
  }

  if (keywords) {
    return keywords.join();
  }

  return 'Untitled';
}
