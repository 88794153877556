import produce from 'immer';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LinearProgress } from 'rmwc';

import AutoScroller from './AutoScroller';
import Block from './Block';
import BottomBar from './BottomBar';
import BottomBarWatch from './BottomBarWatch';
import ChannelHeader from './ChannelHeader';
import DefEditorSelection from './def/DefEditorSelection';
import DocInfo from './DocInfo';
import DraftSidebar from './DraftSidebar';
import Editable from './Editable';
import ExercisesSection from './exercises/ExercisesSection';
import StickyContainer from './StickyContainer';
import MediaEditor from './MediaEditor';
import VocabSection from './VocabSection';
import blockTypes from '../constants/blockTypes';
import editModes from '../constants/editModes';
import {
  docKeyState,
  docSelectorState,
  metadataState,
  useBlocks,
  useRoles,
} from '../hooks/doc';
import {
  editModeState,
  editBlockIndexState,
  highlightedSegmentIndexState,
} from '../hooks/editor';
import {
  playingSegmentState,
  useMediaInput,
  mediaOutputState,
} from '../hooks/player';
import usePlayOnKeyDown from '../hooks/usePlayOnKeyDown';
import useSaveDraft from '../hooks/useSaveDraft';
import { fetchDraft } from '../lib/core';
import createBlock from '../lib/createBlock';
import createDraft from '../lib/createDraft';
import createSlug from '../lib/createSlug';
import getPageTitle from '../lib/getPageTitle';
import {
  isCursorAtBlockStart,
  mergeBlock,
  splitBlockOnSelection,
} from '../lib/selection';
import sentenceSplit from '../lib/sentenceSplit';
import { channelKeyState } from '../state/channel';
import { keyVocabularyState } from '../state/vocab';

import './Draft.css';
import SyncAudioContainer from './SyncAudioContainer';

export default function Draft(props) {
  // recoil state

  const docKey = useRecoilValue(docKeyState);

  const channelKey = useRecoilValue(channelKeyState);

  const [metadata, setMetadata] = useRecoilState(metadataState);
  const { blocks, setBlocks, setBlock } = useBlocks();
  const { roles } = useRoles();
  const setDoc = useSetRecoilState(docSelectorState);
  const editMode = useRecoilValue(editModeState);
  const highlightedSegmentIndex = useRecoilValue(highlightedSegmentIndexState);
  const [editBlockIndex, setEditBlockIndex] = useRecoilState(
    editBlockIndexState
  );
  const { playBlock, pause } = useMediaInput();
  const { isPlaying } = useRecoilValue(mediaOutputState);
  const {
    blockIndex: playingBlockIndex,
    segmentIndex: playingSegmentIndex,
  } = useRecoilValue(playingSegmentState);
  const keyVocabulary = useRecoilValue(keyVocabularyState);

  // local state

  const [isLoading, setIsLoading] = useState(false);
  const [initialTitle, setInitialTitle] = useState('');

  // derived state

  const canSelect =
    editMode === editModes.segments ||
    editMode === editModes.audio ||
    editMode === editModes.translations;

  // hooks

  useSaveDraft(); // auto save on change
  usePlayOnKeyDown(); // play/pause on tab key

  // handle block selection by clicking

  const handleSelectBlock = useCallback(
    index => {
      setEditBlockIndex(index);
    },
    [setEditBlockIndex]
  );

  const handleChangeTitle = useCallback(
    newTitle => {
      setMetadata(metadata =>
        produce(metadata, draftMetadata => {
          draftMetadata.slug = createSlug(newTitle);
          draftMetadata.title = newTitle;
        })
      );

      // update page title
      document.title = getPageTitle(newTitle);
    },
    [setMetadata]
  );

  // handle key events

  useEffect(() => {
    function handleKeyDown(event) {
      switch (event.key) {
        case 'Enter':
          // handle link

          if (blocks[editBlockIndex].type === blockTypes.link) {
            // insert blank paragraph after link
            setBlocks(blocks =>
              produce(blocks, draftBlocks => {
                // splice new block to index after original block
                draftBlocks.splice(editBlockIndex + 1, 0, createBlock());
              })
            );
          }

          // handle non-link
          else {
            // split block
            setBlocks(blocks => splitBlockOnSelection(blocks, editBlockIndex));
          }

          // move cursor to newly created block
          setTimeout(() => setEditBlockIndex(index => index + 1), 10);

          event.preventDefault();
          break;

        case 'Backspace':
          // handle link

          if (blocks[editBlockIndex].type === blockTypes.link) {
            // remove block unless it's the only block
            if (blocks.length > 1) {
              setBlocks(blocks =>
                produce(blocks, draftBlocks => {
                  draftBlocks.splice(editBlockIndex, 1);
                })
              );
            }
            break;
          }

          // handle non-link

          if (isCursorAtBlockStart() && editBlockIndex > 0) {
            // merge block with previous
            setBlocks(blocks => mergeBlock(blocks, editBlockIndex));

            event.preventDefault();
          }

          break;

        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [blocks, editBlockIndex, setBlocks, setEditBlockIndex]);

  // fetch draft when docKey changes

  useEffect(() => {
    // clear doc and page title
    setDoc(null);
    document.title = getPageTitle();

    if (channelKey && docKey) {
      setEditBlockIndex(null);
      setIsLoading(true);

      // fetch draft
      fetchDraft(channelKey, docKey).then(draft => {
        if (draft) {
          setInitialTitle(draft.metadata.title);
          setDoc(draft);
        } else {
          const newDraft = createDraft(channelKey, docKey);
          setInitialTitle(newDraft.metadata.title);
          setDoc(newDraft);
        }

        setEditBlockIndex(0);
        setIsLoading(false);

        // update title
        document.title = getPageTitle(draft && draft.metadata.title);
      });
    }
  }, [channelKey, docKey, setDoc, setEditBlockIndex]);

  // update index if last block was deleted

  if (blocks && editBlockIndex > blocks.length - 1) {
    setEditBlockIndex(blocks.length - 1);
  }

  // strip styles from pasted content for text editing

  useEffect(() => {
    function handlePaste(e) {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      document.execCommand('insertHTML', false, text);
    }

    window.addEventListener('paste', handlePaste);

    return () => {
      window.removeEventListener('paste', handlePaste);
    };
  }, []);

  // split block when newlines are found in segments

  function splitBlock(index, newSegments) {
    const lines = sentenceSplit(metadata.sourceLanguage, newSegments[0].text);

    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        // set selected block text to first line
        draftBlocks[index].segments = [{ text: lines[0] }];

        // insert new blocks for additional lines
        const newBlocks = [];
        for (let i = 1; i < lines.length; i += 1) {
          const line = lines[i];

          // create copy of selected block
          const newBlock = createBlock(draftBlocks[index].type);

          // set segments to line
          newBlock.segments = [{ text: line }];

          // push to list for later insertion
          newBlocks.push(newBlock);
        }

        draftBlocks.splice(index + 1, 0, ...newBlocks);
      })
    );

    // update text in Block's Editable input
    // TODO: find a cleaner way
    setEditBlockIndex(null);
    setTimeout(() => setEditBlockIndex(index), 100);
  }

  // render

  return (
    <div className="draft">
      <div className="draft__doc-container">
        {isLoading && <LinearProgress />}

        {metadata && (
          <div className="doc">
            <StickyContainer>
              <MediaEditor />
            </StickyContainer>

            <div id="doc__title">
              <Editable
                initialText={initialTitle}
                onChange={handleChangeTitle}
                placeholder="Title"
              />
            </div>

            <DocInfo />

            <ChannelHeader showDetails />

            {blocks &&
              blocks.length > 0 &&
              blocks[0].type !== blockTypes.heading && (
                <div className="heading-outer">
                  <div className="heading-inner">Captions</div>
                </div>
              )}

            {blocks &&
              blocks.map((block, index) => {
                const isEditing = index === editBlockIndex;
                return (
                  <Block
                    key={block.key}
                    block={block}
                    index={index}
                    canSelect={canSelect}
                    editMode={editMode}
                    highlightedSegmentIndex={
                      isEditing && highlightedSegmentIndex
                    }
                    isEditing={isEditing}
                    isPlaying={
                      index === playingBlockIndex &&
                      isPlaying &&
                      !editMode === editModes.sync
                    }
                    playingSegmentIndex={
                      index === playingBlockIndex &&
                      !editMode === editModes.sync &&
                      playingSegmentIndex
                    }
                    roles={roles}
                    onSelect={!isEditing && handleSelectBlock}
                    setBlock={setBlock}
                    splitBlock={splitBlock}
                    playBlock={playBlock}
                    pause={pause}
                  />
                );
              })}

            {keyVocabulary.length > 0 && <VocabSection />}

            <ExercisesSection />
          </div>
        )}

        <BottomBar>
          <BottomBarWatch />
        </BottomBar>
      </div>

      <DraftSidebar />

      <AutoScroller />

      <DefEditorSelection />

      <SyncAudioContainer />
    </div>
  );
}
