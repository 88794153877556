import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {
  editBlockIndexState,
  highlightedSegmentIndexState,
} from '../hooks/editor';
import { useMediaInput } from '../hooks/player';
import { blocksState } from './doc';

export default function useSelectSegmentOnKeyPress(handleSpaceKey) {
  // recoil state

  const blocks = useRecoilValue(blocksState);
  const [editBlockIndex, setEditBlockIndex] = useRecoilState(
    editBlockIndexState
  );
  const [highlightedSegmentIndex, setHighlightedSegmentIndex] = useRecoilState(
    highlightedSegmentIndexState
  );

  // derived state

  const block = blocks && blocks[editBlockIndex];
  const segments = block && block.segments;

  // connect to media

  const { playClip } = useMediaInput();

  // handle left/right arrow key events

  useEffect(() => {
    function handleKeyDown(event) {
      switch (event.key) {
        // on left or a, select previous segment
        case 'ArrowLeft':
        case 'a':
          if (highlightedSegmentIndex > 0) {
            // if after first segment, highlight previous segment
            setHighlightedSegmentIndex(highlightedSegmentIndex - 1);
          } else if (editBlockIndex > 0) {
            // otherwise if after first block,
            // highlight last segment of previous block
            const prevBlock = blocks[editBlockIndex - 1];
            setHighlightedSegmentIndex(prevBlock.segments.length - 1);
            setEditBlockIndex(editBlockIndex - 1);
          }
          event.preventDefault();
          break;

        // on right or d, select next segment
        case 'ArrowRight':
        case 'd':
          if (highlightedSegmentIndex < segments.length - 1) {
            // if before last segment, highlight next segment
            setHighlightedSegmentIndex(highlightedSegmentIndex + 1);
          } else if (editBlockIndex < blocks.length - 1) {
            // otherwise if before last block,
            // highlight first segment of next block
            setHighlightedSegmentIndex(0);
            setEditBlockIndex(editBlockIndex + 1);
          }
          event.preventDefault();
          break;

        // on up or w, select first segment of previous block
        case 'ArrowUp':
        case 'w':
          if (editBlockIndex > 0) {
            setHighlightedSegmentIndex(0);
            setEditBlockIndex(editBlockIndex - 1);
          }
          event.preventDefault();
          break;

        // on down or s, select first segment of next block
        case 'ArrowDown':
        case 's':
          if (editBlockIndex < blocks.length - 1) {
            setHighlightedSegmentIndex(0);
            setEditBlockIndex(editBlockIndex + 1);
          }
          event.preventDefault();
          break;

        // on spacebar, run handler if provided
        case 'Spacebar':
        case ' ':
          if (handleSpaceKey) {
            handleSpaceKey();
            event.preventDefault();
          }
          break;

        default:
          break;
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [
    blocks,
    editBlockIndex,
    handleSpaceKey,
    highlightedSegmentIndex,
    playClip,
    segments,
    setEditBlockIndex,
    setHighlightedSegmentIndex,
  ]);
}
