import firebase from 'firebase/app';
import isTouchDevice from 'is-touch-device';
import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Tooltip } from 'rmwc';

import editModes from '../../constants/editModes';
import levelLabels from '../../constants/levelLabels';
import {
  blocksState,
  docKeyState,
  metadataState,
  wordsState,
} from '../../hooks/doc';
import { editModeState, isEditingState } from '../../hooks/editor';
import useWordAudioSprites from '../../hooks/useWordAudioSprites';
import {
  blockIsInSourceLanguageState,
  maxTime,
  mediaOutputState,
  useMediaInput,
} from '../../hooks/player';
import audioSpriteKey from '../../lib/audioSpriteKey';
import {
  encodeWord,
  getDefForKey,
  getPronForKey,
  trackWordLookup,
} from '../../lib/core';
import getHighlighting from '../../lib/getHighlighting';
import { currentUserState } from '../../state/auth';
import {
  highlightSettingState,
  pronSettingState,
  showAllPronsState,
} from '../../state/settings';

import './DefTooltip.css';

function offset(el) {
  const rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

const showDelayMs = 300;

// allow segment click handling in editor

let handleClickSegment;

export function onClickSegment(callback) {
  handleClickSegment = callback;
}

// determines which time to seek to when a segment is clicked

function calcSeekTime(segments, index) {
  const segment = segments[index];

  // if segment has startTime, return it
  if (typeof segment.startTime === 'number') {
    return segment.startTime;
  }

  // iterate from index to zero
  for (let i = index - 1; i >= 0; i--) {
    const currentSegment = segments[i];
    // if currentSegment has startTime, return it
    if (typeof currentSegment.startTime === 'number') {
      return currentSegment.startTime;
    }
  }

  // if no segment with startTime was found, return null
  return null;
}

// Def component

function Def({
  blockIsInSourceLanguage,
  def,
  blockIndex,
  pron,
  selectedHighlight,
  selectedPron,
  showAllProns,
  uniqueCharLevels,
  wordLevel,
}) {
  return (
    <div className="def-tooltip__def">
      {(!showAllProns || !blockIsInSourceLanguage[blockIndex]) && pron && (
        <div className="def-tooltip__pron">{pron[selectedPron]}</div>
      )}

      <div className="def-tooltip__meaning">{def.meaning}</div>
    </div>
  );
}

// DefTooltip component

export default function DefTooltip() {
  // recoil state

  // for tracking lookups
  const currentUser = useRecoilValue(currentUserState);
  const docKey = useRecoilValue(docKeyState);
  const metadata = useRecoilValue(metadataState);

  const isEditing = useRecoilValue(isEditingState);
  const editMode = useRecoilValue(editModeState);
  const words = useRecoilValue(wordsState);
  const { pause, pauseClip } = useMediaInput();
  const { isPlaying } = useRecoilValue(mediaOutputState);
  const blocks = useRecoilValue(blocksState);
  const blockIsInSourceLanguage = useRecoilValue(blockIsInSourceLanguageState);
  const selectedHighlight = useRecoilValue(highlightSettingState);
  const selectedPron = useRecoilValue(pronSettingState);
  const showAllProns = useRecoilValue(showAllPronsState);
  const wordAudioSprite = useWordAudioSprites();

  // local state

  const [content, setContent] = useState(null);
  const [bounds, setBounds] = useState(null);

  // show tooltip after timeout to reduce unwanted popups
  const showTimeoutRef = useRef(null);
  const showTargetRef = useRef(null);

  // track tooltip open duration
  const openTimeRef = useRef(null);

  // side effect: subscribe to mouseover

  useEffect(() => {
    function getLinkEl(event) {
      const { target } = event;

      let linkEl = target.tagName === 'A' ? target : target.parentElement;

      return linkEl && linkEl.tagName === 'A' ? linkEl : null;
    }

    function getDef(linkEl) {
      const word = linkEl.dataset.text;
      return (word && words && words[encodeWord(word)]) || null;
    }

    // show popup

    function showPopup(linkEl, word) {
      const { blockIndex, pronKey, defKey } = linkEl.dataset;

      // set defs to all defs for word, or just one def if defKey is defined

      let defs = word.defs;
      if (defKey) {
        // if defKey is set
        const def = getDefForKey(word, defKey);
        if (def) {
          // if def exists, set defs to just that def
          defs = [def];
        }
      }

      // word/char highlighting

      const { charLevels, wordLevel } = getHighlighting(
        selectedHighlight,
        word
      );

      const uniqueCharLevels = charLevels && [...new Set(charLevels)];

      // update tooltip content

      const renderedDefs = defs.map(def => {
        const pron = getPronForKey(def, pronKey);

        return (
          <Def
            blockIsInSourceLanguage={blockIsInSourceLanguage}
            def={def}
            blockIndex={blockIndex}
            key={def.key}
            pron={pron}
            selectedHighlight={selectedHighlight}
            selectedPron={selectedPron}
            showAllProns={showAllProns}
            uniqueCharLevels={uniqueCharLevels}
            wordLevel={wordLevel}
          />
        );
      });

      const hasLevel =
        typeof wordLevel === 'number' ||
        (uniqueCharLevels &&
          uniqueCharLevels.length > 0 &&
          typeof uniqueCharLevels[0] === 'number');

      setContent(
        <div className="def-tooltip__content">
          {renderedDefs}

          {hasLevel && (
            <div className="def-tooltip__level-container">
              {typeof wordLevel === 'number' && (
                <div
                  className={`def-tooltip__level word__container--level-${wordLevel}`}
                >
                  {levelLabels[selectedHighlight][wordLevel]}
                </div>
              )}

              {uniqueCharLevels &&
                uniqueCharLevels.map((charLevel, index) => (
                  <div
                    className={`def-tooltip__level word__container--level-${charLevel}`}
                    key={index}
                  >
                    {levelLabels[selectedHighlight][charLevel]}
                  </div>
                ))}
            </div>
          )}
        </div>
      );

      // update tooltip bounds

      setBounds({
        ...offset(linkEl),
        width: linkEl.offsetWidth,
        height: linkEl.offsetHeight,
      });

      // track open time

      openTimeRef.current = new Date();
    }

    // mouse over

    function handleMouseOver(event) {
      const linkEl = getLinkEl(event);

      if (linkEl) {
        const def = getDef(linkEl);

        if (def) {
          // clear show timeout if needed
          if (showTimeoutRef.current) {
            clearTimeout(showTimeoutRef.current);
          }

          // set show timeout
          showTimeoutRef.current = setTimeout(() => {
            showPopup(linkEl, def);
          }, showDelayMs);

          // save target so we can identify it on mouseOut
          showTargetRef.current = linkEl;
        }
      }
    }

    // mouse out

    function handleMouseOut(event) {
      const linkEl = getLinkEl(event);

      // clear show timeout if same target
      if (linkEl === showTargetRef.current) {
        clearTimeout(showTimeoutRef.current);
        showTimeoutRef.current = null;
      }

      // clear animation
      if (linkEl) {
        linkEl.style.animation = null;
        linkEl.style.backgroundImage = null;
      }

      // hide tooltip
      setContent(null);

      // track lookup not editing
      if (blocks && !isEditing && linkEl && openTimeRef.current) {
        const { defKey, pronKey, text } = linkEl.dataset;
        const word = getDef(linkEl);
        const def = getDefForKey(word, defKey);
        const blockIndex = parseInt(linkEl.dataset.blockIndex);
        const blockKey = blocks[blockIndex].key;
        const duration =
          new Date().getTime() - new Date(openTimeRef.current).getTime();

        // track user progress in db
        if (currentUser) {
          trackWordLookup(
            currentUser.uid,
            metadata.sourceLanguage,
            metadata.targetLanguage,
            text,
            docKey,
            metadata.versionKey,
            blockKey,
            def.key,
            pronKey,
            duration
          );
        }

        // track in firebase analytics
        firebase.analytics().logEvent('lookup_word', {
          docKey,
          word: text,
          duration,
        });

        // reset open time
        openTimeRef.current = null;
      }

      // track in firebase analytics
      firebase.analytics().logEvent('lookup_word', {
        docKey,
      });
    }

    function handleClick(event) {
      const linkEl = getLinkEl(event);

      if (linkEl) {
        const def = getDef(linkEl);

        if (def) {
          // show popup
          showPopup(linkEl, def);

          // get block and segment
          const blockIndex = parseInt(linkEl.dataset.blockIndex);
          const block = blocks[blockIndex];
          const segmentIndex = parseInt(linkEl.dataset.segmentIndex);
          const segments = block && blocks[blockIndex].segments;
          const segment = segments && segments[segmentIndex];

          // pause media if not editing
          if (!isEditing && segments) {
            const seekTime = calcSeekTime(segments, segmentIndex);
            if (typeof seekTime === 'number') {
              pauseClip(seekTime, maxTime);
            }
          }

          // play word audio
          if (
            wordAudioSprite &&
            segment &&
            typeof segment.wordStart === 'number' &&
            (!isEditing || editMode !== editModes.sync)
          ) {
            wordAudioSprite.play(audioSpriteKey(segment));
          }

          // allow other components to handle click
          if (segment && handleClickSegment) {
            handleClickSegment(blockIndex, segmentIndex, segment, def);
          }

          event.preventDefault();
        }
      }
    }

    if (editMode !== editModes.segments) {
      if (!isTouchDevice()) {
        window.addEventListener('mouseover', handleMouseOver);
      }
      window.addEventListener('mouseout', handleMouseOut);
      window.addEventListener('click', handleClick);
    }

    // remove listeners on cleanup
    return () => {
      window.removeEventListener('mouseover', handleMouseOver);
      window.removeEventListener('mouseout', handleMouseOut);
      window.removeEventListener('click', handleClick);
    };
  }, [
    blockIsInSourceLanguage,
    blocks,
    currentUser,
    docKey,
    editMode,
    isPlaying,
    isEditing,
    metadata,
    selectedHighlight,
    selectedPron,
    showAllProns,
    pause,
    pauseClip,
    wordAudioSprite,
    words,
  ]);

  // hide tooltip on scroll

  useEffect(() => {
    if (content) {
      function handleScroll() {
        setContent(null);
      }
      document.addEventListener('scroll', handleScroll);

      // remove listener on effect
      return () => document.removeEventListener('scroll', handleScroll);
    }
  }, [content]);

  // hide tooltip and scroll to top on docKey change

  useEffect(() => {
    setContent(null);
    window.scrollTo(0, 0);
  }, [docKey]);

  // render

  if (!(content && bounds)) {
    return null;
  }

  return (
    <Tooltip content={content} open={Boolean(content)}>
      <div className="def-tooltip" style={bounds} />
    </Tooltip>
  );
}
