import { useEffect, useState } from 'react';

import { subscribeToDrafts, unsubscribeFromDrafts } from '../lib/core';

// subscribes to draft metadata, updates when channelKey changes
export default function useChannelDrafts(isChannelAdmin, channelKey) {
  const [drafts, setDrafts] = useState(null);

  useEffect(() => {
    if (isChannelAdmin && channelKey) {
      subscribeToDrafts(channelKey, drafts => {
        // sort by last save time
        const sortedDrafts = Object.entries(drafts || {})
          // ensure that docKey is correct
          .map(([docKey, metadata]) => ({ ...metadata, docKey }))
          // sort by most recently edited
          .sort((a, b) => (a.saveDate > b.saveDate ? -1 : 1));
        setDrafts(sortedDrafts);
      });
    }

    // cleanup
    return () => {
      if (channelKey) {
        unsubscribeFromDrafts(channelKey);
      }
    };
  }, [channelKey, isChannelAdmin, setDrafts]);

  return drafts;
}
