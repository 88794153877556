import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { currentUserState } from '../state/auth';
import { channelKeyState, isChannelAdminState } from '../state/channel';
import { getChannelRole } from '../lib/core';

// updates isChannelAdmin whenever channelKey or user changes
export default function useIsChannelAdmin() {
  const user = useRecoilValue(currentUserState);
  const channelKey = useRecoilValue(channelKeyState);
  const setIsChannelAdmin = useSetRecoilState(isChannelAdminState);

  useEffect(() => {
    (async () => {
      if (channelKey && user) {
        setIsChannelAdmin(Boolean(await getChannelRole(channelKey, user.uid)));
      } else {
        setIsChannelAdmin(false);
      }
    })();
  }, [channelKey, setIsChannelAdmin, user]);

  return null;
}
