import { callCloudFunction } from './core';

export default async function processMedia(isVideo, mediaKey) {
  // call cloud function

  const result = await callCloudFunction('processMedia', {
    isVideo,
    mediaKey,
  });

  return result;
}
