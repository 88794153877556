const highlightLevelFunctions = {
  hsk: hsk => {
    if (typeof hsk !== 'number') {
      return null;
    }

    return hsk - 1;
  },
  tocfl: tocfl => {
    if (typeof tocfl !== 'number') {
      return null;
    }

    return tocfl - 1;
  },
  rank: rank => {
    if (typeof rank !== 'number' || rank === -1) {
      return null;
    }

    const thresholds = [150, 300, 600, 1200, 2500, 5000];
    for (let i = 0; i < thresholds.length; i += 1) {
      if (rank < thresholds[i]) {
        return i;
      }
    }

    return null;
  },
};

export default function getHighlighting(selectedHighlight, word, isVocab) {
  const shouldRenderChars =
    selectedHighlight && selectedHighlight.endsWith('-chars');

  const highlightFieldName =
    selectedHighlight && selectedHighlight.split('-')[0];

  if (highlightFieldName && word.data) {
    // if a highlighting option besides vocab is selected
    // and word has data

    const levelFunction = highlightLevelFunctions[highlightFieldName];

    const wordLevel =
      !shouldRenderChars &&
      levelFunction &&
      levelFunction(word.data[highlightFieldName]);

    const charLevels =
      shouldRenderChars && word.data.chars
        ? word.data.chars.map(char => levelFunction(char[highlightFieldName]))
        : null;

    return { charLevels, wordLevel };
  }

  return { wordLevel: isVocab ? 6 : null };
}
