import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { onClickSegment } from '../components/def/DefTooltip';
import { highlightedSegmentIndexState } from './editor';

export default function useSelectSegmentOnClick() {
  const setHighlightedSegmentIndex = useSetRecoilState(
    highlightedSegmentIndexState
  );

  useEffect(() => {
    function selectSegment(blockIndex, segmentIndex, segment) {
      setHighlightedSegmentIndex(segmentIndex);
    }

    onClickSegment(selectSegment);

    return () => onClickSegment(null);
  }, [setHighlightedSegmentIndex]);

  // init highlighted segment on mount, unset on unmount
  useEffect(() => {
    setHighlightedSegmentIndex(0);
    return () => setHighlightedSegmentIndex(null);
  }, [setHighlightedSegmentIndex]);
}
