import BMF from 'browser-md5-file';
import shortid from 'shortid';

import { firebaseShim } from './firebaseShim';

const bmf = new BMF();

export function calcFileMd5(file) {
  return new Promise((resolve, reject) => {
    bmf.md5(file, (err, md5) => {
      if (err) {
        reject(err);
      }
      resolve(md5);
    });
  });
}

export function createDocKey() {
  return shortid.generate().slice(0, 6);
}

export function shortKey() {
  return shortid.generate().slice(0, 4);
}

export function uploadStoragePath(uploadKey) {
  return `uploads/${uploadKey}`;
}

export function callCloudFunction(name, data) {
  return firebaseShim()
    .functions()
    .httpsCallable(name, { timeout: 300 * 1000 })(data)
    .then(result => result.data);
}

export function downloadStorageObjectAsJson(path) {
  return getStorageUrl(path)
    .then(fetch)
    .then(response => response.json());
}

export function generateDbKey() {
  return firebaseShim().database().ref().push().key;
}

export function getDbObject(path) {
  return firebaseShim()
    .database()
    .ref(path)
    .once('value')
    .then(snapshot => snapshot.val());
}

export function getStorageUrl(path) {
  return firebaseShim().storage().ref(path).getDownloadURL();
}

export function pushDbObject(path, value) {
  return firebaseShim().database().ref(path).push(value);
}

export function setDbObject(path, value) {
  return firebaseShim().database().ref(path).set(value);
}

export function setDbObjectTransaction(path, fn) {
  return firebaseShim().database().ref(path).transaction(fn);
}

export function updateDbObject(path, value) {
  return firebaseShim().database().ref(path).update(value);
}

export function subscribeToDbObject(path, handleUpdate, limitToLast) {
  let query = firebaseShim().database().ref(path);

  if (limitToLast) {
    query = query.limitToLast(limitToLast);
  }

  return query.on('value', snapshot => handleUpdate(snapshot.val()));
}

export function unsubscribeFromDbOject(path) {
  return firebaseShim().database().ref(path).off();
}

export async function uploadStorageObject(path, file, metadata) {
  try {
    // try to get download url
    await getStorageUrl(path);

    // if no throw then file was found, so resolve
    return Promise.resolve();
  } catch (error) {
    if (error.code === 'storage/object-not-found') {
      // if object-not-found error, upload it
      return firebaseShim().storage().ref(path).put(file, metadata);
    } else {
      // if it's another error, throw again
      throw error;
    }
  }
}
