import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import ContentEditable from 'react-contenteditable';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Button,
  CircularProgress,
  Icon,
  MenuSurface,
  MenuSurfaceAnchor,
} from 'rmwc';
import striptags from 'striptags';

import useChannelPage from '../hooks/useChannelPage';
import captureException from '../lib/captureException';
import {
  channelIconStoragePath,
  getStorageUrl,
  setChannelIconUrl,
  setChannelTitle,
  subscribe,
  unsubscribe,
  uploadMedia,
} from '../lib/core';
import processChannelImage from '../lib/processChannelImage';
import { currentUserState, isShowingAuthDialogState } from '../state/auth';
import {
  channelKeyState,
  channelMetadataState,
  isChannelAdminState,
  isEditingChannelState,
  isSubscribedState,
} from '../state/channel';

import './ChannelHeader.css';

const subscribeToChannelKey = 'subscribeToChannelKey';

export default function ChannelHeader(props) {
  const { canEdit, showDetails } = props;

  // recoil state

  const channelKey = useRecoilValue(channelKeyState);
  const channelMetadata = useRecoilValue(channelMetadataState);
  const currentUser = useRecoilValue(currentUserState);
  const isChannelAdmin = useRecoilValue(isChannelAdminState);
  const [isEditingChannel, setIsEditingChannel] = useRecoilState(
    isEditingChannelState
  );
  const [isSubscribed, setIsSubscribed] = useRecoilState(isSubscribedState);
  const setIsShowingAuthDialog = useSetRecoilState(isShowingAuthDialogState);

  // other hooks

  const { goToChannelPage } = useChannelPage();

  // local state

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isProcessingIcon, setIsProcessingIcon] = useState(false);
  const [newTitle, setNewTitle] = useState('');

  // derived state

  const { description, iconUrl, links } = channelMetadata || {};
  const title = channelMetadata
    ? channelMetadata.title || 'Channel ' + channelKey
    : '';

  // subscribe

  async function handleClickSubscribe() {
    // track click
    firebase.analytics().logEvent('click_subscribe', { channelKey });

    if (currentUser) {
      await subscribe(currentUser.uid, channelKey);
      setIsSubscribed(true);

      // track subscribe
      firebase.analytics().logEvent('subscribe', { channelKey });
    } else {
      setIsMenuOpen(true);
    }
  }

  function handleClickSignIn() {
    setIsMenuOpen(false);
    setIsShowingAuthDialog(true);

    // store channelKey so we can subscribe to it automatically
    // after sign in
    localStorage.setItem(subscribeToChannelKey, channelKey);

    // track click
    firebase.analytics().logEvent('click_subscribe_sign_in', { channelKey });
  }

  // if we find a stored channelKey,
  // automatically subscribe to it and remove from storage
  useEffect(() => {
    (async () => {
      if (currentUser) {
        const key = localStorage.getItem(subscribeToChannelKey);
        if (key) {
          await subscribe(currentUser.uid, key);
          localStorage.removeItem(subscribeToChannelKey);

          // track subscribe
          firebase.analytics().logEvent('subscribe', { channelKey });
        }
      }
    })();
  }, [channelKey, currentUser]);

  async function handleClickUnsubscribe() {
    if (currentUser) {
      await unsubscribe(currentUser.uid, channelKey);
      setIsSubscribed(false);

      // track unsubscribe
      firebase.analytics().logEvent('unsubscribe', { channelKey });
    }
  }

  // handle edit / done editing

  function handleClickEdit() {
    setNewTitle(title);
    setIsEditingChannel(true);
  }

  async function handleClickDone() {
    await setChannelTitle(channelKey, newTitle);
    setIsEditingChannel(false);
  }

  // upload handlers

  async function handleIconFileSelect(event) {
    if (this.files.length > 0) {
      const file = this.files[0];

      try {
        setIsProcessingIcon(true);

        // upload

        const { md5: uploadKey } = await uploadMedia(file);

        // process

        await processChannelImage(channelKey, uploadKey, false);

        // get image url

        const newImageUrl = await getStorageUrl(
          channelIconStoragePath(channelKey)
        );

        // write to channelMetadata db

        await setChannelIconUrl(channelKey, newImageUrl);
      } catch (error) {
        captureException(error);
      } finally {
        setIsProcessingIcon(false);
      }
    }
  }

  function handleUploadIcon() {
    const fileEl = document.getElementById('icon-file');
    if (fileEl) {
      fileEl.addEventListener('change', handleIconFileSelect, false);
      fileEl.click();
    }
  }

  // name edit handler

  function handleTitleChange(event) {
    setNewTitle(striptags(event.target.value));
  }

  // render

  return (
    <React.Fragment>
      <div className="channel-header">
        <div className="channel-header__icon-and-title">
          <div
            className="channel-header__icon"
            onClick={goToChannelPage}
            style={{ backgroundImage: `url(${iconUrl})` }}
          >
            {
              /* channel icon */
              isEditingChannel && (
                <React.Fragment>
                  <Icon
                    className="channel-header__icon-upload-button"
                    icon={isProcessingIcon ? <CircularProgress /> : 'upload'}
                    onClick={handleUploadIcon}
                  />
                  <input
                    className="channel-header__file-input"
                    id="icon-file"
                    accept="image/*"
                    type="file"
                  />
                </React.Fragment>
              )
            }
          </div>

          {
            /* channel title */
            isEditingChannel ? (
              <ContentEditable
                className={
                  'channel-header__title channel-header__title-editing'
                }
                html={newTitle}
                onChange={handleTitleChange}
                placeholder="Channel title"
                type="text"
              />
            ) : (
              <div
                className={'channel-header__title'}
                onClick={goToChannelPage}
              >
                {title}
              </div>
            )
          }
        </div>

        <div className="channel-header__action-buttons">
          {
            /* channel info edit button */
            isChannelAdmin && canEdit && !isEditingChannel && (
              <Button
                className="channel-header__left-action-button"
                icon="edit"
                label="Edit"
                onClick={handleClickEdit}
                outlined
              />
            )
          }

          {
            /* edit done button */
            isChannelAdmin && canEdit && isEditingChannel && (
              <Button
                className="channel-header__left-action-button"
                icon="check"
                label="Done"
                onClick={handleClickDone}
                raised
                theme="secondaryBg"
              />
            )
          }

          {
            /* subscribe button */
            !isSubscribed && channelMetadata && (
              <MenuSurfaceAnchor>
                <MenuSurface
                  anchorCorner="bottomLeft"
                  className="channel-header__subscribe-popout"
                  onClose={() => setIsMenuOpen(false)}
                  open={isMenuOpen}
                  renderToPortal
                >
                  <div className="channel-header__subscribe-popout-text">
                    Please <b>sign in</b> to subscribe to {title}.
                  </div>
                  <div className="channel-header__subscribe-popout-text">
                    You'll receive <b>new free lessons</b> by email once a week.
                  </div>
                  <Button
                    className="channel-header__subscribe-popout-sign-in-button"
                    icon="login"
                    label="Sign In"
                    onClick={handleClickSignIn}
                    raised
                  />
                </MenuSurface>
                <Button
                  dense
                  label="Subscribe"
                  onClick={handleClickSubscribe}
                  raised
                  theme="secondaryBg"
                />
              </MenuSurfaceAnchor>
            )
          }

          {
            /* already subscribed button */
            isSubscribed && channelMetadata && (
              <Button
                dense
                label="Subscribed"
                onClick={handleClickUnsubscribe}
                outlined
              />
            )
          }
        </div>
      </div>

      {
        /* channel description and links */
        showDetails && (
          <div className="channel-header__details">
            <div className="channel-header__description">{description}</div>
            {links && (
              <div className="channel-header__links">
                {links.map((link, linkIndex) => (
                  <React.Fragment key={linkIndex}>
                    <a
                      className="channel-header__link"
                      href={link.url}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                    >
                      {link.text}
                    </a>
                    {linkIndex < links.length - 1 ? ' · ' : null}
                  </React.Fragment>
                ))}
              </div>
            )}
          </div>
        )
      }
    </React.Fragment>
  );
}
