export default [
  'ar', // Arabic
  'cs', // Czech
  'da', // Danish
  'de', // German
  'el', // Greek
  'en', // English
  'es', // Spanish
  'fil', // Filipino
  'fr', // French
  'ga', // Irish
  'he', // Hebrew
  'hi', // Hindi
  'hu', // Hungarian
  'id', // Indonesian
  'ja', // Japanese
  'ko', // Korean
  'nl', // Dutch
  'no', // Norwegian
  'po', // Polish
  'pt', // Portuguese
  'ro', // Romanian
  'ru', // Russian
  'sv', // Swedish
  'th', // Thai
  'tr', // Turkish
  'uk', // Ukrainian
  'vi', // Vietnamese
  'zh', // Mandarin Chinese
];
