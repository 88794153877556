import React, { useLayoutEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { playingSegmentState } from '../hooks/player';
import { bottomBarHeightState } from '../state/ui';
import { showAllTranslationsState } from '../state/settings';
import './BottomBar.css';

export default function BottomBar(props) {
  // recoil state

  const showAllTranslations = useRecoilValue(showAllTranslationsState);
  const { blockIndex, segmentIndex } = useRecoilValue(playingSegmentState);
  const setBottomBarHeight = useSetRecoilState(bottomBarHeightState);

  // local state

  const barRef = useRef();

  // update height when playing segment or translation setting changes

  useLayoutEffect(() => {
    setBottomBarHeight(barRef.current.offsetHeight);
  }, [blockIndex, showAllTranslations, segmentIndex, setBottomBarHeight]);

  // render

  return (
    <div className="bottom-bar" ref={barRef}>
      {props.children}
    </div>
  );
}
