import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { metadataState } from '../hooks/doc';
import { getAudioUrl } from '../lib/core';

export default function SyncAudioContainer() {
  // recoil state

  const metadata = useRecoilValue(metadataState);

  // local state

  const [audioUrl, setAudioUrl] = useState(null);
  const [wordAudioUrl, setWordAudioUrl] = useState(null);

  // derived state

  const { audioKey, wordAudioKey } = metadata || {};

  // init and update main and word audio urls

  useEffect(() => {
    (async () => {
      if (audioKey) {
        setAudioUrl(await getAudioUrl(audioKey));
      } else {
        setAudioUrl(null);
      }

      if (wordAudioKey) {
        setWordAudioUrl(await getAudioUrl(wordAudioKey));
      } else {
        setWordAudioUrl(null);
      }
    })();
  }, [audioKey, wordAudioKey]);

  // render

  return (
    <React.Fragment>
      {audioUrl && <audio id="sync-main" src={audioUrl} />}

      {wordAudioUrl && <audio id="sync-word" src={wordAudioUrl} />}
    </React.Fragment>
  );
}
