import firebase from 'firebase/app';
import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, MenuSurfaceAnchor } from 'rmwc';

export default function TopBarMenu() {
  // local state

  const [isOpen, setIsOpen] = useState(false);

  // handlers

  function handleSignOut() {
    firebase.auth().signOut();

    // analytics
    firebase.analytics().logEvent('sign_out');
  }

  // render

  return (
    <MenuSurfaceAnchor>
      <Menu open={isOpen} onClose={() => setIsOpen(false)}>
        <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
      </Menu>

      <IconButton icon="more_vert" onClick={() => setIsOpen(true)} />
    </MenuSurfaceAnchor>
  );
}
