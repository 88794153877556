export default function getSelectedEditable() {
  // get selection anchor node
  let node = window.getSelection().anchorNode;

  // examine parent nodes until we find editable
  while (node && node.id !== 'editable') {
    node = node.parentElement;
  }

  return node;
}
