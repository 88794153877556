import equal from 'fast-deep-equal';

import {
  callCloudFunction,
  getDbObject,
  setDbObject,
  subscribeToDbObject,
  unsubscribeFromDbOject,
} from './utils';

// paths

export function draftDbPath(channelKey, docKey) {
  return `/drafts/${channelKey}/${docKey}`;
}

export function draftMetadataDbPath(channelKey, docKey) {
  return `/draftMetadata/${channelKey}/${docKey}`;
}

export function draftMetadataListDbPath(channelKey) {
  return `/draftMetadata/${channelKey}`;
}

// operations

export function fetchDraft(channelKey, docKey) {
  return getDbObject(draftDbPath(channelKey, docKey));
}

export async function saveDraft(channelKey, docKey, doc) {
  // resolve early if no changes were made

  const currentDraft = await getDbObject(draftDbPath(channelKey, docKey));

  if (currentDraft) {
    const normalizedCurrentDraft = {
      ...currentDraft,
      defs: currentDraft.defs || {},
      links: currentDraft.links || [],
      roles: currentDraft.roles || {},
    };

    if (equal(normalizedCurrentDraft, doc)) {
      return Promise.resolve();
    }
  }

  // set new save date

  const { metadata } = doc;

  const metadataWithSaveDate = {
    ...metadata,
    docKey,
    saveDate: new Date().getTime(),
  };
  const docWithSaveDate = {
    ...doc,
    metadata: metadataWithSaveDate,
  };

  // save to db

  return Promise.all([
    // update draft
    setDbObject(draftDbPath(channelKey, docKey), docWithSaveDate),
    // update metadata
    setDbObject(draftMetadataDbPath(channelKey, docKey), metadataWithSaveDate),
  ]);
}

export function deleteDraft(channelKey, docKey) {
  return (
    // set draft to null
    setDbObject(draftDbPath(channelKey, docKey), null)
      // set metadata to null
      .then(() => setDbObject(draftMetadataDbPath(channelKey, docKey), null))
  );
}

export function publishDraft(draft) {
  return callCloudFunction('publishDraft', { draft });
}

// subscribe

export function subscribeToDrafts(channelKey, handleUpdate) {
  return subscribeToDbObject(draftMetadataListDbPath(channelKey), handleUpdate);
}

export function unsubscribeFromDrafts(channelKey) {
  return unsubscribeFromDbOject(draftMetadataListDbPath(channelKey));
}
