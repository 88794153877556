import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import isProduction from './isProduction';
import snackbarQueue from './snackbarQueue';

const environment = isProduction() ? 'production' : 'development';

Sentry.init({
  dsn:
    'https://4622cae7f1fa448e9086c9e3d05cd56c@o20768.ingest.sentry.io/5434383',
  environment,
  integrations: [new Integrations.BrowserTracing()],
  release: 'lessonize@' + process.env.npm_package_version,
  tracesSampleRate: 1.0,
});

export default function captureException(error) {
  // log to console (only in dev)
  console.error(error); // eslint-disable-line

  // show error UI
  snackbarQueue.notify({ title: error.message });

  // log to sentry
  Sentry.captureException(error);
}
