import firebase from 'firebase/app';
import React, { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useRecoilState } from 'recoil';
import {
  Button,
  TopAppBar,
  TopAppBarFixedAdjust,
  TopAppBarNavigationIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from 'rmwc';

import FillBlanks from './FillBlanks';
import MatchVocab from './MatchVocab';
import exerciseTypes from '../../constants/exerciseTypes';
import { docKeyState } from '../../hooks/doc';
import { trackExerciseProgress } from '../../lib/core';
import { currentUserState } from '../../state/auth';
import {
  exerciseIndexState,
  exercisesState,
  lastCompletedExerciseIndexState,
} from '../../state/exercises';

import './Exercises.css';

export default function Exercise() {
  // recoil state

  const [exerciseIndex, setExerciseIndex] = useRecoilState(exerciseIndexState);
  const resetExerciseIndex = useResetRecoilState(exerciseIndexState);
  const exercises = useRecoilValue(exercisesState);
  const [
    lastCompletedExerciseIndex,
    setLastCompletedExerciseIndex,
  ] = useRecoilState(lastCompletedExerciseIndexState);

  const currentUser = useRecoilValue(currentUserState);
  const docKey = useRecoilValue(docKeyState);

  // derived state

  const numExercises = exercises ? exercises.length : 0;
  const currentExercise = exercises && exercises[exerciseIndex];

  // handlers

  function handleClickEnd() {
    resetExerciseIndex();
  }

  function handleExerciseComplete() {
    setLastCompletedExerciseIndex(exerciseIndex);

    // track in database
    if (currentUser) {
      trackExerciseProgress(
        currentUser.uid,
        docKey,
        exerciseIndex,
        numExercises
      );
    }

    // track in firebase analytics
    firebase.analytics().logEvent('complete_exercise', {
      docKey,
      exerciseIndex,
      numExercises,
    });
    if (exerciseIndex === numExercises - 1) {
      firebase.analytics().logEvent('finish_exercises', {
        docKey,
      });
    }
  }

  function handleClickNext() {
    setExerciseIndex(exerciseIndex + 1);
  }

  // lock body scroll on mount, unlock on unmount

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);

  // render

  let renderedExercise = <div>to be rendered</div>;
  switch (currentExercise.type) {
    case exerciseTypes.matchAudioToWords:
    case exerciseTypes.matchWordsToMeanings:
    case exerciseTypes.matchAudioToMeanings:
      renderedExercise = (
        <MatchVocab
          exercise={currentExercise}
          onComplete={handleExerciseComplete}
        />
      );
      break;

    case exerciseTypes.fillBlanksWithAudio:
    case exerciseTypes.fillBlanksWithTranslation:
      renderedExercise = (
        <FillBlanks
          exercise={currentExercise}
          onComplete={handleExerciseComplete}
        />
      );
      break;

    default:
      break;
  }

  return (
    <div className="exercise">
      <TopAppBar className="top-bar" fixed>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarNavigationIcon
              icon="close"
              id="top-bar__nav-button"
              onClick={handleClickEnd}
            />

            <TopAppBarTitle>{`Exercise ${
              exerciseIndex + 1
            } / ${numExercises}`}</TopAppBarTitle>
          </TopAppBarSection>
        </TopAppBarRow>
      </TopAppBar>
      <TopAppBarFixedAdjust />

      <div className="exercise-container">{renderedExercise}</div>

      {lastCompletedExerciseIndex === exerciseIndex && (
        <div className="exercise__correct-panel">
          Nicely done!
          {exerciseIndex === exercises.length - 1 && (
            <Button
              className="exercise__done-button"
              label="Return to lesson"
              onClick={handleClickEnd}
              theme="secondaryBg"
              unelevated
            />
          )}
          {exerciseIndex < exercises.length - 1 && (
            <Button
              className="exercise__done-button"
              label="Next"
              onClick={handleClickNext}
              theme="secondaryBg"
              unelevated
            />
          )}
        </div>
      )}
    </div>
  );
}
