import { subscribeToDbObject, unsubscribeFromDbOject } from './utils';

// paths

export function docUpdatesDbPath() {
  return `/docUpdates`;
}

// subscribe

export function subscribeToDocUpdates(handleUpdate) {
  const limitToLast = 100;
  return subscribeToDbObject(docUpdatesDbPath(), handleUpdate, limitToLast);
}

export function unsubscribeFromDocUpdates() {
  return unsubscribeFromDbOject(docUpdatesDbPath());
}
