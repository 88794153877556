import React from 'react';
import { useRecoilValue } from 'recoil';

import Segment from '../Segment';
import { scriptsState } from '../../hooks/doc';
import {
  encodeWord,
  // getPronIndexForKey,
} from '../../lib/core';
import {
  pronSettingState,
  scriptSettingState,
  showAllPronsState,
} from '../../state/settings';

export default function WordChoice(props) {
  // props

  const {
    choice,
    isBlank,
    isComplete,
    isHighlighted,
    isSelected,
    onClick,
  } = props;

  // recoil
  const scripts = useRecoilValue(scriptsState);
  const selectedScript = useRecoilValue(scriptSettingState);
  const showAllProns = useRecoilValue(showAllPronsState);
  const selectedPron = useRecoilValue(pronSettingState);

  // derived state

  const scriptTextMap = scripts[selectedScript];

  // render

  const choiceClass =
    'exercise__choice' + (isSelected ? ' exercise__choice--selected' : '');

  const displayText =
    (scriptTextMap && scriptTextMap[encodeWord(choice.segment.text)]) ||
    choice.segment.text;

  return (
    <div
      className={
        choiceClass +
        ' exercise__choice--word' +
        (isBlank ? ' exercise__choice--blank' : '') +
        (isComplete ? ' exercise__choice--complete' : '') +
        (isHighlighted ? ' exercise__choice--highlighted' : '')
      }
      onClick={onClick}
    >
      {(!isBlank || isComplete) && (
        <Segment
          def={choice.def}
          displayText={displayText}
          selectedPron={showAllProns && selectedPron}
          segment={choice.segment}
        />
      )}

      {isBlank && !isComplete && <span>&nbsp;</span>}
    </div>
  );
}
