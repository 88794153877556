import firebase from 'firebase/app';

import getFirebaseConfig from './getFirebaseConfig';

const { projectId } = getFirebaseConfig();

export default function translate(sourceLanguage, targetLanguage, keyedText) {
  const translateCallable = firebase.functions().httpsCallable('translate');

  let remappedSourceLanguage = sourceLanguage;
  if (sourceLanguage.startsWith('cmn')) {
    remappedSourceLanguage = remappedSourceLanguage.replace('cmn', 'zh');
  }

  return translateCallable({
    projectId,
    sourceLanguage: remappedSourceLanguage,
    targetLanguage,
    keyedText,
  }).then(result => result.data);
}
