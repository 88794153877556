import React from 'react';
import { useHistory } from 'react-router';

import { getLanguageName } from '../lib/core';

import './DocLinkTile.css';

export default function DocLink(props) {
  const { channelMetadata, docMetadata } = props;

  // derived state

  // urls
  const { alias } = channelMetadata;
  const { channelKey, docKey, slug } = docMetadata;
  const channelUrl = alias ? `/c/${alias}` : `/channel/${channelKey}`;
  const docUrl = `/l/${docKey}` + (slug ? '/' + slug : '');

  // language name
  const languageName = getLanguageName(
    docMetadata.sourceLanguage,
    docMetadata.targetLanguage
  );

  // hooks

  const history = useHistory();

  function handleChannelClick(event) {
    history.push(channelUrl);
    event.preventDefault();
  }

  function handleDocClick(event) {
    history.push(docUrl);
    event.preventDefault();
  }
  // render

  return (
    <div className="doc-link-tile">
      <a href={docUrl} onClick={handleDocClick}>
        <div
          className="doc-link-tile__poster"
          style={{
            backgroundImage: `url(${docMetadata.posterUrl})`,
          }}
        />
      </a>
      <div className="doc-link-tile__info">
        <a href={channelUrl} onClick={handleChannelClick}>
          <img
            alt="Channel Logo"
            className="doc-link-tile__channel-icon"
            src={channelMetadata.iconUrl}
          />
        </a>
        <div className="doc-link-tile__info-text">
          <a
            className="doc-link-tile__title"
            href={docUrl}
            onClick={handleDocClick}
          >
            {docMetadata.title}
          </a>
          <a
            className="doc-link-tile__channel-title"
            href={channelUrl}
            onClick={handleChannelClick}
          >
            {channelMetadata.title}
          </a>
          <a
            className="doc-link-tile__details"
            href={docUrl}
            onClick={handleDocClick}
          >
            {languageName} · {docMetadata.level}
          </a>
        </div>
      </div>
    </div>
  );
}
