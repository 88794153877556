import React, { useLayoutEffect, useRef } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { metadataState } from '../hooks/doc';
import { isEditingState } from '../hooks/editor';
import { isStickyState } from '../hooks/player';
import { mediaHeightState } from '../state/ui';

function StickyContainer(props) {
  const { children } = props;

  // recoil state
  const metadata = useRecoilValue(metadataState);
  const isSticky = useRecoilValue(isStickyState);
  const isEditing = useRecoilValue(isEditingState);

  // derived state

  const isVideo = metadata && (metadata.videoKey || metadata.youtubeUrl);

  // update media height

  const setMediaHeight = useSetRecoilState(mediaHeightState);

  const containerRef = useRef();

  useLayoutEffect(() => {
    setMediaHeight(containerRef.current.offsetHeight);
  }, [children, setMediaHeight]);

  let classes = 'media';
  if (isVideo && isSticky) {
    classes += ' media--sticky';
  }
  if (isEditing) {
    classes += ' media--small';
  }

  return (
    <div className={classes} ref={containerRef}>
      {children}
    </div>
  );
}

export default React.memo(StickyContainer);
