import produce from 'immer';
import React from 'react';
import { useRecoilState } from 'recoil';
import { IconButton } from 'rmwc';

import { vocabState } from '../../hooks/doc';
import { encodeWord } from '../../lib/core';

import './VocabToggle.css';

function VocabToggle(props) {
  const { word } = props;

  // recoil state

  const [vocab, setVocab] = useRecoilState(vocabState);

  // derived state

  const isVocab = Boolean(vocab[encodeWord(word)]);

  // handlers

  function handleClick() {
    setVocab(vocab =>
      produce(vocab, draftVocab => {
        draftVocab[encodeWord(word)] = isVocab ? null : true;
      })
    );
  }

  // render

  return (
    <IconButton
      className={isVocab ? 'vocab-toggle--checked' : null}
      icon={isVocab ? 'playlist_add_check' : 'playlist_add'}
      onClick={handleClick}
    />
  );
}

export default React.memo(VocabToggle);
