import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon, Menu, MenuItem, MenuSurfaceAnchor } from 'rmwc';

import colorNames from '../constants/colorNames';

function ColorMenu(props) {
  const { onSelect, selectedColor } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const { t } = useTranslation();

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleSelect(color) {
    onSelect(color);
    handleClose();
  }

  return (
    <React.Fragment>
      <MenuSurfaceAnchor className="language-select__anchor">
        <Menu open={isOpen} onClose={handleClose} renderToPortal>
          {colorNames.map(color => (
            <MenuItem
              className={`bg-${color} color-${color}`}
              key={color}
              onClick={() => handleSelect(color)}
              selected={color === selectedColor}
            >
              {t(color)}
            </MenuItem>
          ))}
        </Menu>
      </MenuSurfaceAnchor>

      <Icon
        className="icon--clickable"
        disabled={!selectedColor}
        icon={{ icon: 'palette', size: 'xsmall' }}
        onClick={handleOpen}
      />
    </React.Fragment>
  );
}

export default React.memo(ColorMenu);
