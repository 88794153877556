import { useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { editBlockIndexState } from '../hooks/editor';
import { blocksState } from './doc';

export default function useSelectBlockOnKeyPress(allowWSKeys) {
  // recoil state

  const blocks = useRecoilValue(blocksState);
  const [editBlockIndex, setEditBlockIndex] = useRecoilState(
    editBlockIndexState
  );

  // handler

  useEffect(() => {
    function handleKeyDown(event) {
      // on up or w, select previous block
      if (event.key === 'ArrowUp' || (event.key === 'w' && allowWSKeys)) {
        if (editBlockIndex > 0) {
          setEditBlockIndex(editBlockIndex - 1);
        }
        event.preventDefault();
      }

      // on up or s, select next block
      if (event.key === 'ArrowDown' || (event.key === 's' && allowWSKeys)) {
        if (editBlockIndex < blocks.length - 1) {
          setEditBlockIndex(editBlockIndex + 1);
        }
        event.preventDefault();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [allowWSKeys, blocks, editBlockIndex, setEditBlockIndex]);
}
