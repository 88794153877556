import produce from 'immer';
import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { IconButton, CircularProgress } from 'rmwc';

import AudioRecorder from './AudioRecorder';
import AudioWaveform from './AudioWaveform';
import { blocksState } from '../hooks/doc';
import captureException from '../lib/captureException';
import { uploadRecording } from '../lib/core';
import './BlockAudioRecorder.css';

function BlockAudioRecorder(props) {
  const { block, index } = props;
  const { sentenceAudioUrl } = block;

  // recoil state

  const setBlocks = useSetRecoilState(blocksState);

  // local state

  const [sentenceAudioBlob, setSentenceAudioBlob] = useState(null);
  const [isSavingSentence, setIsSavingSentence] = useState(false);

  // clear recording

  function handleClearSentenceRecording() {
    setSentenceAudioBlob(null);
  }

  // delete current audio

  function handleDeleteCurrentSentenceAudio() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks[index].sentenceAudioUrl = null;
      })
    );
  }

  // save audio

  async function handleSaveSentenceAudio() {
    setIsSavingSentence(true);

    try {
      // upload and process recording
      const audioUrl = await uploadRecording(sentenceAudioBlob);

      // update recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks[index].sentenceAudioUrl = audioUrl;
        })
      );

      // reset recording data
      setSentenceAudioBlob(null);
    } catch (error) {
      captureException(error);
    } finally {
      setIsSavingSentence(false);
    }
  }

  // render

  const sentenceAudioSrc = sentenceAudioBlob
    ? window.URL.createObjectURL(sentenceAudioBlob)
    : sentenceAudioUrl;

  return (
    <div className="block-audio-recorder">
      <div className="block-audio-recorder__section">
        <div className="block-audio-recorder__section-label">Normal speed</div>
        <div className="block-audio-recorder__section-row">
          {!sentenceAudioBlob && (
            <IconButton
              disabled={!sentenceAudioUrl || isSavingSentence}
              icon="delete"
              onClick={handleDeleteCurrentSentenceAudio}
            />
          )}

          {sentenceAudioBlob && (
            <IconButton
              disabled={isSavingSentence}
              icon="undo"
              onClick={handleClearSentenceRecording}
            />
          )}

          <AudioWaveform
            id={`sentence-audio-${block.key}`}
            url={sentenceAudioSrc}
          />

          <AudioRecorder onChange={setSentenceAudioBlob} />

          <IconButton
            disabled={!sentenceAudioBlob || isSavingSentence}
            icon={isSavingSentence ? <CircularProgress /> : 'check'}
            onClick={handleSaveSentenceAudio}
          />
        </div>
      </div>
    </div>
  );
}

export default React.memo(BlockAudioRecorder);
