import { atom } from 'recoil';

export const currentUserState = atom({
  key: 'currentUser',
  default: undefined,
});

export const isShowingAuthDialogState = atom({
  key: 'isShowingAuthDialogState',
  default: false,
});
