import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { Portal, SnackbarQueue } from 'rmwc';

import AuthDialog from './AuthDialog';
import Channel from './Channel';
import DefTooltip from './def/DefTooltip';
import Doc from './Doc';
import DocErrorBoundary from './DocErrorBoundary';
import DocSettings from './DocSettings';
import Draft from './Draft';
import Home from './Home';
import TopBarDraft from './TopBarDraft';
import TopBar from './TopBar';
import { isEditingState } from '../hooks/editor';
import useChannelMetadataSubscription from '../hooks/useChannelMetadataSubscription';
import useIsChannelAdmin from '../hooks/useIsChannelAdmin';
import useIsSubscribed from '../hooks/useIsSubscribed';
import useSubscribeToAuthStateChanges from '../hooks/useSubscribeToAuthStateChanges';
import useUpdateStateFromLocation from '../hooks/useUpdateStateFromLocation';
import snackbarQueue from '../lib/snackbarQueue';
import { useRestoreSettings } from '../state/settings';

// render

export default function App() {
  // recoil state

  const isEditing = useRecoilValue(isEditingState);

  // other hooks

  useChannelMetadataSubscription(); // update metadata based on channel key
  useIsChannelAdmin(); // update isChannelAdmin based on channelKey and user
  useIsSubscribed(); // updates isSubscribed whenever channelKey or user changes
  useRestoreSettings();
  useSubscribeToAuthStateChanges();
  useUpdateStateFromLocation();

  // render

  return (
    <React.Fragment>
      {!isEditing && <TopBar />}

      {isEditing && <TopBarDraft />}

      <DocErrorBoundary>
        <Switch>
          <Route path="/c/:channelAlias" component={Channel} />

          <Route path="/channel/:channelKey" component={Channel} />

          <Route path="/draft/:channelKey/:docKey" component={Draft} />

          <Route path="/l/:docKey" component={Doc} />

          <Route path="/" component={Home} />
        </Switch>
      </DocErrorBoundary>

      <DefTooltip />

      <DocSettings />

      <AuthDialog />

      <SnackbarQueue messages={snackbarQueue.messages} />

      <Portal />
    </React.Fragment>
  );
}
