import React from 'react';
import editModes from '../constants/editModes';

import smallProns from '../constants/smallProns';
import { getPronForKey, getDefForKey } from '../lib/core';
import getHighlighting from '../lib/getHighlighting';

import './Segment.css';

function Segment(props) {
  const {
    blockIndex,
    editMode,
    word,
    displayText,
    segmentIndex,
    hasPlayingHighlight,
    isHighlighted,
    isPlaying,
    isSpaceDelimited,
    isSyncing,
    isVocab,
    selectedHighlight,
    selectedPron,
    segment,
    textOffset,
  } = props;

  // derived state

  const isAnnotating = editMode === editModes.annotations;
  const isSegmenting = editMode === editModes.segments;

  // css class to indicate whether segment is highlighted or playing
  let highlightClass = isHighlighted ? ' block-text__segment--highlighted' : '';
  if (hasPlayingHighlight) {
    highlightClass += ' segment--playing';
  }

  // css class for space delimited languages
  const spaceDelimitedClass = isSpaceDelimited ? ' space-delimited' : '';

  let toEditClass = '';

  if (isSegmenting) {
    // if editor is in segments mode
    toEditClass += ' segment--segmenting';
  }

  if (isAnnotating) {
    // highlight segment if missing word
    if (!word) {
      toEditClass += ' segment--error';
    }
    // highlight segment if missing defKey
    if (!segment.defKey && word && word.defs.length > 1) {
      toEditClass += ' segment--error';
    }
  } else if (isSyncing) {
    // if editor is in sync mode
    if (typeof segment.startTime !== 'number') {
      // highlight segment if missing startTime
      toEditClass += ' segment--error';
    }
  }

  // if word was provided for this segment
  if (word) {
    // get def and pron
    const def = getDefForKey(word, segment.defKey);
    const pron = getPronForKey(def, segment.pronKey);

    if ((segment.defKey && !def) || (segment.pronKey && !pron)) {
      // highlight if def or pron was removed during editing
      toEditClass += ' segment--error';
    }

    if (isAnnotating && def && def.meaning.length > 100) {
      // highlight in annotation mode if meaning is too long
      toEditClass += ' segment--warning';
    }

    // css class to indicate segment is key vocabulary
    const vocabClass = segment.isVocab ? ' block-text__segment--vocab' : '';

    const pronClass = smallProns[selectedPron]
      ? 'word__pron word__pron--small'
      : 'word__pron';

    // word/char highlighting
    const { charLevels, wordLevel } = getHighlighting(
      selectedHighlight,
      word,
      isVocab
    );

    // level scale
    const levelClass =
      !charLevels && typeof wordLevel === 'number'
        ? ` word__container--level-${wordLevel}`
        : '';

    const charsClass = charLevels
      ? ' word__container--chars'
      : ' word__container--no-chars';

    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        className={
          'word__container' +
          vocabClass +
          highlightClass +
          levelClass +
          charsClass +
          toEditClass
        }
        data-block-index={blockIndex}
        data-segment-index={segmentIndex}
        data-text={segment.text}
        data-text-offset={textOffset}
        data-def-key={segment.defKey}
        data-pron-key={segment.pronKey}
        id={isPlaying ? 'playing-segment' : null}
      >
        {pron && selectedPron && (
          <div
            className={pronClass}
            ref={r => {
              if (r) {
                r.contentEditable = false;
              }
            }}
          >
            {pron[selectedPron]}
          </div>
        )}

        {!charLevels && (
          <div
            className={'word__text' + spaceDelimitedClass}
            data-block-index={blockIndex}
            data-segment-index={segmentIndex}
            data-text-offset={textOffset}
          >
            {displayText}
          </div>
        )}

        {charLevels && (
          <div className={'word__text word__chars' + spaceDelimitedClass}>
            {displayText.split('').map((char, index) => {
              const charLevel = charLevels[index];
              const levelClass =
                typeof charLevel === 'number'
                  ? ` word__container--level-${charLevel}`
                  : '';
              return (
                <div className={'word__char' + levelClass} key={index}>
                  {char}
                </div>
              );
            })}
          </div>
        )}
      </a>
    );
  }
  // no def was found for this segment
  else if (displayText) {
    return (
      <span
        className={
          'segment--no-def' + highlightClass + spaceDelimitedClass + toEditClass
        }
        data-block-index={blockIndex}
        data-segment-index={segmentIndex}
        data-text-offset={textOffset}
        id={isPlaying ? 'playing-segment' : null}
      >
        {displayText}
      </span>
    );
  }

  // segment has no text
  return null;
}

export default React.memo(Segment);
