import React, { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Dialog } from 'rmwc';

import AuthCreateAccount from './AuthCreateAccount';
import AuthSignIn from './AuthSignIn';
import { currentUserState, isShowingAuthDialogState } from '../state/auth';

import './Auth.css';

const signUp = 'signUp';
const logIn = 'logIn';

export default function Auth(props) {
  // recoil state

  const currentUser = useRecoilValue(currentUserState);
  const [isShowingAuthDialog, setIsShowingAuthDialog] = useRecoilState(
    isShowingAuthDialogState
  );

  // local state

  const [action, setAction] = useState(signUp);

  // render

  return (
    <Dialog
      open={isShowingAuthDialog && !currentUser}
      onClose={() => setIsShowingAuthDialog(false)}
      renderToPortal
    >
      {action === signUp && (
        <AuthCreateAccount onChangeAction={() => setAction(logIn)} />
      )}

      {action === logIn && (
        <AuthSignIn onChangeAction={() => setAction(signUp)} />
      )}
    </Dialog>
  );
}
