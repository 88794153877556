export default {
  text: 'text',
  roles: 'roles',
  audio: 'audio',
  segments: 'segments',
  sync: 'sync',
  annotations: 'annotations',
  translations: 'translations',
  exercises: 'exercises',
  preview: 'preview',
};
