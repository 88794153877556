import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { docKeyState, docSelectorState } from './doc';
import { isEditingState, editModeState } from './editor';
import { mediaInputState, mediaOutputState } from './player';
import { youtubeHasPlayedState } from './player';
import { getChannelKeyFromAlias, parsePath } from '../lib/core';
import getPageTitle from '../lib/getPageTitle';
import { channelKeyState, isEditingChannelState } from '../state/channel';
import {
  exerciseIndexState,
  lastCompletedExerciseIndexState,
} from '../state/exercises';

// render

export default function useUpdateStateFromLocation() {
  // recoil state

  const setChannelKey = useSetRecoilState(channelKeyState);
  const setDoc = useSetRecoilState(docSelectorState);
  const setDocKey = useSetRecoilState(docKeyState);
  const setIsEditing = useSetRecoilState(isEditingState);
  const resetEditMode = useResetRecoilState(editModeState);
  const resetIsEditingChannel = useResetRecoilState(isEditingChannelState);
  const resetMediaInput = useResetRecoilState(mediaInputState);
  const resetMediaOutput = useResetRecoilState(mediaOutputState);
  const resetYoutubeHasPlayed = useResetRecoilState(youtubeHasPlayedState);
  const resetExerciseIndex = useResetRecoilState(exerciseIndexState);
  const resetLastCompletedExercise = useResetRecoilState(
    lastCompletedExerciseIndexState
  );

  // other hooks

  const history = useHistory();
  const location = useLocation();

  // reset state when location changes

  useEffect(() => {
    (async () => {
      // reset player
      resetMediaInput();
      resetMediaOutput();
      resetYoutubeHasPlayed();

      // reset exercises
      resetExerciseIndex();
      resetLastCompletedExercise();

      // reset doc, media, edit mode, and page title
      setDocKey(null);
      setDoc(null);
      setIsEditing(false);
      resetEditMode();
      resetIsEditingChannel();
      document.title = getPageTitle();

      const {
        channelAlias,
        channelKey,
        docKey,
        isEditing,
        isValid,
      } = parsePath(window.location.pathname);

      if (!isValid) {
        // if path is invalid

        // reset keys
        setChannelKey(null);

        // go home
        history.replace('/');
      } else if (channelAlias) {
        // if path is channel alias /c/{alias}
        setChannelKey(await getChannelKeyFromAlias(channelAlias));
      } else {
        // all other paths
        setChannelKey(channelKey);
        setDocKey(docKey);
        setIsEditing(isEditing);
      }
    })();
  }, [
    history,
    location,
    resetEditMode,
    resetExerciseIndex,
    resetIsEditingChannel,
    resetLastCompletedExercise,
    resetMediaInput,
    resetMediaOutput,
    resetYoutubeHasPlayed,
    setChannelKey,
    setDoc,
    setDocKey,
    setIsEditing,
  ]);

  return null;
}
