import { saveAs } from 'file-saver';

export default function exportJson(doc) {
  const docString = JSON.stringify(doc);
  const blob = new Blob([docString], { type: 'text/json;charset=utf-8' });
  const { docKey, slug } = doc.metadata;

  const fileName = docKey + (slug ? '-' + slug : '') + '.json';

  saveAs(blob, fileName);
}
