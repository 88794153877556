import React, { useMemo } from 'react';
import { Chip, ChipSet } from 'rmwc';

function RoleMenu(props) {
  const { role, roles, onAdd, onSelect } = props;

  const sortedRoles = useMemo(
    () =>
      Object.entries(roles || {})
        .map(([key, role]) => ({ ...role, key }))
        .sort((a, b) => (a.name < b.name ? -1 : 1)),
    [roles]
  );

  function handleSelect(roleKey) {
    onSelect(roleKey);
  }

  function handleAdd() {
    onAdd();
  }

  function handleClear() {
    onSelect(null);
  }

  // select role via hotkey

  const handleKeyDown = React.useCallback(
    event => {
      if (document.activeElement.contentEditable !== 'true') {
        const number = -('0' - event.key);
        if (typeof number === 'number' && sortedRoles[number - 1]) {
          onSelect(sortedRoles[number - 1].key);
        }
      }
    },
    [sortedRoles, onSelect]
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  // render

  return (
    <ChipSet>
      {sortedRoles.map((role, index) => (
        <Chip
          className={`bg-${role.color} color-${role.color}`}
          label={`${index + 1} ${role.name}`}
          key={index}
          onClick={() => handleSelect(role.key)}
        />
      ))}

      {role && (
        <Chip
          className="role-editor__icon"
          icon="clear"
          onClick={handleClear}
        />
      )}

      <Chip className="role-editor__icon" icon="add" onClick={handleAdd} />
    </ChipSet>
  );
}

export default React.memo(RoleMenu);
