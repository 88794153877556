import produce from 'immer';
import React, { useCallback, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Button } from 'rmwc';

import InsertCaptionsDialog from './InsertCaptionsDialog';
import blockTypes from '../constants/blockTypes';
import { blocksState, useBlocks } from '../hooks/doc';
import { captionTracksState } from '../hooks/editor';
import createBlock from '../lib/createBlock';

import './TextToolbar.css';

const blockTypeButtons = [
  { type: blockTypes.heading, icon: 'title', label: 'Heading' },
  { type: blockTypes.paragraph, icon: 'subject', label: 'Paragraph' },
  { type: blockTypes.bubble, icon: 'chat_bubble_outline', label: 'Bubble' },
  // {
  //   type: blockTypes.multipleChoiceReading,
  //   icon: 'help_outline',
  //   label: 'Question',
  // },
];

function TextToolbar(props) {
  const { block, index } = props;
  const captionTracks = useRecoilValue(captionTracksState);
  const setBlocks = useSetRecoilState(blocksState);
  const { insertCaptions } = useBlocks();

  // use block

  const currentType = block && block.type;

  const [isInsertingCaptions, setIsInsertingCaptions] = useState(false);

  // handle block type selection

  function handleSelectBlockType(type) {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks[index].type = type;
      })
    );
  }

  // insert/delete

  function handleInsertBlockBefore() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.splice(index, 0, createBlock());
      })
    );
  }

  function handleInsertBlockAfter() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.splice(index + 1, 0, createBlock());
      })
    );
  }

  function handleDeleteBlock() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.splice(index, 1);
      })
    );
  }

  // captions handlers

  function handleOpenCaptionDialog() {
    setIsInsertingCaptions(true);
  }

  const handleInsertCaptions = useCallback(
    captions => {
      insertCaptions(index, captions);
    },
    [index, insertCaptions]
  );

  // render

  return (
    <div className="text-toolbar">
      {blockTypeButtons.map(typeButton => {
        const { icon, label, type } = typeButton;
        const isSelected = type === currentType;
        const className = isSelected ? 'text-toolbar__button--selected' : null;
        const onMouseDown = isSelected
          ? null
          : event => {
              handleSelectBlockType(type);

              // prevent click from taking focus from editor
              event.preventDefault();
            };

        return (
          <Button
            className={className}
            icon={icon}
            key={type}
            label={label}
            onMouseDown={onMouseDown}
          />
        );
      })}

      <Button
        icon="keyboard_arrow_up"
        label="Insert block before"
        onClick={handleInsertBlockBefore}
      />

      <Button
        icon="keyboard_arrow_down"
        label="Insert block after"
        onClick={handleInsertBlockAfter}
      />

      {captionTracks && captionTracks.length > 0 && (
        <Button
          icon="keyboard_arrow_down"
          label="Insert captions"
          onClick={handleOpenCaptionDialog}
        />
      )}

      <Button icon="delete" label="Delete block" onClick={handleDeleteBlock} />

      {isInsertingCaptions && (
        <InsertCaptionsDialog
          captionTracks={captionTracks}
          onInsert={handleInsertCaptions}
          onClose={() => setIsInsertingCaptions(false)}
        />
      )}
    </div>
  );
}

export default React.memo(TextToolbar);
