import { callCloudFunction } from './core';

export default async function processImage(mediaKey, thumbnail) {
  // call cloud function

  const result = await callCloudFunction('processImage', {
    mediaKey,
    thumbnail,
  });

  return result;
}
