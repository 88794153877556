import { encodeWord } from './def';
import { getDbObject, setDbObjectTransaction } from './utils';

// paths

/*
/wordProgress/{uid}/{sourceLanguage}_{taretLanguage}/{base64Word}/{defKey}
  lookups/{date}
    docKey
    versionKey
    blockKey
    duration
  reviews/{date}
    type: blank|match
    docKey
    versionKey
    blockKey
    correct: true|false
*/

export function allUserWordProgressDbPath(uid, sourceLanguage, targetLanguage) {
  if (!uid || !sourceLanguage || !targetLanguage) {
    throw new Error('Invalid args');
  }
  return `/wordProgress/${uid}/${sourceLanguage}_${targetLanguage}`;
}

export function userWordProgressDbPath(
  uid,
  sourceLanguage,
  targetLanguage,
  word
) {
  if (!uid || !sourceLanguage || !targetLanguage || !word) {
    throw new Error('Invalid args');
  }
  return `/wordProgress/${uid}/${sourceLanguage}_${targetLanguage}/${encodeWord(
    word
  )}`;
}

// getters

export function getUserWordProgress(uid, sourceLanguage, targetLanguage, word) {
  return getDbObject(
    userWordProgressDbPath(uid, sourceLanguage, targetLanguage, word)
  );
}

// setters

export function trackWordLookup(
  uid,
  sourceLanguage,
  targetLanguage,
  word,
  docKey,
  versionKey,
  blockKey,
  defKey,
  pronKey,
  duration
) {
  if (!docKey || !versionKey || !blockKey || !defKey || !duration) {
    throw new Error('Invalid args');
  }

  return setDbObjectTransaction(
    userWordProgressDbPath(uid, sourceLanguage, targetLanguage, word),
    wordProgress => {
      const newWordProgress = wordProgress || { lookups: {} };

      const dateString = new Date().getTime().toString();
      newWordProgress.lookups[dateString] = {
        docKey,
        versionKey,
        blockKey,
        defKey,
        pronKey: pronKey || null,
        duration,
      };

      return newWordProgress;
    }
  );
}
