import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { channelKeyState, channelMetadataState } from '../state/channel';

export default function useChannelPage() {
  const channelKey = useRecoilValue(channelKeyState);
  const channelMetadata = useRecoilValue(channelMetadataState);

  const history = useHistory();
  const location = useLocation();

  const [isOnChannelPage, setIsOnChannelPage] = useState(false);

  const { alias } = channelMetadata || {};

  useEffect(() => {
    setIsOnChannelPage(
      location.pathname === `/c/${alias}` ||
        location.pathname === `/channel/${channelKey}`
    );
  }, [alias, channelKey, location]);

  const goToChannelPage = useCallback(() => {
    if (isOnChannelPage) {
      return;
    } else if (alias) {
      history.push(`/c/${alias}`);
    } else if (channelKey) {
      history.push(`/channel/${channelKey}`);
    }
  }, [alias, channelKey, history, isOnChannelPage]);

  return { goToChannelPage, isOnChannelPage };
}
