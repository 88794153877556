import React, { useLayoutEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  Button,
  TopAppBar as RmwcTopAppBar,
  TopAppBarFixedAdjust,
  TopAppBarNavigationIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from 'rmwc';

import TopBarMenu from './TopBarMenu';
import { docKeyState } from '../hooks/doc';
import useChannelPage from '../hooks/useChannelPage';
import getPageTitle from '../lib/getPageTitle';
import { currentUserState, isShowingAuthDialogState } from '../state/auth';
import { channelMetadataState } from '../state/channel';
import { topBarHeightState } from '../state/ui';

import './TopBar.css';

export default function TopBar() {
  // recoil state

  const channelMetadata = useRecoilValue(channelMetadataState);
  const docKey = useRecoilValue(docKeyState);
  // const setIsShowingSideBar = useSetRecoilState(isShowingSideBarState);
  const setTopBarHeight = useSetRecoilState(topBarHeightState);

  //auth
  const currentUser = useRecoilValue(currentUserState);
  const setIsShowingAuthDialog = useSetRecoilState(isShowingAuthDialogState);

  // local state

  const barRef = useRef();

  // other hooks

  const history = useHistory();
  const location = useLocation();

  const { isOnChannelPage } = useChannelPage();

  // derived state

  const channelTitle = channelMetadata && channelMetadata.title;
  const isHome = location.pathname === '/';

  // handlers

  function handleClickHome() {
    if (isHome) {
      return;
    } else {
      history.push(`/`);
    }
  }

  // function handleClickNav() {
  //   setIsShowingSideBar(isShowingSideBar => !isShowingSideBar);
  // }

  // update height

  useLayoutEffect(() => {
    setTopBarHeight(barRef.current.offsetHeight);
  }, [setTopBarHeight]);

  // render

  let barClasses = 'top-bar';
  let signInClasses = 'top-bar__sign-in';
  if (docKey) {
    barClasses += ' top-bar--lesson';
    signInClasses += ' top-bar__sign-in--lesson';
  }

  return (
    <React.Fragment>
      <RmwcTopAppBar className={barClasses} fixed ref={barRef}>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarNavigationIcon
              icon={<img alt="Home" src="/android-chrome-192x192.png" />}
              onClick={handleClickHome}
            />

            <TopAppBarTitle onClick={handleClickHome}>
              {isOnChannelPage ? channelTitle : getPageTitle()}
            </TopAppBarTitle>
          </TopAppBarSection>

          <TopAppBarSection alignEnd>
            {currentUser && <TopBarMenu />}
            {!currentUser && (
              <Button
                className={signInClasses}
                label="Sign In"
                onClick={() => setIsShowingAuthDialog(true)}
              />
            )}
          </TopAppBarSection>
        </TopAppBarRow>
      </RmwcTopAppBar>

      <TopAppBarFixedAdjust />
    </React.Fragment>
  );
}
