import { atom } from 'recoil';

export const channelKeyState = atom({
  key: 'channelKeyState',
  default: null,
});

export const channelListState = atom({
  key: 'channelListState',
  default: null,
});

export const channelMetadataState = atom({
  key: 'channelMetadataState',
  default: null,
});

export const isChannelAdminState = atom({
  key: 'isChannelAdminState',
  default: false,
});

export const isEditingChannelState = atom({
  key: 'isEditingChannelState',
  default: false,
});

export const isSubscribedState = atom({
  key: 'isSubscribedState',
  default: false,
});
