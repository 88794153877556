import produce from 'immer';
import { useCallback } from 'react';
import { atom, selector, useRecoilState } from 'recoil';
import shortid from 'shortid';

import blockTypes from '../constants/blockTypes';
import languageOptions from '../constants/languageOptions';
import { shortKey } from '../lib/core';
import { channelMetadataState } from '../state/channel';

export const minDuration = 0.5;

// docKey

export const docKeyState = atom({
  key: 'docKeyState',
  default: null,
});

// metadata

export const metadataState = atom({
  key: 'metadataState',
  default: null,
});

// blocks

export const blocksState = atom({
  key: 'blocksState',
  default: null,
});

export function createLinkBlock(url) {
  return {
    key: shortid.generate(),
    type: blockTypes.link,
    docKey: url.substr(url.lastIndexOf('/') + 1),
  };
}

export function createVocabularyListBlock() {
  return {
    key: shortid.generate(),
    type: blockTypes.vocabularyList,
  };
}

export function useBlocks() {
  const [blocks, setBlocks] = useRecoilState(blocksState);

  const setBlock = useCallback(
    (index, newBlock) => {
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks.splice(index, 1, newBlock);
        })
      );
    },
    [setBlocks]
  );

  const set = useCallback(
    (index, key, value) => {
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks[index][key] = value;
        })
      );
    },
    [setBlocks]
  );

  const setBlockText = useCallback((index, text) => set(index, 'text', text), [
    set,
  ]);

  const setBlockRoleKey = useCallback(
    (index, newKey) => set(index, 'roleKey', newKey),
    [set]
  );

  const setBlockShouldPause = useCallback(
    (index, shouldPause) => set(index, 'shouldPause', shouldPause),
    [set]
  );

  const setBlockTiming = useCallback(
    (index, startTime, endTime) => {
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks[index].startTime = startTime;
          draftBlocks[index].endTime = endTime;
        })
      );
    },
    [setBlocks]
  );

  // inits block start time to last block endTime before it

  const initBlockTiming = useCallback(
    index => {
      setBlocks(prevBlocks => {
        let startTime = 0;

        if (index > 0) {
          prevBlocks.some((block, loopIndex) => {
            const { endTime } = block;

            if (endTime) {
              startTime = endTime;
            }

            // iterate until we reach the block before
            // the one we are init'ing
            return loopIndex === index - 1;
          });
        }

        return produce(prevBlocks, draftBlocks => {
          draftBlocks[index].startTime = startTime;
          draftBlocks[index].endTime = startTime + 1;
        });
      });
    },
    [setBlocks]
  );

  // inserts captions at index

  const insertCaptions = useCallback(
    (index, captions) => {
      // create blocks from captions
      const captionBlocks = captions.map(caption => ({
        key: shortKey(),
        type: blockTypes.bubble,
        segments: [{ text: caption.text }],
        startTime: caption.startTime,
        endTime: caption.endTime,
      }));

      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks.splice(index, 0, ...captionBlocks);
        })
      );
    },
    [setBlocks]
  );

  // export

  return {
    blocks,
    setBlocks,
    setBlock,
    setBlockText,
    setBlockRoleKey,
    setBlockShouldPause,
    setBlockTiming,
    initBlockTiming,
    insertCaptions,
  };
}

// words

export const wordsState = atom({
  key: 'wordsState',
  default: null,
});

// scripts

export const scriptsState = atom({
  key: 'scriptsState',
  default: null,
});

// links

export const linksState = atom({
  key: 'linksState',
  default: null,
});

// roles

export const rolesState = atom({
  key: 'rolesState',
  default: null,
});

export function useRoles() {
  const [roles, setRoles] = useRecoilState(rolesState);

  return {
    roles,
    setRoles,
  };
}

// vocab

export const vocabState = atom({
  key: 'vocabState',
  default: null,
});

// doc

export const docSelectorState = selector({
  key: 'docSelectorState',
  get: ({ get }) => {
    const metadata = get(metadataState);
    const blocks = get(blocksState);
    const words = get(wordsState);
    const scripts = get(scriptsState);
    const links = get(linksState);
    const roles = get(rolesState);
    const vocab = get(vocabState);
    return { metadata, blocks, words, scripts, links, roles, vocab };
  },
  set: ({ set }, newDoc) => {
    if (newDoc) {
      const { metadata, blocks, words, scripts, links, roles, vocab } = newDoc;
      set(metadataState, metadata);
      set(blocksState, blocks);
      set(wordsState, words || {});
      set(scriptsState, scripts || {});
      set(linksState, links || []);
      set(rolesState, roles || {});
      set(vocabState, vocab || {});
    } else {
      set(metadataState, null);
      set(blocksState, null);
      set(wordsState, null);
      set(scriptsState, null);
      set(linksState, null);
      set(vocabState, null);
    }
  },
});

// has timing

export const hasTimingState = selector({
  key: 'hasTimingState',
  get: ({ get }) => {
    const blocks = get(blocksState);

    if (blocks) {
      return blocks.some(block => typeof block.startTime === 'number');
    }

    // blocks may not have loaded yet
    return false;
  },
});

// has questions selector

export const hasQuestionsState = selector({
  key: 'hasQuestionsState',
  get: ({ get }) => {
    const blocks = get(blocksState);

    if (blocks) {
      return blocks.some(block => block.type === blockTypes.multipleChoice);
    }

    // blocks may not have loaded yet
    return false;
  },
});

// pron options for current sourceLanguage

export const pronOptionsState = selector({
  key: 'pronOptionsState',
  get: ({ get }) => {
    const metadata = get(metadataState);
    const channelMetadata = get(channelMetadataState);

    const { sourceLanguage } = metadata || channelMetadata || {};
    const sourceLanguageOptions = languageOptions[sourceLanguage];
    return sourceLanguageOptions ? sourceLanguageOptions.prons : null;
  },
});

// option to start media on first caption instead of 0:00

export const mediaStartsOnFirstCaptionState = selector({
  key: 'mediaStartsOnFirstCaptionState',
  get: ({ get }) => {
    const metadata = get(metadataState);
    return metadata && metadata.mediaStartsOnFirstCaption;
  },
  set: ({ set }, newValue) => {
    set(metadataState, metadata =>
      produce(metadata, draftMetadata => {
        if (draftMetadata) {
          draftMetadata.mediaStartsOnFirstCaption = newValue;
        }
      })
    );
  },
});
