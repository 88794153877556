import firebase from 'firebase/app';
import isEmail from 'isemail';
import React, { useState } from 'react';
import {
  Button,
  CircularProgress,
  DialogContent,
  DialogTitle,
  TextField,
} from 'rmwc';

import './Auth.css';

export default function AuthSignIn(props) {
  const { onChangeAction } = props;

  // local state

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  // handlers

  async function onSubmit() {
    // validate
    if (!isEmail.validate(email)) {
      setErrorMessage('Please enter a valid email address');
      return;
    }
    if (password.length < 6) {
      setErrorMessage('Password should be at least 6 characters');
      return;
    }

    // remove error message and show loading indicator
    setErrorMessage(null);
    setIsLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      // track sign in
      firebase.analytics().logEvent('sign_in');
    } catch (error) {
      setErrorMessage(error.message);

      // track failed sign in
      firebase.analytics().logEvent('fail_sign_in');
    } finally {
      setIsLoading(false);
    }
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter') {
      onSubmit();
    }
  }

  function handleSignInWithGoogle() {
    setIsConnecting(true);
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithRedirect(provider);

    // analytics
    firebase.analytics().logEvent('click_sign_in_with_google');
  }

  // render

  if (isConnecting) {
    return (
      <DialogContent className="auth__connecting">
        <CircularProgress />
      </DialogContent>
    );
  }

  return (
    <React.Fragment>
      <DialogTitle>Sign In</DialogTitle>

      <DialogContent>
        <TextField
          autoFocus
          className="auth__input"
          disabled={isLoading}
          label="Email"
          onChange={event => setEmail(event.target.value)}
          theme="secondary"
          type="email"
          value={email}
        />

        <TextField
          className="auth__input"
          disabled={isLoading}
          label="Password"
          onChange={event => setPassword(event.target.value)}
          onKeyPress={handleKeyPress}
          type="password"
          value={password}
        />

        {errorMessage && <div className="auth__error">{errorMessage}</div>}

        <Button
          className="auth__action-button"
          disabled={isLoading}
          icon={isLoading ? <CircularProgress /> : 'login'}
          label="Sign In"
          onClick={onSubmit}
          raised
          theme="secondaryBg"
        />

        <div className="auth__third-party">
          <img
            alt="Sign in with Google"
            className="auth__google"
            src="/sign-in-with-google.png"
            onClick={handleSignInWithGoogle}
          />
        </div>

        <Button
          className="auth__action-button"
          disabled={isLoading}
          label="Create a new account"
          onClick={onChangeAction}
        />
      </DialogContent>
    </React.Fragment>
  );
}
