import React from 'react';
import { useRecoilValue } from 'recoil';

import levelLabels from '../constants/levelLabels';
import smallProns from '../constants/smallProns';
import { scriptsState } from '../hooks/doc';
import useWordAudioSprites from '../hooks/useWordAudioSprites';
import audioSpriteKey from '../lib/audioSpriteKey';
import { encodeWord, getDefForKey, getPronForKey } from '../lib/core';
import {
  highlightSettingState,
  pronSettingState,
  scriptSettingState,
} from '../state/settings';
import { keyVocabularyState } from '../state/vocab';

import './VocabSection.css';

export default function VocabSection() {
  // recoil state
  const keyVocabulary = useRecoilValue(keyVocabularyState);
  const scripts = useRecoilValue(scriptsState);
  const selectedHighlight = useRecoilValue(highlightSettingState);
  const selectedPron = useRecoilValue(pronSettingState);
  const selectedScript = useRecoilValue(scriptSettingState);

  // derived state

  const scriptTextMap = scripts[selectedScript];

  // other hooks

  const wordAudioSprites = useWordAudioSprites();

  // handlers

  function handlePlay(segment) {
    if (wordAudioSprites) {
      wordAudioSprites.play(audioSpriteKey(segment));
    }
  }

  // render

  return (
    <React.Fragment>
      <div className="heading-outer">
        <div className="heading-inner">Vocabulary</div>
      </div>
      <table className="vocab-table">
        <tbody>
          {keyVocabulary.map(({ segment, word }, index) => {
            const displayText =
              (scriptTextMap && scriptTextMap[encodeWord(segment.text)]) ||
              segment.text;
            const def = getDefForKey(word, segment.defKey);
            const pron = getPronForKey(def, segment.pronKey);
            const highlight = selectedHighlight && word.data[selectedHighlight];
            const showLevel =
              typeof highlight === 'number' && levelLabels[selectedHighlight];

            return (
              <tr
                className="vocab-table__row"
                key={index}
                onClick={() => handlePlay(segment)}
              >
                <td className="vocab-table__word">{displayText}</td>

                <td className="vocab-table__meaning">
                  {pron && pron[selectedPron] && (
                    <span
                      className={
                        smallProns[selectedPron]
                          ? 'vocab-table__pron--small'
                          : 'vocab-table__pron'
                      }
                    >
                      {pron[selectedPron]} -&nbsp;
                    </span>
                  )}
                  {def.meaning}
                  {showLevel && (
                    <React.Fragment>
                      {' - '}
                      <div
                        className={`vocab-table__level word__container--level-${highlight}`}
                      >
                        {levelLabels[selectedHighlight][highlight]}
                      </div>
                    </React.Fragment>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </React.Fragment>
  );
}
