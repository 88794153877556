import produce from 'immer';
import React from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import SegmentEditor from './def/SegmentEditor';
import { metadataState, blocksState, wordsState } from '../hooks/doc';
import { highlightedSegmentIndexState } from '../hooks/editor';
import useSelectSegmentOnClick from '../hooks/useSelectSegmentOnClick';
import useSelectSegmentOnKeyPress from '../hooks/useSelectSegmentOnKeyPress';
import { encodeWord, setChannelDefs } from '../lib/core';
import { channelKeyState } from '../state/channel';

export default function AnnotationsToolbar(props) {
  const { block, index: blockIndex } = props;

  // recoil state

  const metadata = useRecoilValue(metadataState);
  const setBlocks = useSetRecoilState(blocksState);
  const [words, setWords] = useRecoilState(wordsState);
  const channelKey = useRecoilValue(channelKeyState);
  const highlightedSegmentIndex = useRecoilValue(highlightedSegmentIndexState);

  // derived state

  const { sourceLanguage, targetLanguage } = metadata;
  const segment =
    typeof highlightedSegmentIndex === 'number'
      ? block.segments[highlightedSegmentIndex]
      : null;
  const word = segment && words ? words[encodeWord(segment.text)] : null;

  // hooks

  useSelectSegmentOnClick();
  useSelectSegmentOnKeyPress();

  // handle update segment pron or meaning key

  function handleChangeSegment(segmentIndex, newSegment) {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks[blockIndex].segments[segmentIndex] = newSegment;
      })
    );
  }

  // handle save word

  async function handleSaveWord(text, newWord) {
    // update doc word via recoil state
    setWords(words =>
      produce(words, draftWords => {
        draftWords[encodeWord(text)] = newWord;
      })
    );

    // update channel defs in db
    return setChannelDefs(
      channelKey,
      sourceLanguage,
      targetLanguage,
      text,
      newWord.defs
    );
  }

  // render

  return (
    <React.Fragment>
      {!word && 'Click on a segment to edit its popup'}

      {word && (
        <SegmentEditor
          onChangeSegment={newSegment =>
            handleChangeSegment(highlightedSegmentIndex, newSegment)
          }
          onSaveWord={newWord => handleSaveWord(segment.text, newWord)}
          segment={segment}
          word={word}
        />
      )}
    </React.Fragment>
  );
}
