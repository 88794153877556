import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  CircularProgress,
  IconButton,
  Menu,
  MenuItem,
  MenuSurfaceAnchor,
} from 'rmwc';

import { docKeyState, docSelectorState, metadataState } from '../hooks/doc';
import useChannelPage from '../hooks/useChannelPage';
import { channelKeyState } from '../state/channel';
import { deleteDraft } from '../lib/core';
import captureException from '../lib/captureException';
import exportJson from '../lib/exportJson';

function DraftActionMenu() {
  // recoil state

  const channelKey = useRecoilValue(channelKeyState);
  const doc = useRecoilValue(docSelectorState);
  const docKey = useRecoilValue(docKeyState);
  const metadata = useRecoilValue(metadataState);

  // derived state

  const slug = metadata && metadata.slug;

  // local state

  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // other hooks

  const history = useHistory();
  const { goToChannelPage } = useChannelPage();

  // handlers

  function handleOpen() {
    setIsOpen(true);
  }

  function handleClose() {
    setIsOpen(false);
  }

  function handleClickView() {
    history.push(`/l/${docKey}` + (slug ? '/' + slug : ''));
    setIsOpen(false);
  }

  function handleClickExport() {
    exportJson(doc);
  }

  async function handleClickDelete() {
    setIsProcessing(true);

    try {
      await deleteDraft(channelKey, docKey);
      goToChannelPage();
    } catch (error) {
      captureException(error);
    } finally {
      setIsProcessing(false);
    }
  }

  return (
    <MenuSurfaceAnchor>
      <Menu open={isOpen} onClose={handleClose}>
        <MenuItem
          className="draft-action-menu__menu-item"
          id="draft-action-menu__view"
          onClick={handleClickView}
        >
          View Published
        </MenuItem>

        <MenuItem
          className="draft-action-menu__menu-item"
          id="draft-action-menu__export"
          onClick={handleClickExport}
        >
          Export JSON
        </MenuItem>

        <MenuItem
          className="draft-action-menu__menu-item"
          id="draft-action-menu__delete"
          onClick={handleClickDelete}
        >
          Delete Draft
        </MenuItem>
      </Menu>

      <IconButton
        disabled={isProcessing}
        icon={isProcessing ? <CircularProgress /> : 'more_vert'}
        onClick={handleOpen}
      />
    </MenuSurfaceAnchor>
  );
}

export default React.memo(DraftActionMenu);
