import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import {
  blocksState,
  wordsState,
  metadataState,
  mediaStartsOnFirstCaptionState,
} from './doc';
import languageOptions from '../constants/languageOptions';
import playerModes from '../constants/playerModes';
import { useCallback } from 'react';
import { encodeWord, getPronForKey, getDefForKey } from '../lib/core/def';

export const maxTime = 100000;

export function previousTimedSegment(blocks, blockIndex, segmentIndex) {
  for (let bi = blockIndex; bi >= 0; bi -= 1) {
    const { segments } = blocks[bi];
    if (segments) {
      // if on initial blockIndex and segmentIndex is first segment
      if (bi === blockIndex && segmentIndex === 0) {
        // no need to iterate over segments for this block
        continue;
      }

      // otherwise, iterate backwards over segments
      for (
        let si = bi === blockIndex ? segmentIndex - 1 : segments.length - 1;
        si >= 0;
        si -= 1
      ) {
        if (segments[si].startTime) {
          // return indices of first segment found with a startTime
          return { blockIndex: bi, segmentIndex: si };
        }
      }
    }
  }

  return null;
}

export function nextTimedSegment(blocks, blockIndex, segmentIndex) {
  for (let bi = 0; bi < blockIndex; bi += 1) {
    const { segments } = blocks[bi];
    if (segments) {
      // if on initial blockIndex and segmentIndex is last segment
      if (bi === blockIndex && segmentIndex === segments.length - 1) {
        // no need to iterate over segments for this block
        continue;
      }

      // otherwise, iterate forward over segments
      for (
        let si = bi === blockIndex ? segmentIndex + 1 : 0;
        si < segments.length;
        si += 1
      ) {
        if (segments[si].startTime) {
          // return indices of first segment found with a startTime
          return { blockIndex: bi, segmentIndex: si };
        }
      }
    }
  }

  return null;
}

// line-by-line mode

export const isLineByLineState = atom({
  key: 'isLineByLineState',
  default: false,
});

// player mode

export const playerModeState = atom({
  key: 'playerModeState',
  default: playerModes.default,
});

// blocks filtered by playerMode

export const playerBlocksState = selector({
  key: 'playerBlocksState',
  get: ({ get }) => {
    const blocks = get(blocksState);

    // do filtering here depending on mode?

    return blocks || [];
  },
});

// first playable block index

export const firstPlayableIndexState = selector({
  key: 'firstPlayableIndexState',
  get: ({ get }) => {
    const blocks = get(playerBlocksState);

    if (blocks) {
      for (let i = 0; i < blocks.length; i += 1) {
        const { segments } = blocks[i];
        if (
          segments &&
          segments.some(segment => typeof segment.startTime === 'number')
        ) {
          return i;
        }
      }
    }

    return null;
  },
});

// last playable block index

export const lastPlayableIndexState = selector({
  key: 'lastPlayableIndexState',
  get: ({ get }) => {
    const blocks = get(playerBlocksState);

    if (blocks) {
      for (let i = blocks.length - 1; i >= 0; i -= 1) {
        const { segments } = blocks[i];
        if (
          segments &&
          segments.some(segment => typeof segment.startTime === 'number')
        ) {
          return i;
        }
      }
    }

    return null;
  },
});

// player state

export const mediaIsLoadedState = atom({
  key: 'mediaIsLoadedState',
  default: false,
});

export const wordAudioIsLoadedState = atom({
  key: 'wordAudioIsLoadedState',
  default: false,
});

export const mediaInputState = atom({
  key: 'mediaInputState',
  default: {
    isPlaying: false,
    currentTime: null,
    rewindTime: null,
    startTime: 0,
    endTime: maxTime,
  },
});

export const mediaOutputState = atom({
  key: 'mediaOutputState',
  default: {
    isPlaying: false,
    currentTime: 0,
  },
});

export const isStickyState = atom({
  key: 'isStickyState',
  default: false,
});

export const startIndexState = atom({
  key: 'startIndexState',
  default: null,
});

export const playingSegmentState = selector({
  key: 'playingSegmentState',
  get: ({ get }) => {
    const blocks = get(playerBlocksState);
    const { isPlaying, startTime, endTime } = get(mediaInputState);
    const { currentTime } = get(mediaOutputState);

    // bound boundedTime by start/end time
    let boundedTime = currentTime;

    if (startTime !== null) {
      boundedTime = isPlaying ? Math.max(startTime, boundedTime) : startTime;
    }
    if (endTime !== null) {
      // bound to just before endtime to prevent advancing to next
      // caption when next startTime equals current endTime
      boundedTime = Math.min(endTime - 0.01, boundedTime);
    }

    // find new playingSegment

    let lastPossibleSegment = { blockIndex: null, segmentIndex: null };
    for (const [blockIndex, { segments }] of blocks.entries()) {
      if (segments) {
        for (const [segmentIndex, segment] of segments.entries()) {
          if (segment.startTime <= boundedTime) {
            lastPossibleSegment = { blockIndex, segmentIndex };
          }

          if (segment.startTime > boundedTime) {
            return lastPossibleSegment;
          }
        }
      }
    }

    // if there is no segment starting after currentTime
    return lastPossibleSegment;
  },
});

export const canGoState = selector({
  key: 'canGoState',
  get: ({ get }) => {
    const firstPlayableIndex = get(firstPlayableIndexState);
    const lastPlayableIndex = get(lastPlayableIndexState);
    const { blockIndex } = get(playingSegmentState);

    return {
      canGoBack: blockIndex > firstPlayableIndex,
      canGoForward: blockIndex < lastPlayableIndex,
    };
  },
});

// hook with state and actions

export function useMediaInput() {
  const [mediaInput, setMediaInput] = useRecoilState(mediaInputState);
  const playerBlocks = useRecoilValue(playerBlocksState);
  const setIsSticky = useSetRecoilState(isStickyState);

  // player actions

  const play = useCallback(() => {
    // console.log('play');
    setMediaInput(mediaInput => ({
      ...mediaInput,
      currentTime: null,
      rewindTime: null,
      isPlaying: true,
    }));
    setIsSticky(true);
  }, [setMediaInput, setIsSticky]);

  const pause = useCallback(() => {
    // console.log('pause');
    setMediaInput(mediaInput => ({
      ...mediaInput,
      currentTime: null,
      rewindTime: null,
      isPlaying: false,
      startTime: 0,
      endTime: maxTime,
    }));
  }, [setMediaInput]);

  const pauseAndHide = useCallback(() => {
    // console.log('pauseAndHide');
    setMediaInput(mediaInput => ({
      ...mediaInput,
      currentTime: null,
      rewindTime: null,
      isPlaying: false,
      startTime: 0,
      endTime: maxTime,
    }));
    setIsSticky(false);
  }, [setMediaInput, setIsSticky]);

  const playClip = useCallback(
    (newStartTime, newEndTime) => {
      // console.log('playClip', newStartTime, newEndTime);
      setMediaInput(mediaInput => ({
        ...mediaInput,
        rewindTime: null,
        isPlaying: true,
        currentTime: newStartTime,
        startTime: newStartTime,
        endTime: newEndTime,
      }));
      setIsSticky(true);
    },
    [setMediaInput, setIsSticky]
  );

  const pauseClip = useCallback(
    (newStartTime, newEndTime) => {
      // console.log('pauseClip', newStartTime, newEndTime);
      setMediaInput(mediaInput => ({
        ...mediaInput,
        rewindTime: null,
        isPlaying: false,
        currentTime: newStartTime,
        startTime: newStartTime,
        endTime: newEndTime,
      }));
    },
    [setMediaInput]
  );

  const resetPlayerStartAndEndTime = useCallback(() => {
    // console.log('resetPlayerStartAndEndTime');
    setMediaInput(mediaInput => ({
      ...mediaInput,
      currentTime: null,
      rewindTime: null,
      isPlaying: null,
      startTime: 0,
      endTime: maxTime,
    }));
    setIsSticky(true);
  }, [setMediaInput, setIsSticky]);

  const rewindAndPlay = useCallback(
    seconds => {
      // console.log('rewindAndPlay');
      setMediaInput(mediaInput => ({
        ...mediaInput,
        currentTime: null,
        isPlaying: true,
        rewindTime: seconds,
        startTime: 0,
        endTime: maxTime,
      }));
      setIsSticky(true);
    },
    [setMediaInput, setIsSticky]
  );

  // plays block

  const playBlock = useCallback(
    startIndex => {
      let { startTime } = playerBlocks[startIndex].segments[0];
      if (typeof startTime !== 'number') {
        const nextSegment = nextTimedSegment(playerBlocks, startIndex, 0);
        if (nextSegment) {
          startTime =
            playerBlocks[nextSegment.blockIndex].segments[
              nextSegment.segmentIndex
            ];
        }
      }

      if (typeof startTime === 'number') {
        playClip(startTime, maxTime);
      }
    },
    [playClip, playerBlocks]
  );

  return {
    mediaInput,
    play,
    pause,
    pauseAndHide,
    playClip,
    pauseClip,
    resetPlayerStartAndEndTime,
    rewindAndPlay,
    playBlock,
  };
}

// has translations

export const hasTranslationsState = selector({
  key: 'hasTranslationsState',
  get: ({ get }) => {
    const playerBlocks = get(playerBlocksState);

    return playerBlocks.some(block => block.translation);
  },
});

// has prons

export const hasPronsState = selector({
  key: 'hasPronsState',
  get: ({ get }) => {
    // recoil state

    const metadata = get(metadataState);
    const playerBlocks = get(playerBlocksState);
    const words = get(wordsState);

    // derived state

    const { sourceLanguage } = metadata || {};
    const sourceLanguageOptions = languageOptions[sourceLanguage];
    const { prons: pronOptions } = sourceLanguageOptions || {};

    // if sourceLanguage has no pron options, return false

    if (!pronOptions) {
      return false;
    }

    // otherwise, iterate over blocks to find any segment with
    // a def that has any pron type

    return (
      words &&
      // iterate over blocks until we find a block with pron
      playerBlocks.some(block => {
        return (
          block.segments &&
          // iterate over segments until we find a segment with pron
          block.segments.some(segment => {
            const word = segment.hasDefs && words[encodeWord(segment.text)];
            if (word) {
              // find pron by key
              const def = getDefForKey(word, segment.defKey);
              const pron = getPronForKey(def, segment.pronKey);
              // return whether it has any type of pron for sourceLanguage
              return pronOptions.some(option => pron[option]);
            }
            // otherwise return false if this segment doesn't have a def
            return false;
          })
        );
      })
    );
  },
});

// derived state tracking which blocks are in source language
// based on threshold of at least 30% of characters having defs

export const blockIsInSourceLanguageState = selector({
  key: 'blockIsInSourceLanguageState',
  get: ({ get }) => {
    // recoil state

    const blocks = get(blocksState);

    if (!(blocks && blocks.map)) {
      return null;
    }

    // derive

    const blockIsInSourceLanguage = blocks.map(block => {
      if (block.segments) {
        let charsWithDef = 0;
        let totalChars = 0;
        block.segments.forEach(segment => {
          if (segment.hasDefs) {
            charsWithDef += segment.text.length;
          }
          totalChars += segment.text.length;
        });

        return charsWithDef / totalChars >= 0.3;
      }

      return false;
    });

    return blockIsInSourceLanguage;
  },
});

export const youtubeHasPlayedState = atom({
  key: 'youtubeHasPlayedState',
  default: false,
});

export const mustTapVideoState = selector({
  key: 'mustTapVideoState',
  get: ({ get }) => {
    const metadata = get(metadataState);
    const youtubeHasPlayed = get(youtubeHasPlayedState);

    return metadata && metadata.youtubeUrl && !youtubeHasPlayed;
  },
});

export const mediaStartTimeState = selector({
  key: 'mediaStartTimeState',
  get: ({ get }) => {
    const blocks = get(blocksState);
    const mediaStartsOnFirstCaption = get(mediaStartsOnFirstCaptionState);

    let startTime = 0;

    if (mediaStartsOnFirstCaption && blocks) {
      // if media starts on first caption, find first startTime
      blocks.some(block =>
        block.segments.some(segment => {
          if (typeof segment.startTime === 'number') {
            startTime = Math.floor(segment.startTime);
            return true;
          }
          return false;
        })
      );
    }

    return startTime;
  },
});
