import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import React, { useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import Waveform from './Waveform';
import './TimeShifter.css';

dayjs.extend(duration);

const { devicePixelRatio } = window;

// magic number calculated from uplot rendered width and media duration
const pixelsPerSecond = 187.44396960302763 / (2 / devicePixelRatio);

function offsetToTime(offset) {
  return offset / pixelsPerSecond;
}

function timeToOffset(time) {
  return time * pixelsPerSecond;
}

function overlayStyle(start, end) {
  return { height: (end - start) * pixelsPerSecond };
}

function Timeline(props) {
  const { duration } = props;
  const secondLabels = [];

  for (let s = 0; s < duration + 1; s += 1) {
    secondLabels.push(
      <div
        className="timeline__second-label"
        key={s}
        style={{
          top: s * pixelsPerSecond,
        }}
      >
        {dayjs.duration(s, 'seconds').format('m:ss')}
      </div>
    );
  }

  return (
    <div
      className="timeline"
      style={{
        backgroundImage: `linear-gradient(0, transparent ${
          pixelsPerSecond / 5 - 1
        }px, #e0e0e0 1px)`,
        backgroundSize: `16px ${pixelsPerSecond / 5}px`,
        height: (duration + 10) * pixelsPerSecond,
      }}
    >
      {secondLabels}
    </div>
  );
}

function TimeShifter(props) {
  const { duration, endTime, id, mediaElement, onChange, startTime } = props;

  // local state

  const scrollContainerRef = useRef();
  const [didRenderWaveform, setDidRenderWaveform] = useState(false);
  const didAutoScrollRef = useRef(false);

  // derived state

  const hasStartTime = typeof startTime === 'number';
  const hasEndTime = typeof endTime === 'number';

  // handlers

  function handleClick(event) {
    const { scrollTop } = scrollContainerRef.current;
    const newStartTime = offsetToTime(scrollTop);

    const rect = scrollContainerRef.current.getBoundingClientRect();
    const clickPos = scrollTop + event.clientY - rect.top - 250;
    const newEndTime = offsetToTime(clickPos);

    // call onChange even if newTime hasn't changed
    // to trigger playClip
    onChange(newStartTime, newEndTime);
  }

  function handleWaveformRendered() {
    // track whether waveform has rendered
    // because it changes scroll offsets slightly,
    // causing unwanted time shifts
    setDidRenderWaveform(true);
  }

  // update local state and scroll to it when id changes

  useLayoutEffect(
    () => {
      if (hasStartTime) {
        // if segment has start time, must auto scroll
        didAutoScrollRef.current = false;
      } else {
        // otherwise, no need to auto scroll
        didAutoScrollRef.current = true;
      }

      if (didRenderWaveform && hasStartTime && scrollContainerRef.current) {
        // scroll to offset
        const newOffset = timeToOffset(startTime || 0);
        scrollContainerRef.current.scrollTop = newOffset;
        didAutoScrollRef.current = true;
      }
    },
    [didRenderWaveform, id] // eslint-disable-line
  );

  useLayoutEffect(() => {
    // create copy because ref.current will be gone during cleanup
    const refCopy = scrollContainerRef.current;

    if (refCopy) {
      function handleScroll() {
        if (didAutoScrollRef.current) {
          const { scrollTop } = scrollContainerRef.current;
          const newStartTime = offsetToTime(scrollTop);
          onChange(newStartTime, endTime);
        }
      }

      refCopy.addEventListener('scroll', handleScroll);

      // cleanup
      return () => {
        refCopy.removeEventListener('scroll', handleScroll);
      };
    }
  }, [endTime, onChange]);

  // render

  return ReactDOM.createPortal(
    <div className="time-shifter">
      {hasStartTime && <div className="time-shifter__center-line" />}
      {!hasStartTime && <div className="time-shifter__center-line--no-time" />}

      {hasEndTime && (
        <div
          className="time-shifter__overlay"
          style={overlayStyle(startTime, endTime)}
        />
      )}

      <div
        className="time-shifter__scroll-container"
        onClick={handleClick}
        ref={scrollContainerRef}
      >
        {mediaElement && (
          <Waveform
            mediaElement={mediaElement}
            onRendered={handleWaveformRendered}
          />
        )}

        <Timeline duration={duration} />
      </div>
    </div>,
    document.getElementById('timeshifter-container')
  );
}

export default React.memo(TimeShifter);
