import produce from 'immer';
import React, { useCallback } from 'react';
import { Icon } from 'rmwc';

import ColorMenu from './ColorMenu';
import Editable from './Editable';
import RoleMenu from './RoleMenu';
import colorNames from '../constants/colorNames';
import { useRoles, useBlocks } from '../hooks/doc';
import useSelectBlockOnKeyPress from '../hooks/useSelectBlockOnKeyPress';
import { shortKey } from '../lib/core';

function RoleEditor(props) {
  const { block, index } = props;

  const { setBlockRoleKey, setBlocks } = useBlocks();
  const { roles, setRoles } = useRoles();

  const roleKey = block.roleKey;
  const role = roles && roles[roleKey];
  const roleAlign = role && role.align;
  const roleColor = role && role.color;
  const roleName = role && role.name;

  // hooks

  useSelectBlockOnKeyPress();

  // handle editing role name

  const handleNameChange = useCallback(
    newName => {
      // update shared roles
      setRoles(roles =>
        produce(roles, draftRoles => {
          draftRoles[roleKey].name = newName;
        })
      );
    },
    [roleKey, setRoles]
  );

  // handle selecting block role

  const handleSelectRole = useCallback(
    roleKey => setBlockRoleKey(index, roleKey),
    [index, setBlockRoleKey]
  );

  // handle deleting role

  const handleDeleteRole = useCallback(() => {
    // remove roleKey from blocks
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(draftBlock => {
          if (draftBlock.roleKey === roleKey) {
            draftBlock.roleKey = null;
          }
        });
      })
    );

    // remove from shared roles
    setRoles(roles =>
      produce(roles, draftRoles => {
        delete draftRoles[roleKey];
      })
    );
  }, [roleKey, setBlocks, setRoles]);

  function handleAddRole() {
    const roleKey = shortKey();

    // add to shared roles
    setRoles(roles =>
      produce(roles, draftRoles => {
        const randomColor = colorNames[Math.floor(Math.random() * 16)];
        draftRoles[roleKey] = {
          align: 'left',
          color: randomColor,
          name: 'New role',
        };
      })
    );

    // select role for current block
    handleSelectRole(roleKey);
  }

  function handleSelectColor(newColor) {
    // update shared roles
    setRoles(roles =>
      produce(roles, draftRoles => {
        draftRoles[roleKey].color = newColor;
      })
    );
  }

  function handleToggleAlign() {
    const newAlign = roleAlign === 'right' ? 'left' : 'right';

    // update shared roles
    setRoles(roles =>
      produce(roles, draftRoles => {
        draftRoles[roleKey].align = newAlign;
      })
    );
  }

  return (
    <div className="role-editor">
      <RoleMenu
        onAdd={handleAddRole}
        onSelect={handleSelectRole}
        role={role}
        roles={roles}
      />

      {role && (
        <div className={`role-editor__current-role-row color-${roleColor}`}>
          <div className="role-editor__name">
            {role && (
              <Editable
                initialText={roleName || ''}
                onChange={handleNameChange}
              />
            )}
            {!role && 'Select role'}
          </div>

          <div className="role-editor__current-role-buttons">
            {role && (
              <ColorMenu
                onSelect={handleSelectColor}
                selectedColor={roleColor}
              />
            )}

            {role && (
              <Icon
                className="icon--clickable"
                icon={{
                  icon:
                    roleAlign === 'right'
                      ? 'format_align_left'
                      : 'format_align_right',
                  size: 'xsmall',
                }}
                onClick={handleToggleAlign}
              />
            )}

            {role && (
              <Icon
                className="icon--clickable"
                icon={{
                  icon: 'delete',
                  size: 'xsmall',
                }}
                onClick={handleDeleteRole}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(RoleEditor);
