import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { LinearProgress } from 'rmwc';

import AutoScroller from './AutoScroller';
import Block from './Block';
import BlockErrorBoundary from './BlockErrorBoundary';
import BottomBar from './BottomBar';
import BottomBarWatch from './BottomBarWatch';
import BottomBarTranslation from './BottomBarTranslation';
import ChannelHeader from './ChannelHeader';
import DocInfo from './DocInfo';
import ExercisesSection from './exercises/ExercisesSection';
import StickyContainer from './StickyContainer';
import MediaPlayer from './MediaPlayer';
import VocabSection from './VocabSection';
import blockTypes from '../constants/blockTypes';
import {
  docKeyState,
  metadataState,
  rolesState,
  docSelectorState,
} from '../hooks/doc';
import {
  mediaIsLoadedState,
  mediaOutputState,
  playerBlocksState,
  playingSegmentState,
  useMediaInput,
  wordAudioIsLoadedState,
} from '../hooks/player';
import {
  fetchDoc,
  fetchDocAsGuest,
  getUserDocProgress,
  trackDocView,
} from '../lib/core';
import getPageTitle from '../lib/getPageTitle';
import { answersState } from '../state/answers';
import {
  isShowingTranslationState,
  showAllTranslationsState,
} from '../state/settings';
import { currentUserState } from '../state/auth';
import { channelKeyState } from '../state/channel';
import { lastCompletedExerciseIndexState } from '../state/exercises';
import { keyVocabularyState } from '../state/vocab';

import './Draft.css';

export default function Doc(props) {
  // recoil state

  const setChannelKey = useSetRecoilState(channelKeyState);

  const docKey = useRecoilValue(docKeyState);

  const setLastCompletedExerciseIndex = useSetRecoilState(
    lastCompletedExerciseIndexState
  );
  const resetAnswers = useResetRecoilState(answersState);
  const user = useRecoilValue(currentUserState);
  const metadata = useRecoilValue(metadataState);
  const mediaIsLoaded = useRecoilValue(mediaIsLoadedState);
  const wordAudioIsLoaded = useRecoilValue(wordAudioIsLoadedState);
  const playerBlocks = useRecoilValue(playerBlocksState);
  const roles = useRecoilValue(rolesState);
  const setDoc = useSetRecoilState(docSelectorState);
  const isShowingTranslation = useRecoilValue(isShowingTranslationState);
  const showAllTranslations = useRecoilValue(showAllTranslationsState);
  const { isPlaying } = useRecoilValue(mediaOutputState);
  const { rewindAndPlay, pause, play, playBlock } = useMediaInput();

  const {
    blockIndex: playingBlockIndex,
    segmentIndex: playingSegmentIndex,
  } = useRecoilValue(playingSegmentState);
  const keyVocabulary = useRecoilValue(keyVocabularyState);

  // local state

  const [isLoading, setIsLoading] = useState(false);

  // handle key events

  const handleKeyDown = React.useCallback(
    event => {
      switch (event.key) {
        case 'Tab':
          if (event.shiftKey) {
            // go back 2 seconds and play
            rewindAndPlay(2);
          } else {
            // toggle play/pause
            if (isPlaying) {
              pause();
            } else {
              play();
            }
          }
          event.preventDefault();
          break;

        default:
          break;
      }
    },
    [rewindAndPlay, isPlaying, play, pause]
  );

  React.useEffect(() => {
    // create copy because callback will have changed when cleanup is called
    const callbackCopy = handleKeyDown;

    window.addEventListener('keydown', callbackCopy);

    return () => window.removeEventListener('keydown', callbackCopy);
  }, [handleKeyDown]);

  // fetch doc when channelKey or docKey changes

  useEffect(() => {
    (async () => {
      // clear doc and page title
      setDoc(null);
      document.title = getPageTitle();

      if (docKey && user !== undefined) {
        setIsLoading(true);

        // fetch doc if not already loaded
        let doc = null;

        if (
          window.doc &&
          window.doc.metadata &&
          window.doc.metadata.docKey === window.location.pathname.substring(3)
        ) {
          // get doc json output by renderDocPreview cloud function
          doc = window.doc;
        } else {
          // load doc from db or storage
          if (user) {
            doc = await fetchDoc(docKey);
          } else {
            doc = await fetchDocAsGuest(docKey);
          }

          // clear window.doc
          window.doc = null;
        }

        setDoc(doc);

        if (user) {
          // restore exercise state
          const docProgress = await getUserDocProgress(user.uid, docKey);
          if (
            docProgress &&
            typeof docProgress.lastCompletedExerciseIndex === 'number'
          ) {
            setLastCompletedExerciseIndex(
              docProgress.lastCompletedExerciseIndex
            );
          }

          // track view in database
          trackDocView(user.uid, docKey);
        }

        // track view in firebase analytics
        firebase.analytics().logEvent('view_doc', { docKey });

        // reset multiple choice answers upon loading a doc
        resetAnswers();

        setIsLoading(false);

        // update title
        document.title = getPageTitle(doc && doc.metadata.title);

        // update channel key
        if (doc && doc.metadata.channelKey) {
          setChannelKey(doc.metadata.channelKey);
        }
      }
    })();
  }, [
    docKey,
    resetAnswers,
    setChannelKey,
    setDoc,
    setLastCompletedExerciseIndex,
    user,
  ]);

  // render

  const title = (metadata && metadata.title) || '';

  return (
    <React.Fragment>
      {(isLoading || !mediaIsLoaded || !wordAudioIsLoaded) && (
        <LinearProgress className="top-bar__progress" />
      )}

      {metadata && (
        <div className="doc">
          <StickyContainer>
            <MediaPlayer />
          </StickyContainer>

          <div id="doc__title">{title}</div>

          <DocInfo />

          <ChannelHeader showDetails />

          {playerBlocks &&
            playerBlocks.length > 0 &&
            playerBlocks[0].type !== blockTypes.heading && (
              <div className="heading-outer">
                <div className="heading-inner">Captions</div>
              </div>
            )}

          {playerBlocks.map((block, index) => {
            return (
              <BlockErrorBoundary key={block.key}>
                <Block
                  block={block}
                  index={index}
                  isPlaying={index === playingBlockIndex && isPlaying}
                  pause={pause}
                  playBlock={playBlock}
                  playingSegmentIndex={
                    index === playingBlockIndex ? playingSegmentIndex : null
                  }
                  roles={roles}
                  showAllTranslations={showAllTranslations}
                />
              </BlockErrorBoundary>
            );
          })}

          {keyVocabulary.length > 0 && <VocabSection />}

          <ExercisesSection />

          <BottomBar>
            {!showAllTranslations && isShowingTranslation && (
              <BottomBarTranslation />
            )}
            <BottomBarWatch />
          </BottomBar>
        </div>
      )}

      <AutoScroller />
    </React.Fragment>
  );
}
