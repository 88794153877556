import firebaseConfig from '../constants/firebaseConfig';
import firebaseConfigProduction from '../constants/firebaseConfigProduction';
import firebaseConfigStaging from '../constants/firebaseConfigStaging';

// import isLocal from './isLocal';
import isProduction from './isProduction';
import isStaging from './isStaging';

export default function getFirebaseConfig() {
  // stop using local db for now but leave the code
  // if (isLocal()) {
  //   return {
  //     ...firebaseConfig,
  //     databaseURL: `http://localhost:9000/?ns=${firebaseConfig.projectId}`,
  //   };
  // }

  if (isProduction()) {
    return firebaseConfigProduction;
  }

  if (isStaging()) {
    return firebaseConfigStaging;
  }

  return firebaseConfig;
}
