import { atom, selector } from 'recoil';

import editModes from '../constants/editModes';
import { metadataState, blocksState } from './doc';
import { fetchTrackList } from '../lib/youtubeApi';

// is editing

export const isEditingState = atom({
  key: 'isEditing',
  default: false,
});

// is saving

export const isSavingState = atom({
  key: 'isSaving',
  default: false,
});

// edit mode

export const editModeState = atom({
  key: 'editMode',
  default: editModes.text,
});

// edit block index

export const editBlockIndexState = atom({
  key: 'editBlockIndex',
  default: null,
});

// highlighted segment index

export const highlightedSegmentIndexState = atom({
  key: 'highlightedSegmentIndexState',
  default: null,
});

// block being edited

export const editBlockState = selector({
  key: 'editBlockState',
  get: ({ get }) => {
    const blocks = get(blocksState);
    const editBlockIndex = get(editBlockIndexState);
    return blocks && blocks[editBlockIndex];
  },
});

// caption tracks

export const captionTracksState = selector({
  key: 'captionTracksState',
  get: ({ get }) => {
    const metadata = get(metadataState);
    const mediaUrl = metadata && metadata.mediaUrl;
    return fetchTrackList(mediaUrl);
  },
});
