import { useEffect, useState } from 'react';

import { getAllChannelMetadata } from '../lib/core';

let cache = null;

export default function useAllChannelMetadata() {
  const [allChannelMetadata, setAllChannelMetadata] = useState(cache);

  useEffect(() => {
    (async () => {
      if (!cache) {
        const result = await getAllChannelMetadata();
        setAllChannelMetadata(result);
        cache = result;
      }
    })();
  }, [setAllChannelMetadata]);

  return allChannelMetadata;
}
