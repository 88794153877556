import firebase from 'firebase/app';
import getYoutubeId from 'get-youtube-id';
import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { metadataState } from '../hooks/doc';
import { isEditingState } from '../hooks/editor';
import {
  mediaInputState,
  mediaIsLoadedState,
  mediaOutputState,
  mediaStartTimeState,
  mustTapVideoState,
  useMediaInput,
  youtubeHasPlayedState,
} from '../hooks/player';
import { getAudioUrl, getVideoUrl } from '../lib/core';
import {
  globalGetCurrentTime,
  globalGetIsPlaying,
  globalPause,
  globalPlay,
  globalSetCurrentTime,
  globalSetPlaybackRate,
  setGlobalMediaPlayer,
} from '../lib/globalVideoPlayer';
import { playbackRateState } from '../state/settings';

// frequency for polling youtube player state (milliseconds)
const intervalTime = 50;

// iOS won't seek to exact times, so allow some slop to prevent endless seeking
const seekSlop = 0.1;

function MediaPlayer(props) {
  const { controls, onDurationKnown } = props;

  // recoil state

  const isEditing = useRecoilValue(isEditingState);
  const mediaStartTime = useRecoilValue(mediaStartTimeState);
  const mediaInput = useRecoilValue(mediaInputState);
  const metadata = useRecoilValue(metadataState);
  const mustTapVideo = useRecoilValue(mustTapVideoState);
  const setMediaIsLoaded = useSetRecoilState(mediaIsLoadedState);
  const setMediaOutput = useSetRecoilState(mediaOutputState);
  const setYoutubeHasPlayed = useSetRecoilState(youtubeHasPlayedState);
  const { play } = useMediaInput();
  const playbackRate = useRecoilValue(playbackRateState);

  // derived state

  const { audioKey, posterUrl, videoKey, youtubeUrl } = metadata || {};
  const MediaTagName = videoKey ? 'video' : 'audio';
  const youtubeId = getYoutubeId(youtubeUrl);

  // local state

  const [mediaUrl, setMediaUrl] = useState(null);

  const playerRef = React.useRef();
  const progressIntervalRef = React.useRef();

  // handlers

  function handleClick() {
    const isPlaying = !playerRef.current.paused;
    if (isPlaying) {
      globalPause();
    } else {
      globalPlay();
    }
  }

  function handleReady() {
    setGlobalMediaPlayer(playerRef.current);
    const isPlaying = globalGetIsPlaying();
    const currentTime = globalGetCurrentTime();
    setMediaOutput({ isPlaying, currentTime });

    if (onDurationKnown) {
      onDurationKnown(playerRef.current.duration);
    }
  }

  function handleLoaded() {
    setMediaIsLoaded(true);
  }

  function handleYoutubeReady(event) {
    playerRef.current = event.target;
    setGlobalMediaPlayer(playerRef.current);
    globalSetPlaybackRate(playbackRate ? playbackRate / 100 : 1);

    if (onDurationKnown) {
      onDurationKnown(playerRef.current.getDuration());
    }
  }

  async function handlePlay() {
    setMediaOutput(mediaOutput => ({
      ...mediaOutput,
      isPlaying: true,
    }));
    play(); // update mediaInput
    setYoutubeHasPlayed(true);

    // track play
    firebase.analytics().logEvent('play_media');
  }

  async function handlePause() {
    setMediaOutput(mediaOutput => ({
      ...mediaOutput,
      isPlaying: false,
    }));

    if (progressIntervalRef.current) {
      clearInterval(progressIntervalRef.current);
    }
  }

  // update mediaUrl when metadata changes

  useEffect(() => {
    (async () => {
      if (!youtubeId) {
        setMediaIsLoaded(false);

        if (videoKey) {
          setMediaUrl(await getVideoUrl(videoKey));
          return;
        } else if (audioKey) {
          setMediaUrl(await getAudioUrl(audioKey));
          return;
        }
      }
      setMediaIsLoaded(true);
      setMediaUrl(null);
    })();
  }, [audioKey, setMediaIsLoaded, videoKey, youtubeId]);

  // seek and play/pause as needed when mediaInput changes

  React.useEffect(() => {
    // don't allow youtube to play until user clicks the embed
    // so that youtube counts the view (unless editing)
    if (mustTapVideo && !isEditing) {
      return;
    }

    // keep global ref to player object to enable it to be
    // played in direct response to a user input, otherwise
    // Safari won't allow it as an initial play
    setGlobalMediaPlayer(playerRef.current);

    if (playerRef.current) {
      // clear interval

      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }

      // set interval if playing

      if (mediaInput.isPlaying) {
        // define handleProgress
        function handleProgress() {
          const currentTime = globalGetCurrentTime();

          // seek if before startTime
          if (
            typeof mediaInput.startTime === 'number' &&
            currentTime + seekSlop < mediaInput.startTime
          ) {
            globalSetCurrentTime(mediaInput.startTime);
          }

          // pause if past endTime
          if (mediaInput.endTime && currentTime >= mediaInput.endTime) {
            globalPause();
          }

          // update shared state
          setMediaOutput({ isPlaying: true, currentTime });
        }

        progressIntervalRef.current = setInterval(handleProgress, intervalTime);
      }

      // seek/play/pause as needed

      const isPlaying = globalGetIsPlaying();
      const currentTime = globalGetCurrentTime();

      // seek as needed
      if (typeof mediaInput.currentTime === 'number') {
        globalSetCurrentTime(mediaInput.currentTime);
      }

      // rewind as needed
      if (typeof mediaInput.rewindTime === 'number') {
        globalSetCurrentTime(currentTime - mediaInput.rewindTime);
      }

      // if paused and should be playing, play
      if (!isPlaying && mediaInput.isPlaying) {
        // play via Youtube/HTML API
        globalPlay();
      }

      // otherwise if should be paused
      else if (!mediaInput.isPlaying) {
        // pause via Youtube/HTML API as needed
        if (isPlaying) {
          globalPause();
        }
      }
    }

    return () => {
      if (progressIntervalRef.current) {
        clearInterval(progressIntervalRef.current);
      }
    };
  }, [isEditing, mediaInput, mustTapVideo, setMediaOutput]);

  // set playback rate on setting change

  useEffect(() => {
    if (playerRef.current) {
      globalSetPlaybackRate(playbackRate ? playbackRate / 100 : 1);
    }
  }, [mediaUrl, playbackRate]);

  // render

  const containerClass =
    videoKey || controls || posterUrl || youtubeId ? 'video-player' : null;
  const type = videoKey ? 'video/mp4' : 'audio/mp3';

  return (
    <div
      className={containerClass}
      onClick={!youtubeId ? handleClick : null}
      style={!videoKey ? { backgroundImage: `url(${posterUrl})` } : null}
    >
      {!youtubeId && (
        <MediaTagName
          controls={controls}
          disablePictureInPicture
          height="100%"
          onCanPlay={handleReady}
          onCanPlayThrough={handleLoaded}
          onEnded={handlePause}
          onPause={handlePause}
          onPlay={handlePlay}
          playsInline
          poster={posterUrl}
          preload="auto"
          ref={playerRef}
          src={mediaUrl}
          type={type}
          width="100%"
        />
      )}

      {youtubeId && (
        <YouTube
          containerClassName="video-player"
          onEnd={handlePause}
          onPause={handlePause}
          onPlay={handlePlay}
          onReady={handleYoutubeReady}
          opts={{
            height: '100%',
            playerVars: {
              fs: 0,
              iv_load_policy: 3,
              modestbranding: 1,
              playsinline: 1,
              rel: 0,
              start: mediaStartTime,
            },
            width: '100%',
          }}
          videoId={youtubeId}
        />
      )}
    </div>
  );
}

export default React.memo(MediaPlayer);
