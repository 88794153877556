import { shortKey } from './core';

export const createPron = pronOptions => {
  const newPron = {};
  pronOptions.forEach(option => {
    newPron[option] = '';
  });
  newPron.key = shortKey();
  return newPron;
};

export const createDef = pronOptions => {
  return {
    key: shortKey(),
    meaning: '',
    prons: pronOptions ? [createPron(pronOptions)] : null,
  };
};

export default function createWord(pronOptions) {
  return {
    defs: [createDef(pronOptions)],
  };
}
