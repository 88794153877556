import React from 'react';

import { getDefForKey } from '../../lib/core';

function getDefText(segment, word) {
  const { defKey } = segment;
  const def = getDefForKey(word, defKey);
  return def.meaning;
}

export default function MeaningChoice(props) {
  // props

  const { choice, isComplete, isSelected, onClick } = props;

  // derived state

  const defText = getDefText(choice.segment, choice.word);

  // render

  const choiceClass =
    'exercise__choice' + (isSelected ? ' exercise__choice--selected' : '');

  return (
    <div
      className={
        choiceClass +
        ' exercise__choice--meaning' +
        (isComplete ? ' exercise__choice--complete' : '')
      }
      onClick={onClick}
    >
      {defText}
    </div>
  );
}
