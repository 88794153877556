import React from 'react';
import { IconButton } from 'rmwc';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';

export default function AudioWaveform(props) {
  const { blob, children, id, initialRegionStart, onRegionChange, url } = props;
  const wavesurferRef = React.useRef();
  const startTime = React.useRef(0);
  const endTime = React.useRef(0);
  const [isPlaying, setIsPlaying] = React.useState(false);

  // handlers

  function handleTogglePlay() {
    if (isPlaying) {
      wavesurferRef.current.pause();
      setIsPlaying(false);
    } else {
      wavesurferRef.current.play(startTime.current, endTime.current);
      setIsPlaying(true);
    }
  }

  const handleFinish = React.useCallback(() => {
    setIsPlaying(false);
    wavesurferRef.current.setCurrentTime(startTime.current);
  }, []);

  // effects

  React.useLayoutEffect(() => {
    // create wavesurfer
    if (wavesurferRef.current) {
      wavesurferRef.current.destroy();
    }
    wavesurferRef.current = WaveSurfer.create({
      container: `#waveform-${id}`,
      height: 36,
      waveColor: 'rgba(0, 0, 0, 0.5)',
      progressColor: 'rgba(0, 0, 0, 0.5)',
      plugins: onRegionChange
        ? [
            RegionsPlugin.create({
              regions: [],
              dragSelection: false,
            }),
          ]
        : [],
    });

    // subscribe to events

    wavesurferRef.current.on('ready', () => {
      // set end time to duration
      startTime.current = initialRegionStart || 0;
      endTime.current = wavesurferRef.current.getDuration();

      // call onRegionChange if available to set endtime to duration
      if (onRegionChange) {
        onRegionChange(startTime.current, endTime.current);
      }

      // add default region from 0 to duration
      if (onRegionChange) {
        wavesurferRef.current.addRegion({
          start: startTime.current,
          end: endTime.current,
          loop: false,
          color: 'rgba(0, 0, 0, 0.25)',
        });
      }

      wavesurferRef.current.setCurrentTime(startTime.current);

      // reset playing state
      setIsPlaying(false);
    });

    wavesurferRef.current.on('pause', handleFinish);

    // handle region updates
    if (onRegionChange) {
      wavesurferRef.current.on('region-updated', region => {
        const { start, end } = region;

        startTime.current = start;
        endTime.current = end;
        wavesurferRef.current.setCurrentTime(start);
        wavesurferRef.current.play(start, end);
        onRegionChange(start, end);
      });
    }

    // load audio
    if (blob) {
      wavesurferRef.current.loadBlob(blob);
    } else if (url) {
      wavesurferRef.current.load(url);
    }

    return () => {
      if (wavesurferRef.current) {
        wavesurferRef.current.destroy();
      }
    };
  }, [blob, handleFinish, initialRegionStart, id, onRegionChange, url]);

  // render

  return (
    <div className="audio-waveform__container">
      <IconButton
        disabled={!blob && !url}
        icon={isPlaying ? 'stop' : 'play_arrow'}
        onClick={handleTogglePlay}
      />

      <div className="audio-waveform__waveform" id={`waveform-${id}`}>
        {children}
      </div>
    </div>
  );
}
