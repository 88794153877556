import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Link, { getTitle } from './Link';

export default function DraftLink(props) {
  const { metadata, onClick } = props;
  const { t } = useTranslation();
  const modified = dayjs(metadata.saveDate).format('YYYY-MM-DD h:mm A');

  let secondaryText = t(metadata.sourceLanguage);
  if (metadata.level) {
    secondaryText += ` · ${metadata.level}`;
  }
  if (metadata.targetLanguage !== metadata.sourceLanguage) {
    secondaryText += ` · ${t(metadata.targetLanguage)}`;
  }
  secondaryText += ` · ${modified}`;

  return (
    <Link
      id={metadata.docKey}
      onClick={onClick}
      primaryText={getTitle(metadata)}
      secondaryText={secondaryText}
      thumbnailUrl={metadata.thumbnailUrl}
    />
  );
}
