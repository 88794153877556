import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';

import DefEditorTooltip from './DefEditorTooltip';
import editModes from '../../constants/editModes';
import { blocksState } from '../../hooks/doc';
import { editModeState } from '../../hooks/editor';

function boundsFromRect(rect) {
  return {
    height: rect.height,
    left: rect.left,
    top: rect.top + window.pageYOffset - 8,
    width: rect.width,
  };
}

function getSelectionBounds() {
  const selection = window.getSelection();

  if (!selection.isCollapsed) {
    const rect = selection.getRangeAt(0).getBoundingClientRect();
    return boundsFromRect(rect);
  }
}

function getSelectionRange(blocks) {
  const selection = window.getSelection();

  if (selection.isCollapsed) {
    return null;
  }

  const { anchorNode, anchorOffset, focusNode, focusOffset } = selection;
  const anchorEl = anchorNode.parentElement;
  const focusEl = focusNode.parentElement;

  // first, make sure selection doesn't span multiple blocks
  const anchorBlockIndex = parseInt(anchorEl.dataset.blockIndex);
  const focusBlockIndex = parseInt(focusEl.dataset.blockIndex);
  if (isNaN(anchorBlockIndex) || anchorBlockIndex !== focusBlockIndex) {
    return null;
  }

  // easy case: anchor and focus are both within segments
  const anchorSegmentIndex = anchorEl.dataset.segmentIndex;
  const focusSegmentIndex = focusEl.dataset.segmentIndex;
  const anchorTextOffset = parseInt(anchorEl.dataset.textOffset);
  const focusTextOffset = parseInt(focusEl.dataset.textOffset);
  if (anchorSegmentIndex && focusSegmentIndex) {
    // get selection direction
    let isLeftToRight;
    const relativePosition = anchorNode.compareDocumentPosition(focusNode);
    if (relativePosition & Node.DOCUMENT_POSITION_FOLLOWING) {
      isLeftToRight = true;
    } else if (relativePosition & Node.DOCUMENT_POSITION_PRECEDING) {
      isLeftToRight = false;
    } else {
      isLeftToRight = anchorOffset < focusOffset;
    }

    let start;
    let end;
    if (isLeftToRight) {
      start = anchorTextOffset + anchorOffset;
      end = focusTextOffset + focusOffset;
    } else {
      start = focusTextOffset + focusOffset;
      end = anchorTextOffset + anchorOffset;
    }

    const text = blocks[anchorBlockIndex].segments
      .map(s => s.text)
      .join('')
      .substring(start, end);
    return {
      start,
      end,
      text,
    };
  }

  return null;
}

export default function DefEditorSelection(props) {
  // recoil state

  const blocks = useRecoilValue(blocksState);
  const editMode = useRecoilValue(editModeState);

  // local state

  const [isShowingTooltip, setIsShowingTooltip] = React.useState(false);
  const [text, setText] = useState('');
  const [bounds, setBounds] = useState({});
  const [range, setRange] = useState(null);

  // side effect: subscribe to selection changes

  React.useEffect(() => {
    if (editMode === editModes.segments) {
      // if in text editing mode, define handler and subscribe

      function handleSelectionChange() {
        const newBounds = getSelectionBounds();
        const range = getSelectionRange(blocks);

        if (newBounds && range) {
          // if we got bounds, show tooltip there
          setText(range.text);
          setBounds(newBounds);
          setRange(range);
          setIsShowingTooltip(true);
        } else {
          // otherwise hide tooltip
          setIsShowingTooltip(false);
          setRange(null);
        }
      }

      document.addEventListener('selectionchange', handleSelectionChange);
      document.addEventListener('click', handleSelectionChange);

      // remove event listeners on cleanup
      return () => {
        document.removeEventListener('selectionchange', handleSelectionChange);
        document.removeEventListener('click', handleSelectionChange);
      };
    }

    // hide tooltip if needed
    if (isShowingTooltip) {
      setIsShowingTooltip(false);
    }
  }, [blocks, isShowingTooltip, editMode]);

  // render

  return (
    <DefEditorTooltip open={isShowingTooltip} range={range} text={text}>
      <div className="draft-selection__selection" style={bounds} />
    </DefEditorTooltip>
  );
}
