import { callCloudFunction } from './core';
import getFirebaseConfig from './getFirebaseConfig';

const { storageBucket } = getFirebaseConfig();

export default async function syncTextToAudio(
  sourceLanguage,
  targetLanguage,
  isVideo,
  mediaKey,
  segments
) {
  // call cloud function

  const result = await callCloudFunction('syncTextToAudio', {
    storageBucket,
    sourceLanguage,
    targetLanguage,
    isVideo,
    mediaKey,
    segments,
  });

  return result;
}
