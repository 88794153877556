export function getGlobalMediaPlayer() {
  return window.globalMediaPlayer;
}

export function setGlobalMediaPlayer(newPlayer) {
  window.globalMediaPlayer = newPlayer;
}

export function globalSetPlaybackRate(rate) {
  if (window.globalMediaPlayer.setPlaybackRate) {
    window.globalMediaPlayer.setPlaybackRate(rate);
  } else {
    window.globalMediaPlayer.playbackRate = rate;
  }
}

export function globalPause() {
  if (window.globalMediaPlayer.pauseVideo) {
    window.globalMediaPlayer.pauseVideo();
  } else {
    window.globalMediaPlayer.pause();
  }
}

export function globalPlay() {
  if (window.globalMediaPlayer.playVideo) {
    window.globalMediaPlayer.playVideo();
  } else {
    window.globalMediaPlayer.play();
  }
}

export function globalGetIsPlaying() {
  if (window.globalMediaPlayer.getPlayerState) {
    // https://developers.google.com/youtube/iframe_api_reference#Playback_status
    return window.globalMediaPlayer.getPlayerState() === 1;
  } else {
    return !window.globalMediaPlayer.paused;
  }
}

export function globalGetCurrentTime() {
  if (window.globalMediaPlayer.getPlayerState) {
    return window.globalMediaPlayer.getCurrentTime();
  } else {
    return window.globalMediaPlayer.currentTime;
  }
}

export function globalSetCurrentTime(time) {
  if (window.globalMediaPlayer.getPlayerState) {
    return window.globalMediaPlayer.seekTo(time, true);
  } else {
    return (window.globalMediaPlayer.currentTime = time);
  }
}
