import FileType from 'file-type/browser';
import hash from 'object-hash';
import shortid from 'shortid';

import {
  calcFileMd5,
  callCloudFunction,
  getStorageUrl,
  uploadStorageObject,
  uploadStoragePath,
} from './utils';

// paths

export function audioStoragePath(mediaKey) {
  return `audio/${mediaKey}`;
}

export function videoStoragePath(mediaKey) {
  return `video/${mediaKey}`;
}

// getters

export function getAudioUrl(mediaKey) {
  return getStorageUrl(audioStoragePath(mediaKey));
}

export function getVideoUrl(mediaKey) {
  return getStorageUrl(videoStoragePath(mediaKey));
}

export function getTtsAudioKey(voiceName, speakingRate, text) {
  return 'tts-' + hash(voiceName + speakingRate + text);
}

// upload

export function saveTts(voiceName, speakingRate, text) {
  const audioKey = getTtsAudioKey(voiceName, speakingRate, text);

  return (
    // call cloud function to process upload
    callCloudFunction('ttsSave', { voiceName, speakingRate, text })
      // return audio url
      .then(() => getAudioUrl(audioKey))
  );
}

export async function uploadMedia(file) {
  const md5 = await calcFileMd5(file);

  const fileType = await FileType.fromBlob(file);

  if (
    !fileType.mime.startsWith('video') &&
    !fileType.mime.startsWith('audio') &&
    !fileType.mime.startsWith('image')
  ) {
    throw new Error('Not a recognized media file type');
  }

  await uploadStorageObject(uploadStoragePath(md5), file, {
    contentType: fileType.mime,
  });

  return { md5, fileType };
}

export async function uploadRecording(data, startTime, endTime) {
  const audioKey = shortid.generate();

  // upload wav to uploads dir
  await uploadStorageObject(uploadStoragePath(audioKey), data, {
    contentType: 'audio/wav',
  });

  // call cloud function to process upload
  await callCloudFunction('processRecording', { audioKey, startTime, endTime });

  // return audio url
  return getAudioUrl(audioKey);
}
