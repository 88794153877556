import React from 'react';
import { Icon } from 'rmwc';

export default function AudioChoice(props) {
  // props

  const { isComplete, isSelected, onClick } = props;

  // render

  const choiceClass =
    'exercise__choice exercise__choice--audio' +
    (isSelected ? ' exercise__choice--selected' : '');

  return (
    <div
      className={
        choiceClass + (isComplete ? ' exercise__choice--complete' : '')
      }
      onClick={onClick}
    >
      <Icon icon="volume_up" />
    </div>
  );
}
