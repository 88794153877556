import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { currentUserState } from '../state/auth';
import { channelKeyState, isSubscribedState } from '../state/channel';
import { getSubscription } from '../lib/core';

// updates isSubscribed whenever channelKey or user changes
export default function useIsSubscribed() {
  const user = useRecoilValue(currentUserState);
  const channelKey = useRecoilValue(channelKeyState);
  const setIsSubscribed = useSetRecoilState(isSubscribedState);

  useEffect(() => {
    (async () => {
      if (channelKey && user) {
        setIsSubscribed(Boolean(await getSubscription(user.uid, channelKey)));
      } else {
        setIsSubscribed(false);
      }
    })();
  }, [channelKey, setIsSubscribed, user]);

  return null;
}
