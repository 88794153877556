import produce from 'immer';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { LinearProgress } from 'rmwc';

import MediaPlayer from './MediaPlayer';
import { metadataState } from '../hooks/doc';
import captureException from '../lib/captureException';
import {
  getStorageUrl,
  imageStoragePath,
  thumbnailStoragePath,
  uploadMedia,
} from '../lib/core';
import processImage from '../lib/processImage';
import processMedia from '../lib/processMedia';
import { getYoutubePosterUrl, getYoutubeThumbnailUrl } from '../lib/youtubeApi';
import './MediaEditor.css';

function MediaEditor() {
  // recoil state

  const [metadata, setMetadata] = useRecoilState(metadataState);

  // local state

  const [isProcessing, setIsProcessing] = useState(false);
  const [processingStatus, setProcessingStatus] = useState(null);

  // derived state

  const { audioKey, videoKey, youtubeUrl } = metadata || {};
  const hasMedia = Boolean(audioKey || videoKey || youtubeUrl);

  // handlers

  function handleDragOver(event) {
    // prevent default browser behavior (opening file)
    event.preventDefault();
  }

  async function handleDrop(event) {
    // prevent default browser behavior (opening file)
    event.preventDefault();

    // get file from dataTransfer.items or .files (may vary by browser)
    const file = event.dataTransfer.items
      ? event.dataTransfer.items[0].getAsFile()
      : event.dataTransfer.files[0];

    try {
      // upload

      setProcessingStatus('Uploading');
      setIsProcessing(true);

      const {
        md5: mediaKey,
        fileType: { mime },
      } = await uploadMedia(file);

      // process

      const isImage = mime.startsWith('image');
      const isVideo = mime.startsWith('video');
      let posterUrl;
      let thumbnailUrl;

      setProcessingStatus('Processing');

      if (isImage) {
        await processImage(mediaKey, true); // thumbnail=true
      } else {
        await processMedia(isVideo, mediaKey);
      }

      // get image urls

      if (isImage || isVideo) {
        posterUrl = await getStorageUrl(imageStoragePath(mediaKey));
        thumbnailUrl = await getStorageUrl(thumbnailStoragePath(mediaKey));
      }

      // update recoil state

      setMetadata(metadata =>
        produce(metadata, draftMetadata => {
          if (!isImage) {
            if (isVideo) {
              delete draftMetadata.audioKey;
              draftMetadata.videoKey = mediaKey;
            } else {
              delete draftMetadata.videoKey;
              draftMetadata.audioKey = mediaKey;
            }
          }

          if (posterUrl) {
            draftMetadata.posterUrl = posterUrl;
          }
          if (thumbnailUrl) {
            draftMetadata.thumbnailUrl = thumbnailUrl;
          }
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setProcessingStatus(null);
      setIsProcessing(false);
    }
  }

  function handleYoutubeUrlChange(event) {
    setMetadata(metadata =>
      produce(metadata, draftMetadata => {
        draftMetadata.youtubeUrl = event.target.value;
        // set poster (used in link previews)
        const posterUrl = getYoutubePosterUrl(draftMetadata.youtubeUrl);
        if (posterUrl) {
          draftMetadata.posterUrl = posterUrl;
        }
        // set thumbnail
        const thumbnailUrl = getYoutubeThumbnailUrl(draftMetadata.youtubeUrl);
        if (thumbnailUrl) {
          draftMetadata.thumbnailUrl = thumbnailUrl;
        }
      })
    );
  }

  function handleDurationKnown(duration) {
    if (duration !== metadata.duration) {
      setMetadata(metadata =>
        produce(metadata, draftMetadata => {
          draftMetadata.duration = duration;
        })
      );
    }
  }

  // render

  return (
    <div
      className="media-editor"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      {hasMedia && !isProcessing && (
        <MediaPlayer controls onDurationKnown={handleDurationKnown} />
      )}

      {(!hasMedia || isProcessing) && (
        <div className="media-editor__content media">
          <div className="media-editor__row">
            {!processingStatus && 'Drag & drop audio/video/image file here'}

            {processingStatus}
          </div>
        </div>
      )}

      <input
        className="media-editor__youtube-url"
        onChange={handleYoutubeUrlChange}
        placeholder="Youtube link (optional)"
        type="text"
        value={youtubeUrl}
      />

      {isProcessing && <LinearProgress className="media-editor__progress" />}
    </div>
  );
}

export default React.memo(MediaEditor);
