import produce from 'immer';
import React, { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';

import { onClickBlank } from './BlockText';
import { onClickSegment } from './def/DefTooltip';
import { blocksState } from '../hooks/doc';

function ExercisesToolbar() {
  // recoil state

  const setBlocks = useSetRecoilState(blocksState);

  // handle segment clicks

  useEffect(() => {
    function toggleBlank(blockIndex, segmentIndex, segment) {
      // update blocks in recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks[blockIndex].segments[
            segmentIndex
          ].isBlank = !segment.isBlank;
        })
      );
    }

    onClickBlank(toggleBlank);
    onClickSegment(toggleBlank);

    return () => onClickSegment(null);
  }, [setBlocks]);

  // render

  return null;
}

export default React.memo(ExercisesToolbar);
