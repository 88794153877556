import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';

import { playerBlocksState, useMediaInput } from './player';
import blockTypes from '../constants/blockTypes';

export const studyState = atom({
  key: 'studyState',
  default: {
    studyIndex: 0,
    isStudyIndexFocused: false,
    didAnswerQuestion: false,
  },
});

export const canStudyNextState = selector({
  key: 'canStudyNextState',
  get: ({ get }) => {
    const playerBlocks = get(playerBlocksState);
    const { studyIndex, didAnswerQuestion } = get(studyState);

    // check whether current index is a question
    // that has not been answered
    const blockType = playerBlocks[studyIndex] && playerBlocks[studyIndex].type;
    const isQuestion = blockType === blockTypes.multipleChoice;
    const isUnansweredQuestion = isQuestion && !didAnswerQuestion;

    // can study next if we haven't reached the end
    // and we aren't on an unanswered question
    return studyIndex < playerBlocks.length - 1 && !isUnansweredQuestion;
  },
});

export const studyBlockState = selector({
  key: 'studyBlockState',
  get: ({ get }) => {
    const { studyIndex } = get(studyState);
    const playerBlocks = get(playerBlocksState);
    return playerBlocks[studyIndex];
  },
});

export function useStudy() {
  const [study, setStudy] = useRecoilState(studyState);
  const playerBlocks = useRecoilValue(playerBlocksState);
  const { playBlock } = useMediaInput();

  // study next block

  function studyNext() {
    const nextIndex = study.studyIndex + 1;
    if (nextIndex < playerBlocks.length) {
      setStudy({
        studyIndex: nextIndex,
        isStudyIndexFocused: true,
        didAnswerQuestion: false,
      });
      playBlock(nextIndex);
    }
  }

  // answer question at studyIndex

  function answerQuestion() {
    setStudy(study => ({
      ...study,
      isStudyIndexFocused: true,
      didAnswerQuestion: true,
    }));
  }

  // export

  return {
    study,
    setStudy,
    studyNext,
    answerQuestion,
  };
}
