import firebase from 'firebase/app';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import {
  Button,
  CircularProgress,
  LinearProgress,
  List,
  TabBar,
  Tab,
} from 'rmwc';

import ChannelHeader from './ChannelHeader';
import DocLink from './DocLink';
import DraftLink from './DraftLink';
import useChannelDocs from '../hooks/useChannelDocs';
import useChannelDrafts from '../hooks/useChannelDrafts';
import { channelMetadataState } from '../state/channel';
import {
  channelKeyState,
  isChannelAdminState,
  isEditingChannelState,
} from '../state/channel';
import captureException from '../lib/captureException';
import {
  channelBannerStoragePath,
  createDocKey,
  getStorageUrl,
  setChannelBannerUrl,
  uploadMedia,
} from '../lib/core';
import processChannelImage from '../lib/processChannelImage';

import './Channel.css';

export default function Channel(props) {
  // recoil state

  const channelKey = useRecoilValue(channelKeyState);
  const isEditingChannel = useRecoilValue(isEditingChannelState);
  const isChannelAdmin = useRecoilValue(isChannelAdminState);
  const channelMetadata = useRecoilValue(channelMetadataState);
  const docs = useChannelDocs(channelKey);
  const drafts = useChannelDrafts(isChannelAdmin, channelKey);

  // local state

  const [activeTab, setActiveTab] = useState(0);
  const [isProcessingBanner, setIsProcessingBanner] = useState(false);

  // derived state

  const { bannerUrl } = channelMetadata || {};

  // other hooks

  const history = useHistory();

  // track channel page view
  useEffect(() => {
    if (channelKey) {
      firebase.analytics().logEvent('view_channel', { channelKey });
    }
  }, [channelKey]);

  // handlers

  function handleClickCreate() {
    history.push(`/draft/${channelKey}/${createDocKey()}`);
  }

  function handleClickDoc(metadata) {
    const { docKey, slug } = metadata;
    history.push(`/l/${docKey}` + (slug ? '/' + slug : ''));
  }

  function handleClickDraft(metadata) {
    history.push(`/draft/${channelKey}/${metadata.docKey}`);
  }

  // banner handlers

  async function handleBannerFileSelect(event) {
    if (this.files.length > 0) {
      const file = this.files[0];

      try {
        setIsProcessingBanner(true);

        // upload

        const { md5: uploadKey } = await uploadMedia(file);

        // process

        await processChannelImage(channelKey, uploadKey, true);

        // get image url

        const newImageUrl = await getStorageUrl(
          channelBannerStoragePath(channelKey)
        );

        // write to channelMetadata db

        await setChannelBannerUrl(channelKey, newImageUrl);
      } catch (error) {
        captureException(error);
      } finally {
        setIsProcessingBanner(false);
      }
    }
  }

  function handleUploadBanner() {
    const fileEl = document.getElementById('banner-file');
    if (fileEl) {
      fileEl.addEventListener('change', handleBannerFileSelect, false);
      fileEl.click();
    }
  }

  // render

  return (
    <div className="channel">
      <div
        className="channel__banner"
        style={{ backgroundImage: `url(${bannerUrl})` }}
      >
        {isEditingChannel && (
          <React.Fragment>
            <Button
              className="channel__banner-upload-button"
              disabled={isProcessingBanner}
              icon={isProcessingBanner ? <CircularProgress /> : 'upload'}
              label="Upload banner"
              onClick={handleUploadBanner}
              outlined
            />
            <input
              className="channel__file-input"
              id="banner-file"
              accept="image/*"
              type="file"
            />
          </React.Fragment>
        )}
      </div>

      {!docs && <LinearProgress className="top-bar__progress" />}

      <div className="doc">
        <ChannelHeader canEdit showDetails />

        {isChannelAdmin && (
          <TabBar
            activeTabIndex={activeTab}
            className="channel__tab-bar"
            onActivate={evt => setActiveTab(evt.detail.index)}
          >
            <Tab className="channel__tab">Published</Tab>
            <Tab className="channel__tab">Drafts</Tab>
          </TabBar>
        )}

        {activeTab === 0 && docs && (
          <List twoLine>
            {docs.map(metadata => (
              <div className="link-outer" key={metadata.docKey}>
                <DocLink
                  metadata={metadata}
                  onClick={() => handleClickDoc(metadata)}
                />
              </div>
            ))}
          </List>
        )}

        {activeTab === 1 && isChannelAdmin && drafts && (
          <React.Fragment>
            <Button
              icon="add"
              id="drafts__create-button"
              onClick={handleClickCreate}
              outlined
            />

            <List twoLine>
              {drafts.map(metadata => (
                <div className="link-outer" key={metadata.docKey}>
                  <DraftLink
                    metadata={metadata}
                    onClick={() => handleClickDraft(metadata)}
                  />
                </div>
              ))}
            </List>
          </React.Fragment>
        )}
      </div>
    </div>
  );
}
