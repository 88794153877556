import AudioRecorderPolyfill from 'audio-recorder-polyfill';
import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from 'rmwc';

window.MediaRecorder = AudioRecorderPolyfill;

async function requestMicStream() {
  return await navigator.mediaDevices.getUserMedia({ audio: true });
}

function stopMicStream(stream) {
  if (stream) {
    stream.getTracks().forEach(track => track.stop());
  }
}

export default function AudioRecorder(props) {
  const { onChange } = props;

  // state

  const micStreamRef = useRef(null);
  const recorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);

  // handlers

  async function handleStartRecording() {
    // create recorder
    recorderRef.current = new window.MediaRecorder(micStreamRef.current, {
      mimeType: 'audio/wav',
    });

    // handle data available when recording is stopped
    recorderRef.current.addEventListener('dataavailable', e => {
      // run update callback from props
      onChange(e.data);
    });

    setIsRecording(true);

    // start recording
    recorderRef.current.start();
  }

  async function handleStopRecording() {
    // stop recording
    recorderRef.current.stop();

    // remove “recording” icon from browser tab
    recorderRef.current.stream.getTracks().forEach(i => i.stop());

    // update UI
    setIsRecording(false);

    // reset mic stream
    stopMicStream(micStreamRef.current);
    micStreamRef.current = await requestMicStream();
  }

  // get mic stream on component mount

  useEffect(() => {
    (async () => {
      micStreamRef.current = await requestMicStream();
    })();

    return () => {
      stopMicStream(micStreamRef.current);
    };
  }, []);

  // render

  return (
    <div className="audio-recorder">
      {!isRecording && (
        <IconButton icon="fiber_manual_record" onClick={handleStartRecording} />
      )}

      {isRecording && <IconButton icon="stop" onClick={handleStopRecording} />}
    </div>
  );
}
