import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // https://www.i18next.com/overview/configuration-options
  .init({
    resources: {
      en: {
        translation: {
          // AudioSelector
          'audio-selector__cancel-recording': 'Cancel',
          'audio-selector__record-new-audio': 'Record new audio',
          'audio-selector-tts': 'Computer generated',

          // Auth
          login: 'Login',
          sendLoginCode: 'Send Sign In Code',
          enterEmailAgainToFinishLogin:
            'Please enter your email again to finish login',
          sentLoginCode:
            'We sent you a 4-digit sign in code. Please check your email.',
          sentLoginLink: 'We sent you a sign in link. Please check your email.',

          // CreateDraftDialog
          createDoc: 'Create a New Doc',

          // Drawer
          yourAccount: 'Your Account',
          assignments: 'Assignments',
          drafts: 'Drafts',
          docUpdates: 'Recently published',
          settings: 'Settings',

          // Draft
          mustLoginToViewDraft: 'Must sign in to view doc draft',
          title: 'Title',
          selectTemplate: 'Confirm Template',
          selectLanguages:
            'Please select source and translation languages above.',
          changeImage: 'Change image',
          backToResults: 'Back to search results',
          changeMedia: 'Change media',
          startAtZero: 'Start at 0:00',
          startOnFirstCaption: 'Start on first caption',
          pronunciation: 'Pronunciation',
          notes: 'Notes',
          translation: 'Translation',
          addRole: 'Add Role',
          removeRole: 'Remove Role',
          roleName: 'Role Name',
          noRole: 'Clear Role',
          publish: 'Publish',
          listDocVersions: 'Show Version History',
          viewVersion: 'View Version',
          deleteDraft: 'Delete Draft',
          currentAudio: 'Current audio',
          recordAudio: 'Record new audio',
          save: 'Save',

          // Drafts
          yourDrafts: 'Your drafts',

          // EditorTranslationLinks
          translationLinks: 'Translation Links',

          // Home
          welcome: 'Welcome, {{email}}',

          // Link
          metadataChanged: 'Metadata changed',
          blocksAdded: '{{count}} blocks added',
          blocksChanged: '{{count}} blocks changed',
          blocksRemoved: '{{count}} blocks removed',
          rolesAdded: '{{count}} roles added',
          rolesChanged: '{{count}} roles changed',
          rolesRemoved: '{{count}} roles removed',

          // Settings
          // settings: 'Settings',
          interfaceLanguage: 'Interface language',
          youHaveDeletedDocs: 'You have {{count}} deleted doc',
          youHaveDeletedDocs_plural: 'You have {{count}} deleted docs',
          view: 'View',
          signedInAs: 'Signed in as {{email}}',
          logout: 'Sign Out',

          // UserAssignments
          yourAssignments: 'Your Assignments',

          // doc description
          selectSourceLanguage: 'Select source language',
          selectTranslationLanguage: 'Select translation language',
          languageLesson: '{{language}} lesson',
          selectLevel: 'Select level',
          translationsIn: '{{language}} translations',

          // common
          accept: 'Accept',
          cancel: 'Cancel',

          // languages
          ar: 'Arabic',
          cs: 'Czech',
          da: 'Danish',
          de: 'German',
          el: 'Greek',
          en: 'English',
          es: 'Spanish',
          fil: 'Filipino',
          fr: 'French',
          ga: 'Irish',
          he: 'Hebrew',
          hi: 'Hindi',
          hu: 'Hungarian',
          id: 'Indonesian',
          ja: 'Japanese',
          ko: 'Korean',
          nl: 'Dutch',
          no: 'Norwegian',
          po: 'Polish',
          pt: 'Portuguese',
          ro: 'Romanian',
          ru: 'Russian',
          sv: 'Swedish',
          th: 'Thai',
          tr: 'Turkish',
          uk: 'Ukrainian',
          vi: 'Vietnamese',
          zh: 'Mandarin',

          // docTypes
          video: 'Video',
          dialogue: 'Dialogue',
          reading: 'Reading',
          word: 'Word',
          grammar: 'Grammar',
          list: 'Doc List',

          // buildDocTemplate
          vocabulary: 'Vocabulary',
          writingPractice: 'Writing Practice',
          speakingPractice: 'Speaking Practice',
          meaning: 'Meaning',
          examples: 'Examples',
          otherMeanings: 'Other Meanings',
          relatedWords: 'Related Words',
          relatedDocs: 'Related Docs',
          function: 'Function',
          usage: 'Usage',
          practice: 'Practice',
          listIntro: 'Introduction. Should explain list focus and scope.',
          // filler
          templateCaption: 'This is a caption.',
          templateExample: 'This is an example.',
          templateDialogueLine: 'This is a dialogue line.',
          templateSentence: 'This is a sentence.',
          templatePracticeLine: 'This is a practice line.',
          templateExplanation: 'This is an explanation.',
          templateQuestion: 'This is a question.',
          templatePronunciation: 'Pronunciation',
          templateMeaning: 'Meaning and usage notes',
          templateLink: '[Link]',

          // colors
          red: 'Red',
          pink: 'Pink',
          purple: 'Purple',
          deepPurple: 'Deep purple',
          indigo: 'Indigo',
          blue: 'Blue',
          lightBlue: 'Light blue',
          cyan: 'Cyan',
          teal: 'Teal',
          green: 'Green',
          lightGreen: 'Light green',
          lime: 'Lime',
          yellow: 'Yellow',
          amber: 'Amber',
          orange: 'Orange',
          deepOrange: 'Deep orange',
        },
      },
      'zh-Hant': {
        translation: {
          // AudioSelector
          'audio-selector__cancel-recording': 'Cancel',
          'audio-selector__record-new-audio': 'Record new audio',
          'audio-selector-tts': 'Computer generated',

          // DraftTopBar
          'draft-top-bar__done': '完成',
          'draft-top-bar__publish': '發布',

          // Auth
          login: '登陸',
          sendLoginCode: '發送登錄代碼',
          enterEmailAgainToFinishLogin: '請再次輸入您的電子郵件以完成登錄',
          sentLoginCode:
            '我們向您發送了一個四位數的登錄代碼。 請查看你的郵箱。',
          sentLoginLink: '我們向您發送了一個登錄鏈接。 請查看你的郵箱。',

          // CreateDraftDialog
          createDoc: '創建新課程',

          // Draft
          mustLoginToViewDraft: '必須登錄才能查看課程草稿',
          title: '標題',
          selectTemplate: '確認模板',
          selectLanguages: '請在上方選擇源語言和翻譯語言。',
          changeImage: '更改圖片',
          backToResults: '返回搜索結果',
          changeMedia: '更改媒體',
          startAtZero: '從0:00開始',
          startOnFirstCaption: '從第一個字幕開始',
          pronunciation: '發音',
          translation: '翻譯',
          notes: '註釋',
          addRole: '添加角色',
          removeRole: '取消角色',
          roleName: '角色名稱',
          noRole: '清除角色',
          publish: '發布',
          listDocVersions: '顯示版本歷史',
          viewVersion: '查看版本',
          deleteDraft: '刪除草稿',
          currentAudio: '當前音頻',
          recordAudio: '錄製新音頻',
          save: '保存',

          // Drafts
          yourDrafts: '您的草稿',

          // Drawer
          yourAccount: '您的帳戶',
          assignments: '作業',
          drafts: '草稿',
          docUpdates: '最近發表',
          settings: '設定',

          // EditorTranslationLinks
          translationLinks: '翻譯鏈接',

          // Home
          welcome: '歡迎,{{email}}',

          // Link
          metadataChanged: '元數據已更改',
          blocksAdded: '增加了{{count}}個塊',
          blocksChanged: '已更改{{count}}個塊',
          blocksRemoved: '已刪除{{count}}個區塊',
          rolesAdded: '已添加{{count}}個角色',
          rolesChanged: '已更改{{count}}個角色',
          rolesRemoved: '已刪除{{count}}個角色',

          // Settings
          // settings: '設定',
          interfaceLanguage: '界面語言',
          signedInAs: '您以 {{email}} 身份登錄',
          logout: '登出',

          // UserAssignments
          yourAssignments: '您的作業',

          // doc description
          selectSourceLanguage: '選擇源語言',
          selectTranslationLanguage: '選擇翻譯語言',
          languageLesson: '{{language}}課',
          selectLevel: '選擇語言等級',
          translationsIn: '{{language}}翻譯',

          // common
          accept: '接受',
          cancel: '取消',

          // languages
          ar: '阿拉伯語',
          cs: '捷克語',
          da: '丹麥語',
          de: '德語',
          el: '希臘語',
          en: '英語',
          es: '西班牙語',
          fil: '菲律賓',
          fr: '法語',
          ga: '愛爾蘭人',
          he: '希伯來語',
          hi: '印地語',
          hu: '匈牙利語',
          id: '印尼語',
          ja: '日語',
          ko: '韓文',
          nl: '荷蘭語',
          no: '挪威語',
          po: '波蘭語',
          pt: '葡萄牙語',
          ro: '羅馬尼亞語',
          ru: '俄語',
          sv: '瑞典語',
          th: '泰語',
          tr: '土耳其語',
          uk: '烏克蘭語',
          vi: '越南語',
          zh: '國語',

          // docTypes
          video: '影片',
          dialogue: '對話',
          reading: '閱讀',
          word: '生詞',
          grammar: '語法',
          list: '課程清單',

          // buildDocTemplate
          vocabulary: '詞彙',
          writingPractice: '寫作實踐',
          speakingPractice: '口語練習',
          meaning: '含義',
          examples: '示例',
          otherMeanings: '其他含義',
          relatedWords: '相關詞',
          relatedDocs: '相關課程',
          function: '功能',
          usage: '用法',
          practice: '練習',
          listIntro: '簡介。應該說明列表的重點和範圍。',
          // filler
          templateCaption: '這是字幕。',
          templateExample: '這是一個示例。',
          templateDialogueLine: '這是一個對話行。',
          templateSentence: '這是一個句子。',
          templatePracticeLine: '這是練習行。',
          templateExplanation: '這是一個解釋。',
          templateQuestion: '這是一個問題。',
          templatePronunciation: '發音',
          templateMeaning: '含義和使用說明',
          templateLink: '[鏈接]',

          // colors
          red: '紅色',
          pink: '粉紅色',
          purple: '紫色',
          deepPurple: '深紫色',
          indigo: '靛藍',
          blue: '藍色',
          lightBlue: '淺藍色',
          cyan: '青色',
          teal: '藍綠色',
          green: '綠色',
          lightGreen: '淺綠色',
          lime: '青檸',
          yellow: '黃色',
          amber: '琥珀色',
          orange: '橙色',
          deepOrange: '深橙色',
        },
      },
    },

    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
