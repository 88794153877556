import { useEffect, useState } from 'react';

import { listChannelDocs } from '../lib/core';

// updates docs list whenever channelKey changes
export default function useChannelDocs(channelKey) {
  const [docs, setDocs] = useState(null);

  useEffect(() => {
    (async () => {
      if (channelKey) {
        const docs = await listChannelDocs(channelKey);
        const listedDocs = docs.filter(doc => !doc.isUnlisted);
        setDocs(listedDocs);
      } else {
        setDocs(null);
      }
    })();
  }, [channelKey, setDocs]);

  return docs;
}
