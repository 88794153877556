import { shortKey } from './core';
import blockTypes from '../constants/blockTypes';

export default function createDraft(channelKey, docKey) {
  return {
    metadata: {
      channelKey,
      docKey,
      title: 'New Lesson',
      sourceLanguage: 'zh',
      targetLanguage: 'en',
    },
    blocks: [
      {
        key: shortKey(),
        segments: [{ text: 'New line' }],
        type: blockTypes.paragraph,
        hasAudio: true,
      },
    ],
  };
}
