import editDistance from 'edit-distance';

export function cleanSpacing(html) {
  return html.replace(/&nbsp;/g, ' ').replace(/ +(?= )/g, '');
}

export function htmlToSegments(el) {
  return [{ text: el.textContent.trim() }];
}

export function segmentsToHtml(segments) {
  return segments.map(s => s.text).join('');
}

function getCharPosToSegmentIndexArray(segments) {
  const charPosToIndex = [];
  let charPos = 0;

  segments.forEach((segment, segmentIndex) => {
    segment.text.split('').forEach(char => {
      charPosToIndex[charPos++] = segmentIndex;
    });
  });

  return charPosToIndex;
}

// either adds text as new segment or appends to previous segment text
// if previous segment only has text and no other props (hasDefs, startTime)
function pushText(segments, text) {
  // if no segments, then push text as a new segment
  if (segments.length === 0) {
    segments.push({ text });
  }

  // if there are segments
  else {
    const lastSegment = segments[segments.length - 1];
    const props = Object.keys(lastSegment);
    // if last segment only has text prop, append text
    if (props.length === 1 && props[0] === 'text') {
      // must create new segment object because it's immutable
      segments[segments.length - 1] = { text: lastSegment.text + text };
    }
    // otherwise push text as a new segment
    else {
      segments.push({ text });
    }
  }
}

export function mergeAdjacentTextSegments(segments) {
  const newSegments = [];
  let buffer = '';

  segments.forEach(segment => {
    // if segment only has text prop, append text to buffer
    const props = Object.keys(segment);
    if (props.length === 1 && props[0] === 'text') {
      buffer += segment.text;
    }

    // otherwise flush buffer and push segment
    else {
      if (buffer) {
        pushText(newSegments, buffer);
        buffer = '';
      }
      newSegments.push(segment);
    }
  });

  // flush buffer if not empty
  if (buffer) {
    pushText(newSegments, buffer);
  }

  return newSegments;
}

export function updateSegments(segments, text) {
  // define cost functions
  var insert, remove, update;
  insert = remove = function (node) {
    return 1;
  };
  update = function (stringA, stringB) {
    return stringA !== stringB ? 1 : 0;
  };

  // Compute edit distance, mapping, and alignment.

  const joinedSegments = segments.map(segment => segment.text).join('');

  const lev = editDistance.levenshtein(
    joinedSegments,
    text,
    insert,
    remove,
    update
  );

  // create mapping of char positions to segment indices
  const charPosToSegmentIndexArray = getCharPosToSegmentIndexArray(segments);

  const newSegments = [];
  let segmentCharPos = 0;
  let textCharPos = 0;
  let buffer = '';

  // unreverse pairs because they are reversed by default
  const unreversedPairs = lev.pairs().reverse();

  unreversedPairs.forEach(([segmentChar, textChar]) => {
    const segmentIndex = charPosToSegmentIndexArray[segmentCharPos];
    const currentSegment = segments[segmentIndex];

    if (textChar) {
      // add char to buffer
      buffer += textChar;

      if (currentSegment && buffer === currentSegment.text) {
        // if buffer equals segment, push segment
        newSegments.push(currentSegment);
        buffer = '';
      } else if (currentSegment && buffer.endsWith(currentSegment.text)) {
        // if buffer ends with segment, push preceeding text
        const nonSegmentText = buffer.substring(
          0,
          buffer.length - currentSegment.text.length
        );
        newSegments.push({ text: nonSegmentText });
        // then push segment
        newSegments.push(currentSegment);
        buffer = '';
      }
    }

    // increment char positions
    if (segmentChar) {
      segmentCharPos++;
    }
    if (textCharPos) {
      textCharPos++;
    }
  });

  // if buffer isn't empty, push text
  if (buffer) {
    newSegments.push({ text: buffer });
  }

  // merge adjacent text segments
  const mergedSegments = mergeAdjacentTextSegments(newSegments);

  return mergedSegments;
}
