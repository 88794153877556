export default {
  hsk: ['HSK1', 'HSK2', 'HSK3', 'HSK4', 'HSK5', 'HSK6'],
  'hsk-chars': ['HSK1', 'HSK2', 'HSK3', 'HSK4', 'HSK5', 'HSK6'],
  tocfl: ['TOCFL1', 'TOCFL2', 'TOCFL3', 'TOCFL4', 'TOCFL5', 'TOCFL6', 'TOCFL7'],
  'tocfl-chars': [
    'TOCFL1',
    'TOCFL2',
    'TOCFL3',
    'TOCFL4',
    'TOCFL5',
    'TOCFL6',
    'TOCFL7',
  ],
  'rank-chars': ['TOP150', 'TOP300', 'TOP600', 'TOP1200', 'TOP2500', 'TOP5000'],
};
