import { useCallback, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { debounce } from 'throttle-debounce';

import { docKeyState, docSelectorState } from './doc';
import { isSavingState } from './editor';
import { saveDraft } from '../lib/core';
import { channelKeyState } from '../state/channel';
import captureException from '../lib/captureException';

export default function useSaveDraft() {
  // recoil state

  const channelKey = useRecoilValue(channelKeyState);
  const doc = useRecoilValue(docSelectorState);
  const docKey = useRecoilValue(docKeyState);

  const setIsSaving = useSetRecoilState(isSavingState);

  // save draft when doc changes

  const saveDraftDebounced = useCallback(
    debounce(1000, async (channelKey, docKey, doc) => {
      try {
        await saveDraft(channelKey, docKey, doc);
      } catch (e) {
        captureException(e);
      } finally {
        // set saving to false to show save complete status
        setIsSaving(false);
      }
    }),
    [setIsSaving]
  );

  useEffect(() => {
    if (channelKey && docKey && doc && doc.metadata) {
      // set saving to true to show saving status
      setIsSaving(true);
      // call debounced save
      saveDraftDebounced(channelKey, docKey, doc);
    }
  }, [channelKey, doc, docKey, saveDraftDebounced, setIsSaving]);
}
