import produce from 'immer';
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, CircularProgress, Radio } from 'rmwc';

import { metadataState, blocksState } from '../hooks/doc';
import captureException from '../lib/captureException';
import segmentsToString from '../lib/segmentsToString';
import { listVoices, synthesizeSpeech } from '../lib/tts';

export default function AudioToolbar(props) {
  // recoil state

  const metadata = useRecoilValue(metadataState);
  const [blocks, setBlocks] = useRecoilState(blocksState);

  // local state

  const [isSynthesizing, setIsSynthesizing] = useState(false);
  const [allVoices, setAllVoices] = useState(null);
  const [selectedVoiceIndex, setSelectedVoiceIndex] = useState(0);

  // derived state

  const { sourceLanguage } = metadata;
  const voices = allVoices && allVoices[sourceLanguage];

  // get voices

  useEffect(() => {
    (async () => {
      setAllVoices(await listVoices());
    })();
  }, []);

  // selection handlers

  function handleSelectAll() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          block.isSelected = true;
        });
      })
    );
  }

  function handleSelectNone() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks.forEach(block => {
          delete block.isSelected;
        });
      })
    );
  }

  // handle annotate

  async function handleClickSynthesize() {
    setIsSynthesizing(true);
    try {
      // created map of keys to text for segmenting
      const keyedText = {};
      blocks.forEach(block => {
        if (block.isSelected) {
          keyedText[block.key] = segmentsToString(block.segments);
        }
      });

      // call synthesize cloud function
      const keyedAudioUrls = await synthesizeSpeech(
        voices[selectedVoiceIndex],
        keyedText
      );

      // update blocks in recoil state
      setBlocks(blocks =>
        produce(blocks, draftBlocks => {
          draftBlocks.forEach(block => {
            if (keyedAudioUrls[block.key]) {
              block.sentenceAudioUrl = keyedAudioUrls[block.key];
            }
          });
        })
      );
    } catch (error) {
      captureException(error);
    } finally {
      setIsSynthesizing(false);
    }
  }

  // render

  return (
    <React.Fragment>
      <div className="draft-formatting-menu">
        <Button
          disabled={isSynthesizing || !metadata || !blocks}
          icon="done_all"
          label="All"
          onClick={handleSelectAll}
        />

        <Button
          disabled={isSynthesizing || !metadata || !blocks}
          icon="check_box_outline_blank"
          label="None"
          onClick={handleSelectNone}
        />

        <Button
          disabled={isSynthesizing || !metadata || !blocks}
          icon={isSynthesizing ? <CircularProgress /> : 'graphic_eq'}
          label="Synthesize"
          onClick={handleClickSynthesize}
        />
      </div>

      <div className="audio-toolbar__voice-list">
        {voices &&
          voices.map((voice, index) => (
            <Radio
              checked={index === selectedVoiceIndex}
              className="audio-toolbar__voice"
              key={index}
              onChange={() => setSelectedVoiceIndex(index)}
              value={index}
            >
              {`${voice.name} ${voice.ssmlGender}`}
            </Radio>
          ))}
      </div>
    </React.Fragment>
  );
}
