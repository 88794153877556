import React from 'react';
import { Icon } from 'rmwc';

import captureException from '../lib/captureException';

class BlockErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    captureException(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="block-error-boundary">
          <Icon
            className="block-error-boundary__icon"
            icon={{ icon: 'error', size: 'medium' }}
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default BlockErrorBoundary;
