import React, { useLayoutEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  Icon,
  TopAppBar as RmwcTopAppBar,
  TopAppBarFixedAdjust,
  TopAppBarNavigationIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from 'rmwc';

import DraftActionMenu from './DraftActionMenu';
import {
  editModeState,
  editBlockIndexState,
  isSavingState,
} from '../hooks/editor';
import useChannelPage from '../hooks/useChannelPage';
import getPageTitle from '../lib/getPageTitle';
import { topBarHeightState } from '../state/ui';

function TopBarDraft() {
  // recoil state

  const [topBarHeight, setTopBarHeight] = useRecoilState(topBarHeightState);
  const editBlockIndex = useRecoilValue(editBlockIndexState);

  const editMode = useRecoilValue(editModeState);

  const isSaving = useRecoilValue(isSavingState);

  // local state

  const barRef = useRef();

  // other hooks

  const { goToChannelPage } = useChannelPage();

  // done

  function handleClickHome() {
    goToChannelPage();
  }

  // update height

  useLayoutEffect(() => {
    setTopBarHeight(barRef.current.offsetHeight);
  }, [editMode, editBlockIndex, setTopBarHeight]);

  // render

  return (
    <React.Fragment>
      <RmwcTopAppBar className="top-bar top-bar--lesson" fixed ref={barRef}>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            <TopAppBarNavigationIcon
              icon={<img alt="Home" src="/android-chrome-192x192.png" />}
              id="top-bar__nav-button"
              onClick={handleClickHome}
            />

            <TopAppBarTitle>{getPageTitle()}</TopAppBarTitle>
          </TopAppBarSection>

          <TopAppBarSection alignEnd>
            <Icon
              className="top-bar__saving-icon"
              icon={isSaving ? 'sync' : 'cloud_done'}
            />
            <DraftActionMenu />
          </TopAppBarSection>
        </TopAppBarRow>
      </RmwcTopAppBar>

      <TopAppBarFixedAdjust style={{ paddingTop: topBarHeight }} />
    </React.Fragment>
  );
}

export default React.memo(TopBarDraft);
