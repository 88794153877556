import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { editModeState } from './editor';
import { mediaOutputState, useMediaInput } from './player';
import editModes from '../constants/editModes';

export default function useSelectSegmentOnClick() {
  // recoil state

  const editMode = useRecoilValue(editModeState);
  const { rewindAndPlay, play, pause } = useMediaInput();
  const { isPlaying } = useRecoilValue(mediaOutputState);

  // handle keydown effect

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === 'Tab' && editMode !== editModes.translations) {
        if (event.shiftKey) {
          // go back 2 seconds and play
          rewindAndPlay(2);
        } else {
          // toggle play/pause
          if (isPlaying) {
            pause();
          } else {
            play();
          }
        }
        event.preventDefault();
      }
    }

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [editMode, isPlaying, pause, play, rewindAndPlay]);
}
