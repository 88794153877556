import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Icon } from 'rmwc';

import AnnotationsToolbar from './AnnotationsToolbar';
import ExercisesToolbar from './ExercisesToolbar';
import AudioToolbar from './AudioToolbar';
import PreviewToolbar from './PreviewToolbar';
import RoleEditor from './RoleEditor';
import TextToolbar from './TextToolbar';
import TranslateToolbar from './TranslateToolbar';
import SegmentsToolbar from './SegmentsToolbar';
import SyncToolbar from './SyncToolbar';
import editModes from '../constants/editModes';
import { blocksState } from '../hooks/doc';
import { editModeState, editBlockIndexState } from '../hooks/editor';

import './DraftSidebar.css';

const modeButtons = [
  { mode: editModes.text, icon: 'create', text: 'Text' },
  { mode: editModes.roles, icon: 'palette', text: 'Roles' },
  { mode: editModes.audio, icon: 'mic', text: 'Audio' },
  { mode: editModes.segments, icon: 'line_style', text: 'Segments' },
  { mode: editModes.sync, icon: 'timer', text: 'Timing' },
  { mode: editModes.annotations, icon: 'assistant', text: 'Popups' },
  { mode: editModes.translations, icon: 'translate', text: 'Translations' },
  { mode: editModes.exercises, icon: 'fitness_center', text: 'Exercises' },
  { mode: editModes.preview, icon: 'check', text: 'Publish' },
];

function EditModeButton(props) {
  const { icon, isSelected, onClick, text } = props;

  let className = isSelected
    ? 'edit-mode-button--selected'
    : 'edit-mode-button';

  return (
    <div className={className} onClick={onClick}>
      <Icon icon={icon} />
      <div className="edit-mode-button__text">{text}</div>
    </div>
  );
}

export default React.memo(props => {
  // recoil state

  const blocks = useRecoilValue(blocksState);
  const [editMode, setEditMode] = useRecoilState(editModeState);
  const editBlockIndex = useRecoilValue(editBlockIndexState);

  // derived state

  const block =
    blocks && typeof editBlockIndex === 'number' && blocks[editBlockIndex];

  return (
    <div className="draft-sidebar">
      {blocks && (
        <div className="draft-sidebar__fixed">
          <div className="sync-toolbar-column" id="timeshifter-container" />

          <div className="draft-sidebar__modes">
            {modeButtons.map(modeButton => {
              const { mode, icon, text } = modeButton;
              const isSelected = mode === editMode;
              const onClick = isSelected ? null : () => setEditMode(mode);

              return (
                <React.Fragment key={mode}>
                  <EditModeButton
                    icon={icon}
                    isSelected={isSelected}
                    key={mode}
                    onClick={onClick}
                    text={text}
                  />

                  {isSelected && (
                    <div className="edit-mode-container">
                      {editMode === editModes.text && (
                        <React.Suspense
                          fallback={<div style={{ height: 48 }} />}
                        >
                          <TextToolbar block={block} index={editBlockIndex} />
                        </React.Suspense>
                      )}

                      {editMode === editModes.audio && (
                        <AudioToolbar block={block} index={editBlockIndex} />
                      )}

                      {editMode === editModes.roles && (
                        <RoleEditor block={block} index={editBlockIndex} />
                      )}

                      {editMode === editModes.segments && (
                        <SegmentsToolbar block={block} index={editBlockIndex} />
                      )}

                      {editMode === editModes.annotations && (
                        <AnnotationsToolbar
                          block={block}
                          index={editBlockIndex}
                        />
                      )}

                      {editMode === editModes.sync && (
                        <SyncToolbar block={block} index={editBlockIndex} />
                      )}

                      {editMode === editModes.translations && (
                        <TranslateToolbar />
                      )}

                      {editMode === editModes.exercises && <ExercisesToolbar />}

                      {editMode === editModes.preview && <PreviewToolbar />}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
});
