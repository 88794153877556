import produce from 'immer';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { Checkbox, IconButton } from 'rmwc';

import BlockAudioRecorder from './BlockAudioRecorder';
import BlockText from './BlockText';
import Choices from './Choices';
import Editable from './Editable';
import Role, { getBubbleClassName } from './Role';
import blockTypes from '../constants/blockTypes';
import editModes from '../constants/editModes';
import {
  blocksState,
  createLinkBlock,
  createVocabularyListBlock,
} from '../hooks/doc';
import { fetchDocMetadata } from '../lib/core';
import DocLink from './DocLink';
import './Block.css';

function Block(props) {
  const {
    block,
    roles,
    index,
    canSelect,
    editMode,
    highlightedSegmentIndex,
    isEditing,
    showAllTranslations,
    // media
    isPlaying,
    playingSegmentIndex,
    playBlock,
    pause,
    // editing
    onSelect,
    setBlock,
    splitBlock,
    // study
    isAnswered,
    answerQuestion,
  } = props;

  const {
    audioUrl,
    key,
    notes,
    roleKey,
    segments,
    translation,
    type,
    // media
    startTime,
    // link
    metadata,
  } = block;

  // recoil state

  const setBlocks = useSetRecoilState(blocksState);

  // derived state

  const blockKey = block.key;
  const isBubble = type === blockTypes.bubble;
  const canPlay = playBlock && typeof startTime === 'number';
  const role = roleKey && roles ? roles[roleKey] : null;
  const isEditingText = isEditing && editMode === editModes.text;
  const isEditingAudio = editMode === editModes.audio;
  const isSegmenting = editMode === editModes.segments;
  const isSyncing = editMode === editModes.sync;
  const isEditingTranslation = editMode === editModes.translations;
  const isEditingExercises = editMode === editModes.exercises;

  // other hooks

  const history = useHistory();

  // handlers

  function handleClickAudio(event) {
    if (isPlaying) {
      pause();
    } else {
      playBlock(blockKey);
    }
  }

  function handleClickVideo(event) {
    playBlock(index);
  }

  const handleSelect = React.useCallback(
    event => {
      if (event.defaultPrevented) {
        return; // do nothing if the event was already processed
      }

      // in edit mode, select block on click
      if (onSelect) {
        onSelect(index);
      }

      // when viewing doc, clicking a link pushes url
      else if (type === blockTypes.link && metadata) {
        history.push(`/l/${metadata.docKey}`);
      }
    },
    [index, history, metadata, type, onSelect]
  );

  // handle text editing

  const handleSegmentsChange = React.useCallback(
    async newSegments => {
      // handle lessonize link

      if (
        newSegments.length === 1 &&
        newSegments[0].text.startsWith(window.origin + '/l/')
      ) {
        // first set block to link type to show loading state
        const linkBlock = createLinkBlock(newSegments[0].text);
        setBlock(index, linkBlock);

        // fetch metadata and update block again if successful
        const metadata = await fetchDocMetadata(linkBlock.docKey);
        if (metadata) {
          const updatedLinkBlock = produce(linkBlock, draftLinkBlock => {
            draftLinkBlock.metadata = metadata;
          });
          setBlock(index, updatedLinkBlock);
        }
        return;
      }

      // handle [vocabulary]

      if (newSegments.length === 1 && newSegments[0].text === '[vocabulary]') {
        const vocabularyListBlock = createVocabularyListBlock();
        setBlock(index, vocabularyListBlock);
        return;
      }

      // handle everything else

      if (newSegments[0] && newSegments[0].text.includes('\n')) {
        // split on newlines as needed
        // only checking segment 0 because
        // this can only happen during a paste event
        // which sets segments to an array with one element
        splitBlock(index, newSegments);
      } else {
        setBlock(
          index,
          produce(block, draftBlock => {
            draftBlock.segments = newSegments;
          })
        );
      }
    },
    [block, index, setBlock, splitBlock]
  );

  const handleTranslationChange = React.useCallback(
    newText => {
      // update shared state
      const newBlock = produce(block, draftBlock => {
        draftBlock.translation = newText || null;
      });
      setBlock(index, newBlock);
    },
    [block, index, setBlock]
  );

  function handleToggleAudio() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        if (draftBlocks[index].hasAudio) {
          delete draftBlocks[index].hasAudio;
          delete draftBlocks[index].isSelected; // unselect as well
        } else {
          draftBlocks[index].hasAudio = true;
        }
      })
    );
  }

  function handleToggleAudioBlanks() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        draftBlocks[index].fillBlanksWithAudio = !draftBlocks[index]
          .fillBlanksWithAudio;
      })
    );
  }

  function handleToggleSelected() {
    setBlocks(blocks =>
      produce(blocks, draftBlocks => {
        if (draftBlocks[index].isSelected) {
          delete draftBlocks[index].isSelected;
        } else {
          draftBlocks[index].isSelected = true;
        }
      })
    );
  }

  // render

  // handle links
  if (type === blockTypes.link) {
    return (
      <div className="link-outer" id={key} onClick={handleSelect}>
        <DocLink metadata={block.metadata} />
      </div>
    );
  }

  let handleClickOuter;
  if (onSelect && !isEditingTranslation) {
    // if editing, clicking outer element selects block
    handleClickOuter = handleSelect;
  } else if (isBubble && canPlay) {
    handleClickOuter = audioUrl ? handleClickAudio : handleClickVideo;
  }
  const outerClass =
    `block ${type}-outer` +
    (handleClickOuter ? ' block--clickable' : '') +
    (isEditing ? ' block--editing' : '');
  const innerClass = isBubble ? getBubbleClassName(role) : `${type}-inner`;

  let renderedText;
  if (isEditingText) {
    renderedText = (
      <Editable
        autoFocus
        initialSegments={segments || []}
        id={isEditingText ? 'editable' : null}
        onChange={handleSegmentsChange}
      />
    );
  } else {
    renderedText = (
      <BlockText
        blockIndex={index}
        editMode={editMode}
        highlightedSegmentIndex={highlightedSegmentIndex}
        isEditingExercises={isEditingExercises}
        isPlaying={isPlaying}
        isSyncing={isSyncing}
        playingSegmentIndex={playingSegmentIndex}
        role={role}
        segments={segments}
      />
    );
  }

  return (
    <div className={outerClass} id={key} onClick={handleClickOuter}>
      {isBubble && <Role role={role} />}

      <div className={innerClass}>
        <div
          className="bubble-inline-block"
          data-block-index={index}
          ref={r => {
            if (r) {
              r.contentEditable = isSegmenting;
            }
          }}
        >
          {renderedText}

          {!isEditingTranslation && showAllTranslations && translation && (
            <div className="bubble-translation">{translation}</div>
          )}

          {isEditingTranslation && (
            <div className="bubble-translation">
              <Editable
                autoFocus={isEditing}
                initialText={translation || ''}
                id="translation-editable"
                onChange={handleTranslationChange}
                onFocus={handleSelect}
              />
            </div>
          )}
        </div>
      </div>

      {notes && <div className="bubble-notes">{notes}</div>}

      {isEditingAudio && block.hasAudio && (
        <BlockAudioRecorder block={block} index={index} />
      )}

      {type === blockTypes.multipleChoiceReading && (
        <Choices
          block={block}
          canAnswer
          isAnswered={isAnswered}
          isEditing={isEditingText}
          index={index}
          setBlock={setBlock}
          answerQuestion={answerQuestion}
        />
      )}

      {editMode === editModes.audio && (
        <IconButton
          className="block__audio-icon"
          checked={Boolean(block.hasAudio)}
          icon="volume_off"
          onClick={handleToggleAudio}
          onIcon="volume_up"
        />
      )}

      {false /* temporarily disable audio exercises */ &&
        isEditingExercises && (
          <IconButton
            className="block__audio-icon"
            checked={Boolean(block.fillBlanksWithAudio)}
            icon="translate"
            onClick={handleToggleAudioBlanks}
            onIcon="volume_up"
          />
        )}

      {canSelect && block.hasAudio && (
        <Checkbox
          className="block__checkbox"
          checked={Boolean(block.isSelected)}
          onClick={handleToggleSelected}
        />
      )}
    </div>
  );
}

export default React.memo(Block);
