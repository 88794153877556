import { callCloudFunction } from './core';

import getFirebaseConfig from './getFirebaseConfig';

const { projectId } = getFirebaseConfig();

export default async function annotate(
  channelKey,
  sourceLanguage,
  targetLanguage,
  keyedText
) {
  return await callCloudFunction('annotate', {
    projectId,
    channelKey,
    sourceLanguage,
    targetLanguage,
    keyedText,
  });
}
