import React from 'react';
import { useTranslation } from 'react-i18next';
import { Menu, MenuItem, MenuSurfaceAnchor } from 'rmwc';

function LanguageSelect(props) {
  const {
    children,
    disabled,
    idPrefix,
    language,
    languageCodes,
    setLanguage,
  } = props;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const languagesTranslated = languageCodes
    .map(languageCode => ({
      code: languageCode,
      translation: t(languageCode),
    }))
    .sort((a, b) => (a.translation < b.translation ? -1 : 1));

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function handleSelect(event, code) {
    setLanguage(code);
    setOpen(false);
    event.stopPropagation();
  }

  return (
    <span
      onClick={!disabled ? handleOpen : null}
      style={disabled ? { pointerEvents: 'none' } : null}
    >
      <div className="language-select">
        <MenuSurfaceAnchor className="language-select__anchor">
          <Menu open={open} onClose={handleClose} renderToPortal>
            {languagesTranslated.map(translatedLanguage => (
              <MenuItem
                id={`${idPrefix}-language-select__${translatedLanguage.code}`}
                key={translatedLanguage.code}
                onClick={event => handleSelect(event, translatedLanguage.code)}
                selected={translatedLanguage.code === language}
              >
                {translatedLanguage.translation}
              </MenuItem>
            ))}
          </Menu>
        </MenuSurfaceAnchor>
      </div>

      {children}
    </span>
  );
}

export default React.memo(LanguageSelect);
