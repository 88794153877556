import {
  getDbObject,
  setDbObject,
  subscribeToDbObject,
  unsubscribeFromDbOject,
} from './utils';

function isValidKey(key) {
  return Boolean(typeof key === 'string' && key.match(/^[a-zA-Z0-9_-]+$/));
}

export function parsePath(path) {
  let channelAlias;
  let channelKey;
  let docKey;
  let isEditing = false;
  let isValid = true;

  if (path === '/') {
    return { isValid };
  }

  const tokens = path.substring(1).split('/');

  switch (tokens[0]) {
    case 'c':
      channelAlias = tokens[1];
      isValid = isValidKey(tokens[1]);
      break;
    case 'channel':
      channelKey = tokens[1];
      isValid = isValidKey(tokens[1]);
      break;
    case 'draft':
      channelKey = tokens[1];
      docKey = tokens[2];
      isEditing = true;
      isValid = isValidKey(tokens[1]) && isValidKey(tokens[2]);
      break;
    case 'l':
      docKey = tokens[1];
      isValid = isValidKey(tokens[1]);
      break;
    default:
      isValid = false;
      break;
  }

  return {
    channelAlias,
    channelKey,
    docKey,
    isEditing,
    isValid,
  };
}

// paths

export function channelAliasDbPath(channelAlias) {
  return `/channelAliases/${channelAlias}`;
}

export function allChannelMetadataDbPath() {
  return '/channelMetadata';
}

export function channelMetadataDbPath(channelKey) {
  return `/channelMetadata/${channelKey}`;
}

export function channelAdminPath(channelKey, uid) {
  return `/permissions/${channelKey}/${uid}`;
}

export function allChannelDocMetadataDbPath() {
  return '/channelDocMetadata';
}

export function channelDocMetadataListDbPath(channelKey) {
  return `/channelDocMetadata/${channelKey}`;
}

export function channelDocMetadataDbPath(channelKey, docKey) {
  return `/channelDocMetadata/${channelKey}/${docKey}`;
}

export function channelBannerStoragePath(channelKey) {
  return `channelBanners/${channelKey}.jpg`;
}

export function channelIconStoragePath(channelKey) {
  return `channelIcons/${channelKey}.jpg`;
}

// get metadata

export async function getAllChannelMetadata() {
  return getDbObject(allChannelMetadataDbPath());
}

export async function getChannelKeyFromAlias(channelAlias) {
  return await getDbObject(channelAliasDbPath(channelAlias));
}

export async function getChannelMetadata(channelKey) {
  return await getDbObject(channelMetadataDbPath(channelKey));
}

export async function getAllChannelDocMetadata() {
  return await getDbObject(allChannelDocMetadataDbPath());
}

export function subscribeToChannelMetadata(channelKey, handleUpdate) {
  return subscribeToDbObject(channelMetadataDbPath(channelKey), handleUpdate);
}

export function unsubscribeFromChannelMetadata(channelKey) {
  return unsubscribeFromDbOject(channelMetadataDbPath(channelKey));
}

// set metadata

export async function setChannelBannerUrl(channelKey, url) {
  return await setDbObject(
    channelMetadataDbPath(channelKey) + '/bannerUrl',
    url
  );
}

export async function setChannelIconUrl(channelKey, url) {
  return await setDbObject(channelMetadataDbPath(channelKey) + '/iconUrl', url);
}

export async function setChannelTitle(channelKey, title) {
  return await setDbObject(channelMetadataDbPath(channelKey) + '/title', title);
}

// list docs

function sortByPublishDate(docs) {
  return docs
    ? Object.values(docs).sort((a, b) =>
        a.publishDate < b.publishDate ? 1 : -1
      )
    : [];
}

export async function listAllDocs() {
  // get all doc metadata
  const allChannelDocMetadata = await getAllChannelDocMetadata();

  // remap from /channelKey/docKey to /docKey
  const docMetadata = {};
  if (allChannelDocMetadata) {
    // for each channel
    Object.values(allChannelDocMetadata).forEach(channelDocMetadataList => {
      // copy docMetadata
      Object.assign(docMetadata, channelDocMetadataList);
    });
  }

  // sort and return
  return sortByPublishDate(Object.values(docMetadata));
}

export async function listChannelDocs(channelKey) {
  // get channel doc metadata
  const docs = await getDbObject(channelDocMetadataListDbPath(channelKey));

  // sort and return
  return sortByPublishDate(Object.values(docs));
}

// get role

export async function getChannelRole(channelKey, uid) {
  return Boolean(await getDbObject(channelAdminPath(channelKey, uid)));
}
