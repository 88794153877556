import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableRow,
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
} from 'rmwc';

import { metadataState } from '../hooks/doc';
import { fetchTrack } from '../lib/youtubeApi';

export default function InsertCaptionsDialog(props) {
  const { captionTracks: trackList, onInsert, onClose } = props;

  const [track, setTrack] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [captions, setCaptions] = React.useState(null);
  const [rangeStart, setRangeStart] = React.useState(0);
  const [rangeEnd, setRangeEnd] = React.useState(0);
  const canSubmit = captions && rangeEnd > rangeStart;

  const metadata = useRecoilValue(metadataState);
  const mediaUrl = metadata && metadata.mediaUrl;

  // fetch track when track or url changes
  React.useEffect(() => {
    if (mediaUrl && track) {
      setIsLoading(true);
      fetchTrack(mediaUrl, track)
        .then(captions => {
          setCaptions(captions);
          setRangeStart(0);
          setRangeEnd(captions.length);
        })
        .finally(() => setIsLoading(false));
    } else {
      setCaptions(null);
      setRangeStart(0);
      setRangeEnd(0);
    }
  }, [track, mediaUrl]);

  // handlers

  function handleSelectTrack(event) {
    setTrack(trackList[event.target.value]);
  }

  function onClickInsert() {
    onInsert(captions.slice(rangeStart, rangeEnd));
    onClose();
  }

  // render

  return (
    <Dialog onClose={onClose} open>
      <DialogTitle>Select Caption Range to Insert</DialogTitle>
      <DialogContent>
        {trackList && (
          <select
            id="draft-captions-dialog__track-select"
            onChange={handleSelectTrack}
            value={track || ''}
          >
            {trackList.map((track, index) => (
              <option key={track.id} value={index}>
                {track.languageOriginal}
              </option>
            ))}
          </select>
        )}

        <DataTable>
          <DataTableContent>
            <DataTableBody>
              {captions &&
                captions.map((caption, index) => {
                  const isSelected = index >= rangeStart && index < rangeEnd;
                  const className = isSelected
                    ? 'draft-captions-dialog__row--selected'
                    : 'draft-captions-dialog__row--notSelected';

                  return (
                    <DataTableRow key={index}>
                      <DataTableCell className="insert-captions-dialog__cell">
                        <IconButton
                          disabled={index > rangeEnd - 1}
                          icon="vertical_align_top"
                          id={`caption-${index}-setStart`}
                          onClick={() => setRangeStart(index)}
                        />
                      </DataTableCell>
                      <DataTableCell className="insert-captions-dialog__cell">
                        <IconButton
                          disabled={index < rangeStart}
                          icon="vertical_align_bottom"
                          id={`caption-${index}-setEnd`}
                          onClick={() => setRangeEnd(index + 1)}
                        />
                      </DataTableCell>
                      <DataTableCell
                        className={className + ' insert-captions-dialog__cell'}
                      >
                        {captions[index].text}
                      </DataTableCell>
                    </DataTableRow>
                  );
                })}
            </DataTableBody>
          </DataTableContent>
        </DataTable>

        {isLoading && <LinearProgress />}
      </DialogContent>

      <DialogActions>
        <DialogButton
          id="draft-captions-dialog__close-button"
          onClick={onClose}
        >
          Cancel
        </DialogButton>

        <DialogButton
          disabled={!canSubmit}
          id="draft-captions-insert-button"
          isDefaultAction
          onClick={onClickInsert}
        >
          Insert
        </DialogButton>
      </DialogActions>
    </Dialog>
  );
}
