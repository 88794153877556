import sourceLanguageCodes from './sourceLanguageCodes';

const cefr = [null, 'A1', 'A2', 'B1', 'B2', 'C1', 'C2'];

const levels = {};

sourceLanguageCodes.forEach(code => {
  levels[code] = cefr;
});

levels.zh = [null, 'HSK1', 'HSK2', 'HSK3', 'HSK4', 'HSK5', 'HSK6'];

export default levels;
