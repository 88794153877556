import { selector } from 'recoil';

import { blocksState, vocabState, wordsState } from '../hooks/doc';
import { encodeWord } from '../lib/core';

export const keyVocabularyState = selector({
  key: 'keyVocabularyState',
  get: ({ get }) => {
    const blocks = get(blocksState);
    const words = get(wordsState);
    const vocab = get(vocabState);

    if (blocks && words) {
      // ordered list of vocab metadata to return
      const segmentAndWordList = [];
      // track words added so we don't add again
      const keyVocab = {};
      // for each block
      blocks.forEach(
        block =>
          block.segments &&
          // for each segment
          block.segments.forEach(segment => {
            const encodedWord = encodeWord(segment.text);
            const word = words[encodedWord];
            if (
              // isVocab
              vocab[encodedWord] &&
              // def exists
              word &&
              // haven't added before
              !keyVocab[encodedWord]
            ) {
              // push metadata
              segmentAndWordList.push({ segment, word });
              // track word so we won't add it again
              keyVocab[encodedWord] = true;
            }
          })
      );
      // return ordered list
      return segmentAndWordList;
    }

    // missing blocks or links
    return [];
  },
});
