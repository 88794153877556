import produce from 'immer';

import getSelectedEditable from './getSelectedEditable';
import getSelectionOffset from './getSelectionOffset';

import createBlock from './createBlock';
import { htmlToSegments } from './editable';

// save and restore contenteditable selection
// https://gist.github.com/dantaex/543e721be845c18d2f92652c0ebe06aa

export function saveSelection() {
  if (window.getSelection) {
    const sel = window.getSelection();
    if (sel.getRangeAt && sel.rangeCount) {
      return sel.getRangeAt(0);
    }
  } else if (document.selection && document.selection.createRange) {
    return document.selection.createRange();
  }
  return null;
}

export function restoreSelection(range) {
  if (range) {
    if (window.getSelection) {
      const sel = window.getSelection();
      sel.removeAllRanges();
      sel.addRange(range);
    } else if (document.selection && range.select) {
      range.select();
    }
  }
}

// select all text in an element
// https://stackoverflow.com/a/2838358

export function selectElementText(el, win) {
  win = win || window;
  const doc = win.document;
  let sel, range;
  if (win.getSelection && doc.createRange) {
    sel = win.getSelection();
    range = doc.createRange();
    range.selectNodeContents(el);
    sel.removeAllRanges();
    sel.addRange(range);
  } else if (doc.body.createTextRange) {
    range = doc.body.createTextRange();
    range.moveToElementText(el);
    range.select();
  }
}

// draft operations

export function isCursorAtBlockStart() {
  const editableEl = getSelectedEditable();

  if (editableEl) {
    const offset = getSelectionOffset(editableEl);
    return offset.start === offset.end && offset.start === 0;
  }

  return false;
}

export function isCursorAtBlockEnd() {
  const editableEl = getSelectedEditable();
  if (editableEl) {
    const offset = getSelectionOffset(editableEl);
    return (
      offset.start === offset.end &&
      offset.start === editableEl.innerText.length
    );
  }

  return false;
}

export function splitBlockOnSelection(blocks, index) {
  // get editable element, offset, and block index
  const editableEl = getSelectedEditable();
  const offset = editableEl && getSelectionOffset(editableEl);

  // if we successfully found offset within editable
  if (offset) {
    const segments = htmlToSegments(editableEl);
    const segmentsBefore = [];
    const segmentsAfter = [];
    let charsProcessed = 0;

    segments.forEach(segment => {
      const segmentStartOffset = charsProcessed;
      const segmentEndOffset = charsProcessed + segment.text.length;

      if (segmentEndOffset < offset.start) {
        // ends before selection start
        segmentsBefore.push(segment);
      } else if (segmentStartOffset > offset.end) {
        // starts after selection end
        segmentsAfter.push(segment);
      } else {
        const offsetStartWithinSegment = offset.start - segmentStartOffset;
        const offsetEndWithinSegment = offset.end - segmentStartOffset;
        if (offsetStartWithinSegment > 0) {
          segmentsBefore.push({
            ...segment,
            text: segment.text.substring(0, offsetStartWithinSegment),
          });
        }
        if (segment.text.length - offsetEndWithinSegment > 0) {
          segmentsAfter.push({
            ...segment,
            text: segment.text.substring(offsetEndWithinSegment),
          });
        }
      }

      charsProcessed += segment.text.length;
    });

    // return immutable version with changes
    return produce(blocks, draftBlocks => {
      const block = draftBlocks[index];

      // update current block segments
      block.segments = segmentsBefore;

      // create a new block
      const newBlock = createBlock(block.type);
      newBlock.roleKey = block.roleKey || null;
      newBlock.segments = segmentsAfter;

      // splice new block to index after original block
      draftBlocks.splice(index + 1, 0, newBlock);
    });
  }

  // if we couldn't find editable element, return blocks unchanged
  return blocks;
}

export function mergeBlock(blocks, index) {
  // return immutable version with changes
  return produce(blocks, draftBlocks => {
    const block = draftBlocks[index];
    const prevBlock = draftBlocks[index - 1];

    // append block text onto prevBlock
    prevBlock.segments = prevBlock.segments
      ? prevBlock.segments.concat(block.segments || [])
      : block.segments;

    // delete block
    draftBlocks.splice(index, 1);
  });
}
