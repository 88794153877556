import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { IconButton } from 'rmwc';

import Segment from '../Segment';
import WordChoice from './WordChoice';
// import exerciseTypes from '../../constants/exerciseTypes';
import { blocksState, wordsState, scriptsState } from '../../hooks/doc';
import useWordAudioSprites from '../../hooks/useWordAudioSprites';
import {
  maxTime,
  mediaOutputState,
  playingSegmentState,
  useMediaInput,
} from '../../hooks/player';
import audioSpriteKey from '../../lib/audioSpriteKey';
import { encodeWord } from '../../lib/core';
import { findNextSegment } from '../../lib/findSegment';
import shuffle from '../../lib/shuffle';
import { correctSound, incorrectSound } from '../../lib/sounds';
import {
  pronSettingState,
  scriptSettingState,
  showAllPronsState,
} from '../../state/settings';

import './Exercises.css';

function firstIncompeteIndex(choices, completedIndices) {
  let firstIncompeteIndex;
  choices.some((choice, index) => {
    if (!completedIndices[choice.index]) {
      firstIncompeteIndex = index;
      return true;
    }
    return false;
  });
  return firstIncompeteIndex;
}

export default function FillBlanks(props) {
  // props

  const { exercise, onComplete } = props;

  // recoil
  const blocks = useRecoilValue(blocksState);
  const words = useRecoilValue(wordsState);
  const scripts = useRecoilValue(scriptsState);
  const selectedScript = useRecoilValue(scriptSettingState);
  const showAllProns = useRecoilValue(showAllPronsState);
  const selectedPron = useRecoilValue(pronSettingState);
  const { isPlaying } = useRecoilValue(mediaOutputState);
  const {
    blockIndex: playingBlockIndex,
    segmentIndex: playingSegmentIndex,
  } = useRecoilValue(playingSegmentState);
  const { pause, playClip } = useMediaInput();

  // hooks

  const wordAudioSprites = useWordAudioSprites();

  // local state

  const [topChoices, setTopChoices] = useState([]);
  const [bottomChoices, setBottomChoices] = useState([]);
  const [selectedChoiceIndex, setSelectedChoiceIndex] = useState(null);
  const [completedIndices, setCompletedIndices] = useState({});

  // derived state

  const {
    block: { segments },
    blockIndex,
  } = exercise;

  const scriptTextMap = scripts[selectedScript];

  let prompt;
  let showAudio = false;
  let showTranslation = true; // temporarily disable audio blanks exercises
  // switch (exercise.type) {
  //   case exerciseTypes.fillBlanksWithAudio:
  //     prompt = 'Listen to the audio and fill in the blanks';
  //     showAudio = true;
  //     break;
  //   case exerciseTypes.fillBlanksWithTranslation:
  //     prompt = 'Read the translation and fill in the blanks';
  //     showTranslation = true;
  //     break;
  //   default:
  //     break;
  // }

  // update choices on exercise change

  useEffect(() => {
    const newTopChoices = [];
    const newBottomChoices = [];

    segments.forEach((segment, index) => {
      if (segment.isBlank) {
        const choiceIndex = newTopChoices.length;
        const def = words && words[encodeWord(segment.text)];
        newTopChoices.push({
          def,
          index: choiceIndex,
          segment,
          segmentIndex: index,
        });
        newBottomChoices.push({
          def,
          index: choiceIndex,
          segment,
          segmentIndex: index,
        });
      }
    });

    shuffle(newBottomChoices);

    setTopChoices(newTopChoices);
    setBottomChoices(newBottomChoices);
    setSelectedChoiceIndex(0);
    setCompletedIndices({});
  }, [words, exercise, segments]);

  // handlers

  function handlePlayAudio() {
    // find start time
    let startSegmentIndex = null;
    let startTime = null;
    segments.some((segment, index) => {
      if (typeof segment.startTime === 'number') {
        startSegmentIndex = index;
        startTime = segment.startTime;
        return true;
      }
      return false;
    });

    // find end time
    if (startSegmentIndex !== null && startTime !== null) {
      const { segment: firstSegmentAfter } = findNextSegment(
        blocks,
        blockIndex + 1,
        0,
        s => typeof s.startTime === 'number'
      );

      if (firstSegmentAfter) {
        // if there is a later segment with media timing,
        // play until its start time
        const endTime = firstSegmentAfter.startTime;
        playClip(startTime, endTime);
      } else {
        // if this is the last segment with media timing,
        // play to the end
        playClip(startTime, maxTime);
      }
    }
  }

  function handleSelectChoice(newIndex) {
    if (typeof selectedChoiceIndex === 'number') {
      // if a choice is currently selected
      const selectedIsTopChoice = selectedChoiceIndex < topChoices.length;
      const newIsTopChoice = newIndex < topChoices.length;
      if (
        (selectedIsTopChoice && newIsTopChoice) ||
        (!selectedIsTopChoice && !newIsTopChoice)
      ) {
        // if both current and new selections are from same set, select new one
        setSelectedChoiceIndex(newIndex);
      } else {
        // otherwise, determine whether choices match
        const selectedChoice = selectedIsTopChoice
          ? topChoices[selectedChoiceIndex]
          : bottomChoices[selectedChoiceIndex - topChoices.length];
        const newChoice = newIsTopChoice
          ? topChoices[newIndex]
          : bottomChoices[newIndex - topChoices.length];

        if (selectedChoice.index === newChoice.index) {
          // choice is correct
          correctSound.play();
          const newCompletedIndices = {
            ...completedIndices,
            [newChoice.index]: true,
          };
          setCompletedIndices(newCompletedIndices);

          // have we finished?
          if (Object.keys(newCompletedIndices).length === topChoices.length) {
            // all choices complete
            setSelectedChoiceIndex(null);
            onComplete();
          } else {
            // otherwise, select the next incomplete choice
            setSelectedChoiceIndex(
              firstIncompeteIndex(topChoices, newCompletedIndices)
            );
          }
        } else {
          // choice is incorrect
          incorrectSound.play();
        }
      }
    } else {
      // otherwise, select choice
      setSelectedChoiceIndex(newIndex);
    }

    // pause block audio
    pause();

    // play sound
    const choice = bottomChoices[newIndex - topChoices.length];
    if (choice && wordAudioSprites) {
      wordAudioSprites.play(audioSpriteKey(choice.segment));
    }
  }

  // render

  return (
    <React.Fragment>
      <div className="exercise__prompt">{prompt}</div>

      {showTranslation && (
        <div className="exercise__translation">
          {exercise.block.translation}
        </div>
      )}

      <div className="exercise__fill-blanks-container">
        {showAudio && (
          <IconButton
            className="exercise__audio"
            icon={isPlaying ? 'stop' : 'volume_up'}
            onClick={isPlaying ? pause : handlePlayAudio}
          />
        )}

        {segments.map((segment, index) => {
          const choice = topChoices.find(
            choice => choice.segmentIndex === index
          );
          const isHighlighted =
            isPlaying &&
            blockIndex === playingBlockIndex &&
            index === playingSegmentIndex;
          const isSelected = choice && choice.index === selectedChoiceIndex;

          return choice ? (
            <WordChoice
              choice={choice}
              index={index}
              isBlank
              isComplete={completedIndices[choice.index]}
              isHighlighted={isHighlighted}
              isSelected={isSelected}
              key={index}
              onClick={
                isSelected ? null : () => handleSelectChoice(choice.index)
              }
            />
          ) : (
            <Segment
              blockIndex={blockIndex}
              displayText={
                (scriptTextMap && scriptTextMap[encodeWord(segment.text)]) ||
                segment.text
              }
              isHighlighted={isHighlighted}
              key={index}
              selectedPron={showAllProns && selectedPron}
              segment={segment}
              segmentIndex={index}
              word={words && words[encodeWord(segment.text)]}
            />
          );
        })}
      </div>

      <div className="exercise__bottom-choice-container">
        {bottomChoices.map((choice, index) => {
          const isSelected = topChoices.length + index === selectedChoiceIndex;
          return (
            <WordChoice
              choice={choice}
              index={index}
              isComplete={completedIndices[choice.index]}
              isSelected={isSelected}
              key={index}
              onClick={
                isSelected
                  ? null
                  : () => handleSelectChoice(topChoices.length + index)
              }
            />
          );
        })}
      </div>
    </React.Fragment>
  );
}
