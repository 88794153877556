import blockTypes from '../constants/blockTypes';
import { shortKey } from '../lib/core';

export default function createBlock(type) {
  return {
    type: type || blockTypes.paragraph,
    key: shortKey(),
    segments: [{ text: '' }],
    hasAudio: true,
  };
}
