exports.isSpaceDelimited = language => {
  return !(
    language === 'ar' ||
    language === 'he' ||
    language === 'hi' ||
    language === 'ja' ||
    language === 'ko' ||
    language === 'th' ||
    language === 'zh'
  );
};

const languageNames = {
  en: {
    'zh-CN': '英文',
    'zh-TW': '英文',
  },
  zh: {
    en: 'Mandarin',
  },
};

exports.getLanguageName = (sourceLanguage, targetLanguage) => {
  if (languageNames[sourceLanguage]) {
    return languageNames[sourceLanguage][targetLanguage];
  }
  return undefined;
};
